import React, { memo, useContext} from "react";
import { TextsContext } from '../context/textsContext';
import StyleHeader from "../scss/header.module.scss";
import Navbar from "react-bootstrap/Navbar";
import Picture from "./PictureComponent";
import { TitleH1Component } from "./TitleH1Component";
import {Link} from "react-router-dom";


const HeaderComponent = memo(() => {
	const { texts } = useContext(TextsContext);

	return (
		<header className={`container ${StyleHeader.header}`}>

			<Navbar>
				<Navbar.Brand href="#" className={`d-flex align-items-center py-0`}>
					<TitleH1Component styleGroup="raleway fw_400 lh_40 color_secundary mb-0">
						{texts?.header_title ?? 'My VIP Care'}
					</TitleH1Component>
				</Navbar.Brand>

				<Navbar.Toggle />
				<Navbar.Collapse className="justify-content-end">
					<div className={`d-flex align-items-center ${StyleHeader.logoDesktop} me-0 me-md-5`}>
						<Link to={'/home'}>
							<Picture src="/img/MyVIPcare-TM-VUMI.png" alt="VUMI" styleGroup={`${StyleHeader.img} w-auto`}/>
							{/*<Picture src="/img/logoMycareAc.png" alt="VUMI" styleGroup={`${StyleHeader.img} w-auto`}/>*/}
						
						</Link>
					</div>
				</Navbar.Collapse>
			</Navbar>

		</header>
	);
});

export default HeaderComponent;
