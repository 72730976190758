import mainApi from "./mainApi";

export const getPlan = async(planId = "152") => {
    const now = new Date();
    const leftTrim0 = (value) =>  value < 10 ? `0${value}` : value;
    const coverage =  `${now.getFullYear()}-${leftTrim0(now.getMonth())}-${leftTrim0(now.getDate())}`;
    const postData = {
        "planId": planId,
        "effectiveDate": coverage,
        "terminationDate": coverage
    };
    const {data} = await mainApi.post("/services/quoter-options-plan", postData);
    return data?.data?.[0];
}


export const validateAgentNumber = async (agentNumber, planName) => {
    try {
        const {data} = await mainApi.get(`/services/quoter-validate`, {params: {agentNumber, planName}});
        return data;
    } catch (error) {
        console.error(error?.response)
    }
}