import React, { useContext } from "react";
import { TextsContext } from "context/textsContext";
import { useApplication } from "context/applicationContext";
import { useApplicationList } from "hooks/ApplicationListHook";
import { useNavigate } from "react-router-dom";

export function SolicitadoAgregadoList({ subsection }) {
  const { texts } = useContext(TextsContext);
  const { applicationState } = useApplication();
  const {startEdit, remove} = useApplicationList();
  const navigate = useNavigate();


  const edit = (i) => {
    return () => {
      startEdit(subsection, i.id);
      navigate(subsection);
    }
  }

  return (
    <div className="block-list-edit">
      {React.Children.toArray(
        (applicationState[`${subsection}List`] ?? []).map((i) => (
          <div className="inner-block-list">
            <div className="n">Diego Bustamante</div>
            <div className="ac">
              <span style={{cursor: "pointer"}} onClick={edit(i)}>{texts?.prior_coverage_edit ?? "Editar"}</span>
              <span style={{cursor: "pointer"}} onClick={() => {remove(subsection, i.id)}}>{texts?.prior_coverage_delete ?? "Borrar"}</span>
            </div>
          </div>
        ))
      )}
    </div>
  );
}
