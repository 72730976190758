import { useEffect, useState, useContext } from "react";
import { TextsContext } from '../context/textsContext';
import { useCotizador } from "../context/apiContext";
import { useCalculations } from "./CalculationsHook";

export const useFeeTable = () => {
	const { texts } = useContext(TextsContext);
    const { fare, fee, addDependant } = useCotizador();
	const {getMainFares, getDependantsFares, calculateValue} = useCalculations();

    const getName = (name) => {
		switch (name) {
			case "15":
				return texts?.general_form_option_spouse ?? 'Spouse';
			case 9:
				return texts?.general_table_title_main ?? 'Main Applicant';
			case "16":
				return texts?.general_form_option_children ?? 'Son/Daugther';
			case "-16":
				return texts?.general_form_option_other ?? 'Other';
			default:
				return name;
		}
	}

	const getTotal = () => {
		return {
			name: texts?.general_table_title_total ?? 'Total',
			annual: calculateValue("annual", true, true, true).toFixed(2),
			semiannual: calculateValue("semiannual", true, true, true).toFixed(2),
			quarterly: calculateValue("quarterly", true, true, true).toFixed(2),
			monthly: calculateValue("monthly", true, true, true).toFixed(2),
		}
	}

	const getTotalWithoutFee = () => {
		return {
			name: texts?.general_table_title_total ?? 'Total',
			annual: calculateValue("annual", true, false).toFixed(2),
			semiannual: calculateValue("semiannual", true, false).toFixed(2),
			quarterly: calculateValue("quarterly", true, false).toFixed(2),
			monthly: calculateValue("monthly", true, false).toFixed(2),
		}
	}

	const getFullRider = () => {
		const value = calculateValue('rider', true, false);
		return {
			name: texts?.general_table_title_rider ?? 'Rider',
			annual: value,
			semiannual: value,
			quarterly: value,
			monthly: value,
		}
	}

	const getRider = () => {
		const values = getFullRider();
		return {
			name: texts?.general_table_title_rider ?? 'Rider',
			annual: Math.round(values.annual),
			semiannual: Math.round(values.annual),
			quarterly: Math.round(values.annual),
			monthly: Math.round(values.annual),
		}
	}

	const getFullPremiumAnnual = () => {
		return {
			name: texts?.general_table_title_Premium ?? 'Premium',
			annual: calculateValue("annual", false, false),
			semiannual: (calculateValue("semiannual", false, false)),
			quarterly: (calculateValue("quarterly", false, false)),
			monthly: (calculateValue("monthly", false, false)),
		}
	}

	const getPremiumAnnual = () => {
		const values = getFullPremiumAnnual();
		return {
			name: texts?.general_table_title_Premium ?? 'Premium',
			annual: Math.round(values.annual),
			semiannual: Math.round(values.semiannual),
			quarterly: Math.round(values.quarterly),
			monthly: Math.round(values.monthly),
		}
	}

	const getOtherPayments = () => {
		return {
			name: texts?.general_table_title_Premium ?? 'Premium',
			annual: calculateValue(null, false, false).toFixed(2),
			semiannual: (calculateValue("semiannual", false, false)).toFixed(2),
			quarterly: (calculateValue("quarterly", false, false)).toFixed(2),
			monthly: (calculateValue("monthly", false, false)).toFixed(2),
		}
	}

	const getValues = () => {
		const toFix = (object) => {
			object.annual = (object.annual).toFixed(2);
			object.semiannual = (object.semiannual).toFixed(2);
			object.quarterly = (object.quarterly).toFixed(2);
			object.monthly = (object.monthly).toFixed(2);
			return object
		}
		const toFee = (fee) => {
			if (typeof fee === "string") {
				return parseFloat(Number(fee).toFixed(2));
			}
		}
		let main = getMainFares(true);
		main.name = getName(main.componentId);
		main = toFix(main)
		return [
			main,
			...(addDependant ? getDependantsFares(true).map(i => {
				i.name = getName(i.componentId)
				i = toFix(i)
				return i;
			}) : []),
			// getRider(),
			...(fee.data?.data?.map(i => ({
				name: texts?.general_table_title_fee ?? 'Admin Fee', annual: toFee(i.fee), semiannual: toFee(i.fee), quarterly: toFee(i.fee), monthly: toFee(i.fee)
			})) ?? []),
			getTotal(),
			// { name: "Annualized Premium", Anual: 8401.60, SemiAnnual: 4510.18, Quarterly: 2304.81 },
		];
	}

	const getValuesPdf = () => {
		const toFix = (object) => {
			object.annual = (object.annual).toFixed(2);
			object.semiannual = (object.semiannual).toFixed(2);
			object.quarterly = (object.quarterly).toFixed(2);
			object.monthly = (object.monthly).toFixed(2);
			return object
		}
		const toFee = (fee) => {
			if (typeof fee === "string") {
				return parseFloat(Number(fee).toFixed(2));
			}
		}
		let main = getMainFares(true);
		main.name = getName(main.componentId);
		main = toFix(main);
		return [
			main,
			...(addDependant ? getDependantsFares(true).map(i => {
				i.name = getName(i.componentId)
				i = toFix(i)
				return i;
			}) : []),
			// getRider(),
			
			...(fee.data?.data?.map(i => ({
				name: texts?.general_table_title_fee ?? 'Admin Fee', annual: toFee(i.fee), semiannual: toFee(i.fee), quarterly: toFee(i.fee), monthly: toFee(i.fee)
			})) ?? []),
			getTotal(),
			// { name: "Annualized Premium", Anual: 8401.60, SemiAnnual: 4510.18, Quarterly: 2304.81 },
		];
	}

	const [list, setList] = useState(getValues())




	useEffect(() => {
		setList(getValues());
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fare, fee])

    return {
        list,
		getOtherPayments,
		getRider,
		getPremiumAnnual,
		getFullPremiumAnnual,
		getFullRider,
		getValuesPdf
    };
}