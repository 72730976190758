/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { equal } from "helpers/toolsHelper";
import { useDataParams } from "hooks/DataParamsHook";
import React, {useContext, createContext, useEffect, useState} from "react";
import { TextsContext } from "./textsContext";
import { useLoginLoader } from "hooks/LoginLoaderHook";

const userContext = createContext();

export function useAuth() {
    return useContext(userContext);
}

export function AuthProvider({ children }) {
    const [user, setUser] = useState(false);
    const [loading, setLoading] = useState(true);
    const {data, loading: paramLoading} = useDataParams();
    const { texts, loaded } = useContext(TextsContext);
    const {isDisabled} = useLoginLoader();

    const autoLog = () => {
        if (!user && !paramLoading) {
            const userdata = sessionStorage.getItem('userdata') ?? localStorage.getItem('userdata');
            const agentId = (data?.agentId || data?.AgentId) ?? 0;
            const userId = (data?.userId || data?.UserId) ?? 0;
            const onAgents = data?.onAgents ;
            const agentNumber = data?.agentNumber || data?.AgentNumber;

            if(onAgents && onAgents !== "0") {
                localStorage.setItem('source', "AC_APP");
            } else {
                localStorage.setItem('source', "web");
            }

            let sameValues = true;
            if ((agentId || userId || agentNumber) && userdata) {
                const parsed = JSON.parse(userdata);
                sameValues = equal(agentId, parsed?.agentId) && equal(userId, parsed?.userId) && equal(agentNumber, parsed?.agentNumber);
            }
            
            if (userdata && sameValues) {
                setUser(JSON.parse(userdata));
            } else {
                if (!agentId && agentId !== 0 && !userId && userId !== 0) {
                    setLoading(paramLoading);
                    return;
                };
                if (agentId == 0 && userId == 0 && !agentNumber) {
                    setLoading(paramLoading);
                    return;
                }
                const user_ = {agentId, userId, agentNumber};
                setUser(user_);
                sessionStorage.setItem('userdata', JSON.stringify(user_));
                localStorage.setItem('userdata', JSON.stringify(user_));
            }
        }
        setLoading(paramLoading)
    }

    useEffect(() => {
        try {
            if (isDisabled() && !validateIsDebug()) {
                setUser(false);
            } else {
                autoLog();
            }
        } catch (error) {
            autoLog();
        }
      }, [user, data, paramLoading, loaded, texts])

    const logout = () => {
        setUser(false);
        localStorage.removeItem('userdata');
        sessionStorage.removeItem('userdata');
    }

    const loginWithUserData = (agentNumber) => {
        if (!agentNumber) return false;
        const user_ = {agentId: 0, userId: 0, agentNumber};
        setUser(user_);
        sessionStorage.setItem('userdata', JSON.stringify(user_));
        localStorage.setItem('userdata', JSON.stringify(user_));
        localStorage.setItem('source', "link");
        return true;
    }

    const validateIsDebug = () => {
        const userdata = sessionStorage.getItem('userdata') ?? localStorage.getItem('userdata');
        if (!userdata) return false;
        const parsed = JSON.parse(userdata);
        
        if (parsed.agentId === 265) return true;
        return false;
    }


    const value = {
        user,
        setUser,
        logout,
        loginWithUserData
        ,loading
    }
    return <userContext.Provider value={value}>{children}</userContext.Provider>;
}
