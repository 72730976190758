/* eslint-disable eqeqeq */
import { FEMALE_BENEFITS, OUTPATION_BENEFITS, useCotizador } from "../context/apiContext";
import { arrayIncludesNumber, equal } from "../helpers/toolsHelper";
import {useBenefitValidation} from "./cotizador/BenefitValidationHook";

export const useSteps = () => {
  const { selectedBenefits, selectedBenefitsNames, selectedBenefitsIds, outpationBenefitsEnabled } =
    useCotizador();
  const {
    addDependant,
    isFemale,
    birthday,
    gender,
    name,
    surname,
    email,
    country,
    coverage,
    isEverythingLoading,
    errors,
    nonAmbulance,
    organ,
    acceptNonRegion,
    organRider,
    acceptNonRegion2,
    limitedCover,
    outpationBenefitsmaternidad,
    nonMaternidad
  } = useCotizador();

  const benefitValidator = useBenefitValidation(selectedBenefits);

  const getSteps = () => {
    return [...stepsGenerator()];
  };

  const getCurrentStepData = () => {
    const currentPath = window.location.pathname;
    return getSteps(addDependant).find((i) => i.path == currentPath);
  };

  const inSteps = (value) => {
    return getSteps().find((step) => step.path === value);
  };

  const getStep = (index) => {
    return getSteps().find((i) => i.index == index);
  };

  const getFromAllStep = (step) => {
    return getAllSteps().find((i) => i.step === step);
  };

  const benefitsGenerator = function* (currentStep) {
    if (!currentStep) return;

    const isValidStep = (step) => {
      return (currentStep >= step) || (currentStep === -1);
    }

    if (isValidStep(4)) {
      if (!arrayIncludesNumber(Object.values(selectedBenefitsIds), 63))
        yield "Maximum out of pocket";
 
      if (!Object.values(selectedBenefitsIds).includes("-12") && !Object.values(selectedBenefitsIds).includes("46"))
        if (selectedBenefitsNames["Geographical coverage"])
          yield* [
            "Providers Network",
          ];

      if (!arrayIncludesNumber(Object.values(selectedBenefitsIds), 46) && !arrayIncludesNumber(Object.values(selectedBenefitsIds), 44) )
        yield* [
          "Deductible Inside U.S.",
        ];

      yield* [
        "Geographical coverage",
        "Maximum coverage per insured, per policy year",
        "Deductible Outside U.S.",
        "Coinsurance"
      ];
    }
    if (isValidStep(5)) {
      yield* [
        "Preventive health checkup",
        "Congenital conditions",
        // "Dental treatment",
        // "Injuries during the training or practice of hazardous hobbies and non-professional sports",
        // "Injuries during the training or practice of non-hazardous professional sports",
        "Injuries during the training or practice of non-professional hazardous hobbies and sports",
        
        "Deductible elimination"
      ];
      if (addDependant) {
        yield "Free extended coverage for eligible dependents after policyholder’s death";
      }

    }

    if (isValidStep(6)) {
      if (outpationBenefitsEnabled) {
        yield* OUTPATION_BENEFITS;
      }
      
    }
    if (isValidStep(7) && outpationBenefitsmaternidad) {
      yield* FEMALE_BENEFITS;
    }
  };

  const getBenefitsPerStep = () => {
    return [...benefitsGenerator(-1)];
  };

  const stepsGenerator = function* () {
    let index = 0;

    yield {path: "/step1", module: 1, index: index++, step: 1};
    // yield { path: "/step12", module: 1, index: index++, step: 12 };

    const normalErrors = {
      ...errors,
      dependants: false,
      organ: false,
      dependantsTable: false,
      acceptNonOrgan: false,
      acceptNonRegion: false,
      acceptNonRegion2: false,
      limitedCover: false,
      nonAmbulance: false,
      benefitsList: false,
      nonMaternidad: false
    };

    if (
        !name ||
        !surname ||
        !email ||
        !gender ||
        !country ||
        !coverage ||
        !birthday ||
        !!Object.values(normalErrors)
            .map((i) =>
                Object.values(i).reduce((a, b) => {
                  if (a) return true;
                  if (!b) return false;
                  return b;
                }, false)
            )
            .filter((i) => i).length
    ) {
      return;
    }

    if (addDependant) {
      yield {path: "/step2", module: 1, index: index++, step: 2};
      yield {path: "/step3", module: 1, index: -10, step: 3};

      if (errors['dependantsTable'] && errors['dependantsTable']['invalid']) {
        return;
      }
    }

    if (isEverythingLoading()) return;

    yield {path: "/step11", module: 2, index: index++, step: 4};
    
    if (benefitValidator.shouldNotAdvance([...benefitsGenerator(4)])) return;
    if ((selectedBenefitsNames["Providers Network"] !== "Global Open" && limitedCover === false) &&
        !(selectedBenefitsIds['Geographical coverage'] === "46" || selectedBenefitsIds['Geographical coverage'] === 46)) {
      return;
    }
    if (equal(selectedBenefitsIds['Geographical coverage'], "46") && limitedCover === false) {
      return
    }


    yield { path: "/step4", module: 3, index: index++, step: 5 };


    if ((!organ) || benefitValidator.shouldNotAdvance([...benefitsGenerator(5)])) return;
    if (organ && organRider == 0) {
      return;
    }

    yield { path: "/step5", module: 3, index: index++, step: 6 };

    if (benefitValidator.shouldNotAdvance([...benefitsGenerator(6)])) return;
    if (!nonAmbulance && !outpationBenefitsEnabled) return;

    if (isFemale()) {
      const maternityIsNo = selectedBenefitsIds['Maternity and cesarean'] === "182";
      const newbornIsNo = selectedBenefitsIds['Maternity and newborn complications'] === '202';
      yield { path: "/step6", module: 3, index: index++, step: 7 };
      if (!outpationBenefitsmaternidad && !nonMaternidad) return;
      if ((maternityIsNo || newbornIsNo) && !nonMaternidad) return;
      if (benefitValidator.shouldNotAdvance([...benefitsGenerator(7)])) return;
    }

    //yield { path: "/step7", module: 4, index: index++, step: 8 };

    yield { path: "/step8", module: 5, index: index++, step: 9 };

    if (!acceptNonRegion || !acceptNonRegion2) return;

    yield { path: "/step9", module: 5, index: index++, step: 10 };
  };


  const getAllSteps = () => {
    return [
      { path: "/step1", module: 1, index: 0, step: 1 },
      { path: "/step2", module: 1, index: 1, step: 2 },
      { path: "/step3", module: 1, index: 2, step: 3 },
      { path: "/step11", module: 2, index: 3, step: 4 },
      { path: "/step4", module: 3, index: 3, step: 5 },
      { path: "/step5", module: 3, index: 4, step: 6 },
      { path: "/step6", module: 3, index: 5, step: 7 },
      //{ path: "/step7", module: 4, index: 6, step: 8 },
      { path: "/step8", module: 5, index: 7, step: 9 },
      { path: "/step9", module: 5, index: 8, step: 10 },
      // { path: "/step12", module: 6, index: 9, step: 11 },
    ];
  };

  return {
    getAllSteps,
    getCurrentStepData,
    getSteps,
    getStep,
    inSteps,
    getBenefitsPerStep,
    benefitsGenerator,
    getFromAllStep
  };
};
