import { DownloadPdf } from "pages/Downloader/DownloadPdf";
import Home from "../pages/Home/Home";
import LoginPage from "../pages/Login/LoginPage";
import {TestPdf} from "../pages/Base/TestPdf";

export const MainRoutes = [
    {
        path: "/login",
        element: <LoginPage />,
    },
    {
        path: "/home",
        element: <Home />,
    },
    {
        path: "/home/:userdata",
        element: <Home />,
    },
    {
        path: "/download-pdf/:appNumber",
        element: <DownloadPdf />,
    },
    {
        path: "/test/pdf",
        element: <TestPdf />,
    }
]
