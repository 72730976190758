import axios from 'axios';
import { getEnvVariables } from '../helpers/getEnvVariables'

const { API_LOGIN } = getEnvVariables()

export const loginRequest = async (username, password) => {
    try {
      const response = await axios.post(`${API_LOGIN}/login`, { username, password });
      return response.data;
    } catch (error) {
      throw error;
    }
  };