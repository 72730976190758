import React, { Fragment, useContext } from 'react'
import { TextsContext } from 'context/textsContext';
import StyleStep from "scss/step.module.scss";
import {useNavigate} from "react-router-dom";
import { Icon } from '@iconify/react';
import { useApplicationStep } from 'hooks/ApplicationStepManagerHook';


const StepAppComponents = () => {
	const { texts } = useContext(TextsContext);
	const navigate = useNavigate();
	const {getUserCurrentPath} = useApplicationStep();

	const handleClick = (path) => {
		return () => {
			// validateValues();
			navigate(path);
		}
	};

  return (
	<Fragment>
		<ul className={StyleStep.StyleStep}>
			{
				React.Children.toArray(getUserCurrentPath().map((page, idx) => (
					<li key={page.id} className={`${StyleStep.step} 
					${page?.visited && StyleStep.stepPastVisited} 
					${page?.current && StyleStep.stepVisited}
					${!page?.visited && !page.current && StyleStep.stepNoVisited} `}>
						<span className={StyleStep.dot}>
							<Icon icon="mdi:check-bold" className={StyleStep.icon} />
						</span>
						<span onClick={handleClick(page.path)} className={`${StyleStep.texto}`}>{texts[page?.key] ?? page?.name}</span>
					</li>
				)))
			}
		</ul>
	</Fragment>
  )
}

export default StepAppComponents
