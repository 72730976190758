import mainApi from "./mainApi";

export const sendCotizadorData = async (data) => {
    try {
      const response = await mainApi.post(`/services/application`, data);
      return response.data;
    } catch (error) {
      // alert(JSON.stringify(error.response))
      throw error;
    }
  };

export const saveCotizadorData = async (data) => {
    try {
        const response = await mainApi.post(`/services/save`, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};