import React, { Fragment, useContext, useEffect } from "react";
import { TextsContext } from '../../context/textsContext';
import { TitleH1Component } from "../../components/TitleH1Component";
import { TitleH3Component } from "../../components/TitleH3Component";
import Stylegeneral from '../../scss/general.module.scss';
import { BtnComponent } from "../../components/BtnComponent";
import { TitleH2Component } from "../../components/TitleH2Component";
import { useCotizador } from "../../context/apiContext";
import { DependantsComponent } from "../../components/DependantsComponent";
import styletext from '../../scss/text.module.scss';
import ValidaComponent from "components/ValidaComponent";
import { scrollToTopNew } from "components/GlobalFunction";
const Step2Pages = () => {
    const { texts, verifyCurrentLanguage } = useContext(TextsContext);
    const { dependants, resetDependantData } = useCotizador();

    useEffect(() => {
        verifyCurrentLanguage();
        //scroll to
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function scrollToTop() {
          scrollToTopNew();
        
        }
    return (
        <Fragment>
            <div className="dependbox w-100 align-items-center justify-content-start h-100 ">
                <section className="w-100 pad">
                    <div className="container " >
                        <div className="d-block d-lg-none" id="sectionscroll">
                            <TitleH2Component styleGroup={`raleway fw_400 lh_40 color_secundary mb-0 py-2 ${Stylegeneral.titulo_principal}`}>
                                {texts?.general_header_title ?? 'My VUMI VIP Care'}
                            </TitleH2Component>
                        </div>

                        <TitleH1Component styleGroup={`raleway_semibold fw_700 lh_30 color_primary pb-5 ${Stylegeneral.titulo}`}>
                            {texts?.step2_title ?? 'Tell us about your dependents'}
                        </TitleH1Component>

                        <TitleH2Component styleGroup={`Poppins fw_700 color_primary pb-0 ${Stylegeneral.titulo} ${styletext.textgeneral2}`}>
                            {texts?.step2_phrase_one ?? 'You may add your spouse/domestic partner or children ages 24 and under your policy.'}
                        </TitleH2Component>
                        <TitleH3Component styleGroup={`Poppins fw_400 color_primary pb-5 ${Stylegeneral.titulo} ${styletext.textgeneral}`}>
                            {texts?.step2_phrase_two ?? '*Dependents refer to family members under the age of 24. Upon reaching 24, a person is no longer eligible for dependent coverage.'}
                        </TitleH3Component>

                        {/*BODY*/}
                        <div className="depends">
                            {
                                !!dependants.length
                                && (<DependantsComponent />)

                            }
                        </div>


                        {/*END BODY*/}

                        <div className={` ${Stylegeneral.titulo}`} onClick={scrollToTop}>
                            <BtnComponent onClick={resetDependantData} to={"/step3"} >
                                {texts?.step2_button_add ?? 'Add dependent'}
                                <ValidaComponent keyName={"dependantsTable"} />
                            </BtnComponent>

                        </div>

                    </div>
                </section>

            </div>

        </Fragment>
    )
}
export default Step2Pages;
