import { getPdfOptions } from "helpers/pdfOptions";
import mainApi from "./mainApi";
import html2pdf from "html2pdf.js";
import {getEnvVariables} from "../helpers/getEnvVariables";
const { BACK_BASE_URL } = getEnvVariables()

export const downloadPdfWithData = async (postData, inFrame = false) => {
  const { data } = await mainApi.post("/htmlpdf", postData, {
    responseType: "plain/text",
  });
  const filename = `${postData["name"]} ${postData["surname"]} VIP Care.pdf`;

  if (inFrame) {
    const pdf = await html2pdf().from(data).set(getPdfOptions()).outputPdf();

    const response = await mainApi.post("/pdf-file",
      {fileB64: btoa(pdf), filename: filename});

    window.parent.postMessage({quote: BACK_BASE_URL + response.data.file}, "*");
  } else {
    const pdf = html2pdf().from(data);

    pdf
      .set(getPdfOptions())
      .save(filename);
  }
};

export const downloadPdfWithAppNumber = async (
  appNumber = false,
  showViewer = false
) => {
  const { data } = await mainApi.get(`/application_html/${appNumber}`, {
    responseType: "plain/text",
  });

  const pdf = html2pdf().from(data);
  if (showViewer) {
    return pdf.outputPdf();
  } else {
    pdf.set(getPdfOptions()).save(`${appNumber} VIP Care.pdf`);
  }
};


