import React, { Fragment, useState, useContext, useRef, useEffect } from "react";
import StyleGrid from "../scss/grid.module.scss";
import { ParagraphComponent } from "../components/ParagraphComponent";
import MenuComponent from "../components/MenuComponent";
import { Icon } from "@iconify/react";
import { TextsContext } from "../context/textsContext";
import { useLocation } from "react-router-dom";
import HeaderComponent from "../components/HeaderComponent";
import StepComponents from "../components/StepComponents";
import FooterAppComponent from "../components/FooterAppComponent";
import HeaderBackComponent from "../components/HeaderBackComponent";
import { TitleH6Component } from "../components/TitleH6Component";
import StyleBtn from "../scss/btn.module.scss";
import { Modal } from "react-bootstrap";
import Stylemodal from '../scss/modal.module.scss';
import { TitleH3Component } from "../components/TitleH3Component";
import { TitleH2Component } from "../components/TitleH2Component";
import StepAppComponents from "components/application/StepAppComponents";
const AppLayout = ({ children }) => {
    const { texts } = useContext(TextsContext);
    const [hamburger, sethamburger] = useState(true);
    const hHeader = useRef(null);
    const wpGroup = useRef(null);
    const hContent = useRef(null);
    const hFooter = useRef(null);
    const stepsG = useRef(null);
    const [disabledHeader, setDisabledHeader] = useState(false);
    const currentPath = window.location.pathname;
    const location = useLocation();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (currentPath === '/info-solicitud' || currentPath === '/coberturaprevia') {
            setShow(true);
        } else {
            setShow(false)
        }

    }, [currentPath])

   
    return (
        <Fragment>
            <div className={StyleGrid.wpApp}>
                {!disabledHeader &&
                    <div className={`${StyleGrid.wpMenu} ${!hamburger ? StyleGrid.active : null}`} ref={hHeader}>
                        <div className={StyleGrid.wpScroll}>
                            <MenuComponent sethamburger={sethamburger} hamburger={hamburger} phase={"application"} />
                        </div>

                        <div className={`${StyleGrid.tooltipicon}`}>
                            <div className={`${StyleGrid.text_tooltip} me-2 bg_gray_3`}>
                                <ParagraphComponent styleGroup='mb-0 color_gray_5 pb-0'>
                                    {texts?.menu_paragraph ?? 'Place the cursor over the information icon 🛈 for further explanation about each one of the fields.'}
                                </ParagraphComponent>
                            </div>
                            <div className={`${StyleGrid.iconInfo} color_secundary`}>
                                <Icon icon='mdi:information-symbol' />
                            </div>
                            {/*<img src="../../img/iconInfo.png" alt="icon " className={`${StyleGrid.iconInfo}`} />*/}
                        </div>
                    </div>
                }


                <div className={StyleGrid.wpGroup} ref={wpGroup}>
                    <div className={`${StyleGrid.wpHeader}`}>
                        <div className="d-none">
                            <HeaderComponent />
                        </div>

                        <div ref={stepsG}>
                            <div className="d-lg-none" >
                                <StepAppComponents />
                            </div>
                        </div>
                        {currentPath === '/info-solicitud' && <div className={`${StyleGrid.btnmodal} bg_primary mt-2`} onClick={handleShow}>
                            <div className={`container ${StyleGrid.contenido_btn} `}>
                                <TitleH6Component styleGroup="color-white raleway_bold mb-0">{texts?.application_info_modal_title ?? 'Información importante'}</TitleH6Component>
                                <div className={`${StyleGrid.iconmodal} color-white `}>
                                    <Icon icon='mdi:chevron-down' />
                                </div>
                            </div>
                        </div>}
                    </div>
                    <div className={`${StyleGrid.wpContent} w-100`} ref={hContent}>
                        <div className={`py-3 py-md-0  ${currentPath === '/info-solicitud' ? StyleGrid.contenidoTop : StyleGrid.contenidoTopPopup} ${StyleGrid.wpScroll} ${StyleGrid.wpScrollContent}`}>
                            <div className={`${StyleGrid.numero_App}`}>
                                <HeaderBackComponent routeTo='/' />
                            </div>
                            {children}
                        </div>
                    </div>
                    <div className={`${StyleGrid.wpFooter} ${StyleGrid.stylefooter} ${currentPath != '/editar-dependientes' ? `${StyleGrid.footersombra}` : ''}`}>
                        <FooterAppComponent />
                    </div>

                </div>

                <div className="">
                    <Modal show={show} onHide={handleClose} className={`informacionmodal d-flex justify-content-center align-items-center`}>

                        <Modal.Body className={`bg_gray_3  ${Stylemodal.modalinformacion} `}>
                            <div className={`  w-100 mb-0 ${Stylemodal.headerModal}`}>
                                <TitleH2Component styleGroup={`mb-0 raleway color_primary me-4 text-center fw_500 my-0 my-md-3 ${Stylemodal.titulomodal}`}>{texts?.application_info_modal_title ?? 'Información importante'}</TitleH2Component>
                                <div className="d-flex justify-content-end">
                                    <div className={`  ${Stylemodal.iconClose} ${Stylemodal.btncirculo}`} onClick={handleClose}>
                                        <Icon icon='mdi:close' />
                                    </div>
                                </div>

                            </div>
                            <div className="py-4">
                                <TitleH3Component styleGroup={`raleway ${Stylemodal.modalparrafo}`}>{texts?.application_info_modal_message ?? 'Esta solicitud es un documento legal que debe ser leído y completado en su totalidad, así como firmado por el solicitante principal y su agente. El solicitante principal y su(s) dependiente(s) deberán adjuntar a esta solicitud, una copia de su pasaporte o documento de identificación personal (cédula o equivalente). Si usted padece de alguna condición médica existente, deberá declararla detalladamente en las páginas 2, 3 y 4, Sección 5. Los solicitantes que tengan 65 años de edad cumplidos o más, deberán proporcionar la Declaración del Médico Tratante debidamente completada y firmada por su médico. Si la información proporcionada estuviera incompleta o sin firmar, esto provocará demoras en el proceso de evaluación y emisión de la póliza. Asimismo, VUMI® Group, I.I. se reservará el derecho de contactar al solicitante y/o a su médico.'}</TitleH3Component>
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                                <button onClick={handleClose} type="button" className={`Poppins fw_500 color_primary px-4 py-3  ${StyleBtn.btn}  d-flex justify-content-center align-items-center`} ><span>{texts?.application_info_continue_button ?? 'Continue'}</span></button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>

            </div>

        </Fragment>
    )
}

export default AppLayout;