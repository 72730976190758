export const getEnvVariables = () => {
  return {
    API_URL: 'https://api.myvipcare.vumigroup.com/api/',
    BACK_BASE_URL: 'https://api.myvipcare.vumigroup.com/',
    API_LOGIN: 'https://vumi-agents.iridian.co/api',
    // VUMI_LOGIN_URL: "https://agentsportal.vumigroup.com/index.php/vip_care/login",
    VUMI_LOGIN_URL: "https://agentsportal.vumigroup.com/index.php/vip_care/login",
    VUMI_LOGIN_URL_TEST: "https://agentsportal.vumigroup.com/index.php/vip_care/login"

  }
}

