import React, { Fragment } from "react";
import { isIOS } from "react-device-detect";
import ImageLoader from "./ImageLoaderComponent";


function Picture(props) {

  const dummy = props.dummy;
  //console.log("dummy in picture", dummy);

  let iOSVersion = null;

  if (isIOS) {
    const iOSVersionMatch = window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (iOSVersionMatch) {
      iOSVersion = parseInt(iOSVersionMatch[1], 10);
    }
  }

  return (
    <Fragment>
      { iOSVersion > 13 || !isIOS ? (
        <ImageLoader src={props.src} styleGroup={props.styleGroup} alt="Descripción de la imagen" dummy={dummy}/>
      ) : (
        <picture>
          <img className={`w-100 ${props.styleGroup}`} src={props.src} alt={props.alt} loading="lazy"/>
        </picture>
      )}
    </Fragment>
  );
}

export default Picture;
