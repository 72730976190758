import { TextsContext } from "context/textsContext";
import { useContext, useEffect, useState } from "react";

export const useDateSelects = ({
  maxYear = 2100,
  minYear = 1900,
  onChange = () => {},
  value,
  disabled = false,
  maxDays = null,
  minDays = null,
}) => {
  const [day, setDay] = useState(value?.getDate?.() ?? 0);
  const [month, setMonth] = useState(
    typeof value?.getMonth?.() !== "undefined" ? value?.getMonth?.() + 1 : 0
  );
  const [year, setYear] = useState(value?.getFullYear?.() ?? -1);
  const { getCurrentLanguage } = useContext(TextsContext);

  function getMonthNames(format = "long", locale = (getCurrentLanguage() ?? "en")) {
    const formatter = new Intl.DateTimeFormat(locale, {
      month: format,
      timeZone: "UTC",
    });
    const months_ = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const months = ((minDays || maxDays) ? getMonthsInRange(minDays, maxDays) : months_).map((month) => {
      const mm = month < 10 ? `0${month}` : month;
      return new Date(`2017-${mm}-01T00:00:00+00:00`);
    });
    return months.map((date) => ({number: date.getUTCMonth() + 1, name: formatter.format(date)}));
  }

  useEffect(() => {
    setDay(value?.getDate?.() ?? 0);
    setMonth(
      typeof value?.getMonth?.() !== "undefined" ? value?.getMonth?.() + 1 : 0
    );
    setYear(value?.getFullYear?.() ?? -1);
  }, [value]);

  const getArray = (to = 1, from = 1) => {
    if (from !== 0) to++;
    return [...Array(to - from).keys()].map((i) => i + from);
  };

  function daysInMonth(month = 1, year = 2000) {
    const nDate = new Date(year, month, 0).getDate();
    return isNaN(nDate) ? 30 : nDate;
  }

  useEffect(() => {
    if (day && month && year && year > 0) {
      onChange(new Date(year, month - 1, day));
    }
  }, [day, month, year]);

  function getMonthsInRange(daysBefore = 9999, daysAfter = 9999) {
    
    const today = new Date();
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - daysBefore);
  
    const endDate = new Date(today);
    endDate.setDate(today.getDate() + daysAfter);

    const monthsInRange = [];
    const diff = getMonthDiff(startDate, endDate);

    for (let index = 0; index <= diff; index++) {
      var currentMonth = startDate.getMonth() + 1;
      if (!monthsInRange.includes(currentMonth)) {
        monthsInRange.push(currentMonth);
      }
      startDate.setMonth(startDate.getMonth() + 1);
    }

    return monthsInRange;
  }

  const getMonthDiff = (d1, d2) => {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}


  return {
    daysInMonth,
    getMonthNames,
    getArray,
    day,
    month,
    year,
    setMonth,
    setDay,
    setYear
  };
};
