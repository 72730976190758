import React, { Fragment, useState, useEffect, useContext } from "react";
import { TextsContext } from "../../../context/textsContext";
import style from '../../../scss/InfoSolicitudo.module.scss';
import { TitleH2Component } from "../../../components/TitleH2Component";
import Stylegeneral from '../../../scss/general.module.scss'
import { TitleH3Component } from "../../../components/TitleH3Component";
import Picture from "../../../components/PictureComponent";
import styleForm from '../../../scss/form.module.scss';
import { Link, useLocation } from "react-router-dom";
import { CheckboxComponent } from "components/CheckboxComponent";
import { SolicitadoAgregadoList } from "components/application/SolicitadoAgregadoList";
import { InputTextComponent } from "components/application/InputTextComponent";
import ValidaComponent, { APPLICATION } from "components/ValidaComponent";
import Formulario1Component from "./components/Formulario1Component";
import { useApplication } from "context/applicationContext";
import StyleBtn from "scss/btn.module.scss";
import { Icon } from "@iconify/react";
const CoberturaPreviaPage = () => {
    const { texts, verifyCurrentLanguage } = useContext(TextsContext);
    const { pathname } = useLocation();
    const { [pathname]: values } = useApplication();

    const [imageToUpload, setImageToUpload] = useState(null); // [file, setFile
    const handleImage = (e) => {
        const file = e.target.files[0];
        setImageToUpload(file);
    }

    useEffect(() => {
        verifyCurrentLanguage();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Fragment>
            <section>
                <div className="container d-flex justify-content-center  ">
                    <div className={` pt-3 pb-0`}>
                        <div className="d-flex align-items-center">
                            <Picture src="/img/document-protection-hand.png" alt="VUMI" styleGroup={`${style.logosimg} w-auto mb-0 me-2`} />
                            <TitleH2Component styleGroup={`raleway_bold color-azul2 lh_30   mb-0 ${Stylegeneral.titulo} `}>
                                {texts?.prior_coverage_title ?? 'Información de Cobertura Previa'}
                            </TitleH2Component>
                        </div>
                    </div>
                </div>
            </section>
            <br />
            <section className="pb-5">
                <div className="container px-3">
                    <div className="row gy-4">

                        <div className="col-12  ">
                            <div className={`${styleForm.btn_BreviaActive}`}>
                                <div className={`form-check form-switch input-personalizado ${styleForm.col_input} me-3 me-xl-0  me-xxl-3`}>
                                    <CheckboxComponent section={pathname} className="form-check-input" type="checkbox" id="seguro" name={"haveInsurance"} />
                                </div>
                                <label className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                    {texts?.prior_coverage_checkbox_question_one ?? '¿Tiene usted un seguro de salud o plan de cobertura médica con alguna otra compañía?'}
                                    <div className="App-link">{texts?.prior_coverage_add ?? 'Agregar »'}</div>
                                </label>

                            </div>
                            {values?.["haveInsurance"] && <Formulario1Component />}
                        </div>

                        <div className="col-12  ">
                            <div className={`${styleForm.btn_BreviaActive}`}>
                                <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                    <CheckboxComponent section={pathname} className="form-check-input" type="checkbox" id="need" name={"haveInsuranceVumi"} />
                                </div>
                                <label htmlFor="need" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                    {texts?.prior_coverage_checkbox_question_two ?? '¿Ha tenido cobertura médica con VUMI® o cualquiera de sus afiliadas?'}
                                    <div className="App-link">{texts?.prior_coverage_add ?? 'Agregar »'}</div>
                                </label>

                            </div>
                            {values?.["haveInsuranceVumi"] && <div className={`pt-4 ${Stylegeneral.colform}`}>
                                <InputTextComponent
                                    section={pathname}
                                    name="numeropoliza"
                                    label="Número de poliza"
                                    placeholder='Ej:132342323'
                                    tooltip="Geographic area where the policy´s benefits apply"
                                    type='number'
                                />
                                <ValidaComponent keyName={"numeropoliza"} phase={APPLICATION} section={pathname} />
                            </div>}
                        </div>

                        <div className="col-12 ">
                            <div className={`${styleForm.btn_BreviaActive}`}>
                                <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                    <CheckboxComponent section={pathname} className="form-check-input" type="checkbox" id="need" name={"haveRefusedInsurance"} />
                                </div>
                                <label htmlFor="need" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                    {texts?.prior_coverage_checkbox_question_three ?? '¿Ha sido alguna solicitud de seguro de salud o de vida rechazada, aceptada sujeta a restricciones o a una prima mayor que las tarifas estándar de la aseguradora para alguno de los solicitantes?'}
                                    <div className="App-link">{texts?.prior_coverage_add ?? 'Agregar »'}</div>
                                </label>

                            </div>

                            {values?.["haveRefusedInsurance"] && <Fragment>
                                <div className={`pt-4 ${Stylegeneral.colform}`}>
                                    <InputTextComponent
                                        section={pathname}
                                        name="explicacion"
                                        label="Explicación"
                                        placeholder='Ej:Explicación'
                                        tooltip="Geographic area where the policy´s benefits apply"
                                        type='text'
                                    />
                                    <ValidaComponent keyName={"explicacion"} phase={APPLICATION} section={pathname} />
                                </div>

                                <div className={`d-flex flex-column-reverse flex-sm-row justify-content-start align-items-center mt-4 gy-3`}>
                                    <button type="button" className={`raleway  color_secundary   ${StyleBtn.btnApp} ${StyleBtn.btnAppcancel} ${StyleBtn.btn_border} me-sm-3  `}>{texts?.general_cancel_button ?? 'Cancel'}</button>
                                    <button type="button" className={`raleway  color_primary  d-flex align-items-center justify-content-center   ${StyleBtn.btnApp} ${StyleBtn.btnAppSave}   ${StyleBtn.btnant} `}>
                                        <Icon icon='cil:save' className={`${StyleBtn.iconBtnSave}`} />
                                        <span className="ms-2">{texts?.general_save_button ?? 'Save'}</span>
                                    </button>
                                </div>
                            </Fragment>}
                        </div>


                        <div className="block-list-edit">
                            <div className="d-flex justify-content-between justify-content-md-start">
                                <div className="n me-md-5">Alianza</div>
                                <div className="ac">
                                    <span>{texts?.prior_coverage_edit ?? 'Editar'}</span>
                                    <span>{texts?.prior_coverage_delete ?? 'Borrar'}</span>
                                </div>
                            </div>
                        </div>

                        <TitleH3Component styleGroup={`raleway_bold color-azul2 mb-0 h3_label`}>
                            {texts?.prior_coverage_medical_examn_title ?? 'Parte A: Exámenes Médicos'}
                        </TitleH3Component>

                        <div className="block-list-edit">
                            <div className="d-flex justify-content-between justify-content-md-start">
                                <div className="n me-md-5">Diego Bustamante</div>
                                <div className="ac">
                                    <span>{texts?.prior_coverage_edit ?? 'Editar'}</span>
                                    <span>{texts?.prior_coverage_delete ?? 'Borrar'}</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-12   ">
                            <div className={`${styleForm.btn_BreviaActive}`}>
                                <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                    <CheckboxComponent section={pathname} className="form-check-input" type="checkbox" id="need" name={"have5yearPriorExam"} />
                                </div>
                                <label htmlFor="" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                    {texts?.prior_coverage_checkbox_question_four ?? '¿Alguno de los solicitantes se ha realizado algún examen que no sea rutinario en los últimos 5 años? En caso afirmativo, indique:'}
                                    <div className="App-link"><Link className="App-link" to={'/registro4Page'}>{texts?.prior_coverage_add ?? 'Agregar »'}</Link></div>
                                </label>

                            </div>
                        </div>

                        <SolicitadoAgregadoList subsection={'/registro4Page'} />

                        <div className="col-12   ">
                            <div className={`${styleForm.btn_BreviaActive}`}>
                                <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                    <CheckboxComponent section={pathname} className="form-check-input" type="checkbox" id="need" name={"have5yearPriorExamPedGinRu"} />
                                </div>
                                <label htmlFor="" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                    {texts?.prior_coverage_checkbox_question_five ?? '¿Algunos de los solicitantes ha tenido un examen pediátrico, ginecológico o de rutina en los últimos 5 años? En caso afirmativo, indique:'}
                                    <div className="App-link"><Link className="App-link" to={'/registro5Page'}>{texts?.prior_coverage_add ?? 'Agregar »'}</Link></div>
                                </label>

                            </div>
                        </div>


                        <div className="block-list-edit">
                            <div className="d-flex justify-content-between justify-content-md-start">
                                <div className="n me-md-5">Diego Bustamante</div>
                                <div className="ac">
                                    <span>{texts?.prior_coverage_edit ?? 'Editar'}</span>
                                    <span>{texts?.prior_coverage_delete ?? 'Borrar'}</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-12  ">
                            <div className={`${styleForm.btn_BreviaActive}`}>
                                <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                    <CheckboxComponent section={pathname} className="form-check-input" type="checkbox" id="need" name={"have9monthExam"} />
                                </div>
                                <label htmlFor="" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                    {texts?.prior_coverage_checkbox_question_six ?? '¿Alguno de los solicitantes ha tenido consultas médicas en los últimos 9 meses? En caso afirmativo, indique:'}
                                    <div className="App-link"><Link className="App-link" to={'/registro6Page'}>{texts?.prior_coverage_add ?? 'Agregar »'}</Link></div>
                                </label>

                            </div>
                        </div>


                        <div className="block-list-edit">
                            <div className="d-flex justify-content-between justify-content-md-start">
                                <div className="n me-md-5">Diego Bustamante</div>
                                <div className="ac">
                                    <span>{texts?.prior_coverage_edit ?? 'Editar'}</span>
                                    <span>{texts?.prior_coverage_delete ?? 'Borrar'}</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-12  ">
                            <div className={`${styleForm.btn_BreviaActive}`}>
                                <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                    <CheckboxComponent section={pathname} className="form-check-input" type="checkbox" id="need" name={"haveAccident"} />
                                </div>
                                <label htmlFor="" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                    {texts?.prior_coverage_checkbox_question_seven ?? '¿Alguno de los solicitantes ha sufrido algún accidente? En caso afirmativo, indique:'}
                                    <div className="App-link"><Link className="App-link" to={'/registro7Page'}>{texts?.prior_coverage_add ?? 'Agregar »'}</Link></div>
                                </label>

                            </div>
                        </div>


                        <div className="block-list-edit">
                            <div className="d-flex justify-content-between justify-content-md-start">
                                <div className="n me-md-5">Diego Bustamante</div>
                                <div className="ac">
                                    <span>{texts?.prior_coverage_edit ?? 'Editar'}</span>
                                    <span>{texts?.prior_coverage_delete ?? 'Borrar'}</span>
                                </div>
                            </div>
                        </div>

                        <TitleH3Component styleGroup={`raleway_bold color-azul2 mb-0 h3_label`}>
                            {texts?.prior_coverage_medical_conditions_title ?? 'Parte B: Condiciones Médicas'}
                        </TitleH3Component>
                        <p>{texts?.prior_coverage_medical_conditions_phrase ?? 'Según su mejor entendimiento y conocimiento, ¿alguno de los solicitantes ha recibido tratamiento médico, o se ha realizado pruebas diagnósticas y/o ha padecido alguna de estas enfermedades?'}</p>

                        {
                            [
                                { name: "nasal", title: texts?.prior_coverage_checkbox_question_eight ?? "Desórdenes nasales, de la visión, del oído o de la garganta", ruta: '/registro8Page' },
                                { name: "convultions", title: texts?.prior_coverage_checkbox_question_nine ?? "Convulsiones, migrañas, parálisis u otro desorden neurológico", ruta: '/registro9Page' },
                                { name: "heart", title: texts?.prior_coverage_checkbox_question_ten ?? "Desórdenes del corazón, desórdenes circulatorios, hipertensión arterial, colesterol o triglicéridos elevados", ruta: '/registro9Page' },
                                { name: "allergy", title: texts?.prior_coverage_checkbox_question_eleven ?? "Alergias, asma, bronquitis, neumonía, desorden pulmonar u otro desorden del sistema respiratorio", ruta: '/registro9Page' },
                                { name: "stomach", title: texts?.prior_coverage_checkbox_question_twelve ?? "Enfermedades del esófago, estómago, intestinos, páncreas, vesícula biliar, hepatitis u otras enfermedades del hígado así como otro desorden del aparato digestivo", ruta: '/registro9Page' },
                                { name: "kidney", title: texts?.prior_coverage_checkbox_question_thirteen ?? "Enfermedades de los riñones o de las vías urinarias", ruta: '/registro9Page' },
                                { name: "column", title: texts?.prior_coverage_checkbox_question_fourteen ?? "Enfermedades o lesiones de la columna vertebral, reumatismo, artritis, gota u otro desorden muscular, articular o de los huesos", ruta: '/registro9Page' },
                                { name: "cancer", title: texts?.prior_coverage_checkbox_question_fifteen ?? "Cáncer o tumores benignos", ruta: '/registro9Page' },
                                { name: "anemia", title: texts?.prior_coverage_checkbox_question_sixteen ?? "Anemia, leucemia, linfoma, trastornos de la coagulación u otro desorden de la sangre", ruta: '/registro9Page' },
                                { name: "diabetes", title: texts?.prior_coverage_checkbox_question_seventeen ?? "Diabetes, desórdenes de la glándula tiroides u otro desorden endocrino/hormonal", ruta: '/registro9Page' },
                                { name: "skin", title: texts?.prior_coverage_checkbox_question_eighteen ?? "Enfermedades de la piel", ruta: '/registro9Page' },
                                { name: "hereditary", title: texts?.prior_coverage_checkbox_question_nineteen ?? "Enfermedades congénitas y/o hereditarias", ruta: '/registro9Page' },
                                { name: "std", title: texts?.prior_coverage_checkbox_question_twenty ?? "Enfermedades de transmisión sexual o de los órganos sexuales u otro desorden del sistema reproductivo", ruta: '/registro9Page' },
                                { name: "prostate", title: texts?.prior_coverage_checkbox_question_twenty_one ?? "Masculino: Enfermedades de la próstata", ruta: '/registro9Page' },
                                { name: "ginecology", title: texts?.prior_coverage_checkbox_question_twenty_two ?? "Femenino: Enfermedades de las mamas, los ovarios, el útero u otro desorden ginecológico", ruta: '/registro9Page' },
                                { name: "pregnancy", title: texts?.prior_coverage_checkbox_question_twenty_three ?? "Femenino: Embarazo actual / Embarazos anteriores", ruta: '/registro10Page' },
                                { name: "pregnancyProblem", title: texts?.prior_coverage_checkbox_question_twenty_four ?? "Femenino: Complicaciones del embarazo o del parto, embarazo múltiple, complicaciones del recién nacido que incluyen, pero no se limitan a condiciones congénitas o hereditarias", ruta: '/registro11Page' },
                                { name: "other", title: texts?.prior_coverage_checkbox_question_twenty_five ?? "¿Ha tenido otra enfermedad, padecimiento, lesión, accidente, cirugía, consulta médica, diagnóstico, pérdida involuntaria de peso u hospitalización no mencionada arriba?", ruta: '/registro12Page' },
                            ].map((item, index) => (
                                <div key={index}>
                                    <div className="col-12  ">
                                        <div className={`${styleForm.btn_BreviaActive}`}>
                                            <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                                <CheckboxComponent section={pathname} className="form-check-input" type="checkbox" id="need" name={item.name} />
                                            </div>
                                            <label htmlFor="need" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                                {item.title}
                                                <div className="App-link"><Link className="App-link" to={item.ruta + `?section=${item.name}`}>{texts?.prior_coverage_add ?? 'Agregar »'}</Link></div>
                                            </label>

                                        </div>
                                    </div>
                                    <SolicitadoAgregadoList subsection={item.ruta === '/registro9Page' ? item.name : item.ruta} />
                                </div>
                            ))
                        }

                        <p>
                            {texts?.prior_coverage_file_upload_phrase ?? 'Utilice este espacio para agregar documentos referentes a las condiciones médicas del solicitante y/o sus dependientes según sea el caso'}
                        </p>
                        <div className="row">
                            <div className="col-12 col-md-6 col-xl-4">
                                <div className="upload-form">
                                    <input type="file" id="file" className="inputfile" accept="image/jpg,image/png,application/pdf" onChange={handleImage} />
                                    <div className="input-replace">
                                        <div className="l">{texts?.general_select ?? 'Seleccione'}</div>
                                        <div className="name-file">
                                            {imageToUpload?.name ?? (texts?.general_not_file ?? 'Ningún archivo seleccionado')}
                                        </div>
                                    </div>
                                </div>
                                <div className="note">
                                    {texts?.general_file_type ?? 'Tipo de archivo jpg, png o pdf, máximo 2MB'}
                                </div>

                                <div className={`raleway btn-general`}>{texts?.general_upload_button ?? 'Subir'}</div>
                            </div>

                        </div>


                        <hr />

                        <TitleH3Component styleGroup={`raleway_bold color-azul2 mb-0 h3_label`}>
                            {texts?.prior_coverage_medications_title ?? 'Parte C: Medicamentos'}
                        </TitleH3Component>

                        <div className="col-12 ">
                            <div className={`${styleForm.btn_BreviaActive}`}>
                                <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                    <CheckboxComponent section={pathname} className="form-check-input" type="checkbox" id="need" name={"haveMedicaments"} />
                                </div>
                                <label htmlFor="" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                    {texts?.prior_coverage_checkbox_question_twenty_six ?? '¿Alguno de los solicitantes se le ha indicado o se encuentra bajo tratamiento con algún medicamento?'}
                                    <div className="App-link"><Link className="App-link" to={'/registro13Page'}>{texts?.prior_coverage_add ?? 'Agregar »'}</Link></div>
                                </label>

                            </div>
                        </div>
                        <div className="block-list-edit">
                            <div className="d-flex justify-content-between justify-content-md-start">
                                <div className="n me-md-5">Diego Bustamante</div>
                                <div className="ac">
                                    <span>{texts?.prior_coverage_edit ?? 'Editar'}</span>
                                    <span>{texts?.prior_coverage_delete ?? 'Borrar'}</span>
                                </div>
                            </div>
                            <div className="inner-block-list">
                                <div className="n">Victoria Bustamante</div>
                                <div className="ac">
                                    <span>{texts?.prior_coverage_edit ?? 'Editar'}</span>
                                    <span>{texts?.prior_coverage_delete ?? 'Borrar'}</span>
                                </div>
                            </div>
                        </div>

                        <TitleH3Component styleGroup={`raleway_bold color-azul2 mb-0 h3_label`}>
                            {texts?.prior_coverage_habits_title ?? 'Parte D: Hábitos'}
                        </TitleH3Component>

                        <div className="col-12 ">
                            <div className={`${styleForm.btn_BreviaActive}`}>
                                <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                    <CheckboxComponent section={pathname} className="form-check-input" type="checkbox" id="need" name={"haveDrugs"} />
                                </div>
                                <label htmlFor="" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                    {texts?.prior_coverage_checkbox_question_twenty_seven ?? '¿Alguno de los solicitantes usa o ha usado productos con nicotina, bebidas alcohólicas o drogas ilegales?'}
                                    <div className="App-link"><Link className="App-link" to={'/registro14Page'}>{texts?.prior_coverage_add ?? 'Agregar »'}</Link></div>
                                </label>

                            </div>
                        </div>
                        <div className="block-list-edit">
                            <div className="d-flex justify-content-between justify-content-md-start">
                                <div className="n me-md-5">Diego Bustamante</div>
                                <div className="ac">
                                    <span>{texts?.prior_coverage_edit ?? 'Editar'}</span>
                                    <span>{texts?.prior_coverage_delete ?? 'Borrar'}</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between justify-content-md-start">
                                <div className="n me-md-5">Victoria Bustamante</div>
                                <div className="ac">
                                    <span>{texts?.prior_coverage_edit ?? 'Editar'}</span>
                                    <span>{texts?.prior_coverage_delete ?? 'Borrar'}</span>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <p>
                            {texts?.prior_coverage_final_paragraph ?? 'Cualquier examen, tratamiento y/o consultas que se realice el solicitante principal o cualquiera de sus dependientes, antes de someter la solicitud o durante el proceso de evaluación de riesgos (si aplica) o de aprobación hasta la fecha de efectividad, deben ser informados a VUMI® Group, I.I. para añadir esta información a la solicitud. Asimismo, cualquier accidente y/o síntoma que haya surgido durante este período, debe ser informado a la compañía para tomar en cuenta esta nueva información para la aprobación de la cobertura y la emisión de los documentos correspondientes. La falta de notificación de esta información será considerada como muestra de mala fe al aceptar las obligaciones contractuales de la póliza, por lo que VUMI® Group, I.I. queda en capacidad de iniciar las acciones pertinentes.'}
                        </p>

                    </div>

                </div>

            </section>
        </Fragment >
    )
}
export default CoberturaPreviaPage;