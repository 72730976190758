import { iOS } from "./toolsHelper";

export const getPdfOptions = () => {
    let pdfOptions = {
      size: "A4",
      orientation: "portrait",
      margin: [0, -5],
      image: {
        type: "jpeg",
        quality: 100,
      },
      html2canvas: {
        scale: 3,
      },
    };
    if (iOS()) {
      pdfOptions = {
        size: "A4",
        orientation: "portrait",
        margin: [0, -5],
        image: {
          type: "jpeg",
          quality: 75,
        },
        html2canvas: {
          scale: 1.5,
        },
        jsPDF: {
          compress: true,
        },
      };
    }
    return pdfOptions;
  };
  