import { Icon } from "@mui/material";
import Picture from "components/PictureComponent";
import { TitleH1Component } from "components/TitleH1Component";
import React, { useContext, useEffect, useState } from "react";
import StyleBtn from "../../../scss/btn.module.scss";
import style from "../../../scss/login.module.scss";
import { loginRequest } from "../../../api/loginApi";
import { useAuth } from "context/userContext";
import { useNavigate } from "react-router-dom";
import { TextsContext } from "context/textsContext";
import { useLoginLoader } from "hooks/LoginLoaderHook";

export const LoginForm = ({ setModalvalidation, canShow = null }) => {
  const {isDisabled} = useLoginLoader();
  const [disabled, setDisabled] = useState(false);
  const [username, setUsername] = useState("");


  useEffect(() => {
    if (canShow === null) {
      setDisabled(false);
  
      try {
          if (isDisabled() && username !== "marco.espinosa.presentaciones") {
              setDisabled(true);
          }
      } catch (error) {
          setDisabled(false);
      }
    }
  }, [canShow, username]);

  
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [loading, setLoading] = useState(false);
  const { setUser } = useAuth();
  const navigate = useNavigate();

  const { texts } = useContext(TextsContext);



  const handleLogin = async () => {
    if (disabled) return;
    setLoading(true);
    try {
      const data = await loginRequest(username, password);
      setLoading(false);
      if (data) {
        setUser(data.usuario);
        sessionStorage.setItem("userdata", JSON.stringify(data.usuario));
        localStorage.removeItem("userdata");
        if (remember)
          localStorage.setItem("userdata", JSON.stringify(data.usuario));
        localStorage.setItem("source", "web");
        navigate("/home");
      }
    } catch (error) {
      console.error("Error en el inicio de sesión:", error);
      setLoading(false);
      setModalvalidation(true);
    }
  };

  return (
    <div className={`container ${style.body_login}`}>
      <div className={`${style.login}`}>
        <div className={`pb-3 pb-sm-4 ${style.imgsLogos}`}>
          <TitleH1Component
            styleGroup={`raleway_semibold fw_700  lh_30 color_primary mb-0 ${style.fztextlogin}`}
          >
            {texts?.login_title ?? "LOG IN"}
          </TitleH1Component>
          <Picture
            src="/img/MyVIPcare-TM-VUMI.png"
            alt="VUMI"
            styleGroup={` w-auto ${style.logosimg} `}
          />
        </div>
        <form action="#">
          <div className={`${style.formgrup} pb-4`}>
            <label
              htmlFor="#"
              className={`${style.labels} color_primary fw_700 raleway_bold pb-1`}
            >
              {texts?.login_username ?? "Username"}
            </label>
            <input
              type="text"
              placeholder={texts?.login_username ?? "Username"}
              className={`${style.inputs} raleway color_gris_8`}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div className={`pb-3`}>
            <label
              htmlFor="#"
              className={`${style.labels}  color_primary fw_700 raleway_bold pb-1`}
            >
              {texts?.login_password ?? "Password"}
            </label>

            <div className={`${style.inputs} d-flex align-items-center`}>
              <input
                type={showPassword ? "text" : "password"}
                placeholder="**********"
                className={`${style.inpust_password} raleway color_gris_8 w-100`}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <div
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                className={`${style.iconPassword}`}
              >
                <Icon
                  icon={showPassword ? "mdi:eye-off" : "mdi:eye-outline"}
                  className={`${style.icon} `}
                />
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center align-items-center">
            <button
              type="button"
              className={`Poppins fw_500 color_primary   ${StyleBtn.btn} ${style.wpbtnLogin} ${disabled && StyleBtn.btnComingSoon} `}
              disabled={loading || disabled}
              onClick={handleLogin}
            >
              {!disabled ? (texts?.login_button ?? "Log in") : texts?.coming_soon ?? "Coming Soon"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
