import { useEffect, useState } from "react"

const predefined = {
    agentId: 0,
    userId: 0,
    lang: "en",
    onAgents: false,
};


export const useDataParams = () => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        
        const dataParam = queryParams.get("key");
        const agentId = queryParams.get("agentId");
        const userId = queryParams.get("userId");
        const lang = queryParams.get("lang");
        const onAngents = queryParams.get("onAngents");
        const onAgents = queryParams.get("onAgents");

        if (dataParam) {
            handleDataParam(dataParam);
        } else if (agentId && userId) {
            handleAgentsAndUser(agentId, userId, lang, onAgents || onAngents);
        }
        setLoading(false);
    }, [setData]);

    /**
     * Get user data from params
     */
    const handleDataParam = (dataParam) => {
        let obj = {};
        try {
            obj = JSON.parse(atob(dataParam));
        } catch(e) {
            return;
        }
        setData({
            ...predefined,
            ...obj});
    }

    /**
     * Get user data from params
     * 
     * To be removed in next version
     * @deprecated
     */
    const handleAgentsAndUser = (agentId, userId, lang, onAgents) => {
        setData({
            ...predefined,
            ...{
            agentId,
            userId,
            lang,
            onAgents
        }})
    }

    return {
        data,
        loading
    };
}