import React, { Fragment, useEffect, useContext } from "react";
import { TextsContext } from "context/textsContext";
import styleForm from '../../../scss/form.module.scss';
import { InputTextComponent } from "../../../components/application/InputTextComponent";
import ValidaComponent, { APPLICATION } from "../../../components/ValidaComponent";
import { SelectAppComponent } from "../../../components/application/SelectAppComponent";
import Stylegeneral from '../../../scss/general.module.scss'
import { useLocation } from "react-router-dom";
import { CheckboxComponent } from "components/CheckboxComponent";

const Registro9Page = () => {
    const { texts, verifyCurrentLanguage } = useContext(TextsContext);
    const {pathname, search} = useLocation();
    const queryParams = new URLSearchParams(search)
    const section = queryParams.get("section") ?? pathname;

    useEffect(() => {
		verifyCurrentLanguage();
	}, []);

    return (
        <Fragment>
            <section>
                <div className="container py-3">
                    <div className={`${styleForm.btn_activar}`}>
                        <label htmlFor="migranas" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                            {texts?.page_nine_checkbox_one ?? 'Convulsiones, migrañas, parálisis u otro desorden neurológico'}
                        </label>
                        <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                            <input className="form-check-input" type="checkbox" id="migranas" name={queryParams.get("section") ?? "addDependant"} />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container pb-5">
                    <div className="row gy-4">
                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <SelectAppComponent
                                name="solicitante"
                                label={texts?.general_form_field_applicant ?? "Solicitante"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                placeholder='Ej:Colombia'
                                type='text'
                                options={[]}
                                section={section}
                            />

                            <ValidaComponent phase={APPLICATION} section={section} keyName={"solicitante"} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={section}
                                name="condicion"
                                label={texts?.general_form_field_condition ?? "Condición, enfermedad o lesión"}
                                placeholder={texts?.general_form_placeholder_condition ?? "Ej: Cabeza"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                required
                                type='text'
                            />
                            <ValidaComponent keyName={"condicion"} phase={APPLICATION} section={section} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={section}
                                name="fecha"
                                label={texts?.general_form_field_from ?? "Desde"}
                                placeholder='Ej: Cabeza'
                                tooltip="Geographic area where the policy´s benefits apply"
                                required
                                type='date'
                            />
                            <ValidaComponent keyName={"fecha"} phase={APPLICATION} section={section} />
                        </div>

                        <div className={`col-12 ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.btn_activar}`}>
                                <label htmlFor="infosolicitud" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                    {texts?.general_checkbox_medical_condition ?? '¿El solicitante padece actualmente de esta condición médica?'}
                                </label>
                                <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                    <CheckboxComponent section={section} className="form-check-input" type="checkbox" id="infosolicitud" name={"addDependant"} />
                                </div>
                            </div>
                        </div>

                        <div className={`col-12 ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={section}
                                name="fechahasta"
                                label={texts?.general_form_field_to ?? "Hasta"}
                                placeholder='Ej: Cabeza'
                                tooltip="Geographic area where the policy´s benefits apply"
                                required
                                type='date'
                            />
                            <ValidaComponent keyName={"fechahasta"} phase={APPLICATION} section={section} />
                        </div>

                    

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={section}
                                name="tratamientodyresultados"
                                label={texts?.general_form_field_treatment ?? "Tratamiento y resultado"}
                                placeholder={texts?.general_form_placeholder_treatment ?? "Ej: Resultado"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                type='text'
                                required
                            />
                            <ValidaComponent keyName={"tratamientodyresultados"} phase={APPLICATION} section={section} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={section}
                                name="currentConditionState"
                                label={texts?.general_form_field_status ?? "Estado actual de la condición, enfermedad o lesión"}
                                placeholder={texts?.general_form_placeholder_status ?? "Ej:Lorem ipsum"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                textej={texts?.general_form_phrase_status ?? "(ej. si está en tratamiento, desapareció o está controlada)"}
                                required
                                type='text' 
                                styleicon={'w-75'}
                            />
                            <ValidaComponent keyName={"currentConditionState"} phase={APPLICATION} section={section} />
                        </div>

                        
                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={section}
                                name="nombremedico"
                                label={texts?.general_form_field_doctor_name ?? "Nombre del médico"}
                                placeholder={texts?.general_form_placeholder_doctor_name ?? "Ingresar Nombre"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                required
                                type='text'
                            />
                            <ValidaComponent keyName={"nombremedico"} phase={APPLICATION} section={section} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={section}
                                name="numbertele"
                                label={texts?.general_form_field_doctor_phone ?? "Teléfono del médico"}
                                placeholder={texts?.general_form_placeholder_doctor_phone ?? "Ej: 3423545676"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                required
                                type={"number"}
                                numeric
                            />
                            <ValidaComponent keyName={"numbertele"} phase={APPLICATION} section={section} />
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}
export default Registro9Page;