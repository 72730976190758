import React, { Fragment, useContext } from 'react'
import { TextsContext } from '../context/textsContext';
import { TitleH3Component } from './TitleH3Component'
import { ParagraphComponent } from './ParagraphComponent'
import StyleTxt from "../scss/text.module.scss";
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import { TitleH2Component } from './TitleH2Component';

const HeaderBackComponent = ({ routeTo = "/" }) => {
	const { texts } = useContext(TextsContext);

	return (
		<div className="py-2 ">
			<div className="container">
				<div className="d-flex align-items-center justify-content-between">
					<div className="flex-wrap">
						<TitleH2Component styleGroup={` raleway fw_400 color_primary my-1 ps-2 text-md-center`}>
							{texts?.application_info_header_title ?? 'Mi Solicitud'}
						</TitleH2Component>
						<div className="d-flex  ps-2">
							<ParagraphComponent styleGroup={` ${StyleTxt.textoHeaerapp} raleway fw_700 color_secundary my-1`}>
								{texts?.application_info_header_phrase ?? 'Número de aplicación'}
							</ParagraphComponent>
							<ParagraphComponent styleGroup={` ${StyleTxt.textoHeaerapp} raleway fw_400 color_gray_5 my-1 ms-3`}>
								543435
							</ParagraphComponent>
						</div>
					</div>
					{/*<div className="d-flex align-items-center justify-content-center">
						<Icon icon="fluent:ios-arrow-24-regular" />
						<Link className={`raleway fw_300 color_primary_2 pe-2 ${StyleTxt.p}`} to={`${routeTo}`}>Volver</Link>
	</div>*/}
				</div>
			</div>
		</div>
	)
}

export default HeaderBackComponent