import React, { Fragment, useEffect, useContext } from "react";
import { TextsContext } from "context/textsContext";
import { TitleH2Component } from "components/TitleH2Component";
import Picture from "components/PictureComponent";
import style from 'scss/InfoSolicitudo.module.scss';
import Stylegeneral from 'scss/general.module.scss'
import styleForm from 'scss/form.module.scss';
import { SelectAppComponent } from "components/application/SelectAppComponent";
import ValidaComponent, { APPLICATION } from "components/ValidaComponent";
import { InputTextComponent } from "components/application/InputTextComponent";
import StyleBtn from "scss/btn.module.scss";
import { Icon } from "@iconify/react";
import { useLocation } from "react-router-dom";
import { CheckboxComponent } from "components/CheckboxComponent";
import { useApplication } from "context/applicationContext";

const HistoriaFamiliarPage = () => {
    const { texts, verifyCurrentLanguage } = useContext(TextsContext);
    const {pathname} = useLocation();
    const { [pathname]: values } = useApplication();

    useEffect(() => {
        verifyCurrentLanguage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <Fragment>
            <section>
                <div className="container d-flex justify-content-center">
                    <div className="d-flex align-items-center py-4">
                        <Picture src="/img/HomeCircle.png" alt="VUMI" styleGroup={`${style.logosimg} w-auto mb-0 me-2`} />
                        <TitleH2Component styleGroup={`raleway_bold color-azul2 lh_30 mb-0 ${Stylegeneral.titulo} `}>
                            {texts?.family_history_title ?? 'Historia Familiar'}
                        </TitleH2Component>
                    </div>
                </div>
            </section>
            <section>
                <div className="container px-3 pb-5 pt-md-5">
                    <div className="row gy-4">
                        <div className="col-12  ">
                            <div className={`${styleForm.btn_activar}`}>
                                <label htmlFor="need" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                    {texts?.family_history_option_checkbox_one ?? '¿Alguno de los solicitantes tiene historia familiar de diabetes, hipertensión, desórdenes del corazón, cáncer, o enfermedad congénita o hereditaria?'}
                                </label>
                                <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                    <CheckboxComponent section={pathname} className="form-check-input" type="checkbox" id="need" name={"need"} />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 ">
                            <div className={`${styleForm.btn_activar}`}>
                                <label htmlFor="adoptado" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                    {texts?.family_history_option_checkbox_two ?? 'Si alguno de los dependientes es adoptado, ¿conoce su historial médico familiar?'}
                                </label>
                                <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                    <CheckboxComponent section={pathname} className="form-check-input" type="checkbox" id="adoptado" name={"adoptado"} />
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
            <section className="pb-5">
                {values?.["adoptado"] && <div className="container px-3">
                    <div className="row gy-4 ">
                        <div className={`col-12 ${Stylegeneral.colform}`}>
                            <SelectAppComponent
                                name="solicitante"
                                label={texts?.general_form_field_applicant ?? 'Solicitante'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                placeholder=''
                                section={pathname}
                                options={[]}
                                required
                            />

                            <ValidaComponent keyName={"solicitante"}  section={pathname} phase={APPLICATION} />
                        </div>

                        <div className={`col-12 ${Stylegeneral.colform}`}>
                            <SelectAppComponent
                                name="Rsolicitante"
                                label={texts?.family_history_form_field_relation ?? 'Relación con el solicitante'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                placeholder=''
                                section={pathname}
                                options={[]}
                                required
                            />

                            <ValidaComponent keyName={"Rsolicitante"}  section={pathname} phase={APPLICATION} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <SelectAppComponent
                                name="enfermedad"
                                label={texts?.family_history_form_field_illness ?? 'Enfermedad'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                placeholder=''
                                section={pathname}
                                options={[]}
                                required
                            />

                            <ValidaComponent keyName={"enfermedad"}  section={pathname} phase={APPLICATION} />
                        </div>

                        <div className={`col-12 ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                name="historia"
                                label={texts?.family_history_form_field_history ?? 'Historia'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                placeholder={texts?.family_history_form_placeholder_history ?? 'Ej: Información'}
                                type='text'
                                section={pathname}
                                required
                            />

                            <ValidaComponent keyName={"historia"} section={pathname} phase={APPLICATION} />
                        </div>
                    </div>

                    <div className={`d-flex flex-column-reverse flex-sm-row justify-content-center align-items-center mt-4`}>
                        <button type="button" className={`raleway  color_secundary   ${StyleBtn.btnApp} ${StyleBtn.btnAppcancel}  me-sm-3  `}>{texts?.general_cancel_button ?? 'Cancel'}</button>
                        <button type="button" className={`raleway  color_primary  d-flex align-items-center justify-content-center   ${StyleBtn.btnApp} ${StyleBtn.btnAppSave}   ${StyleBtn.btnant} `}>
                            <Icon icon='cil:save' className={`${StyleBtn.iconBtnSave}`} />
                            <span className="ms-2">{texts?.general_save_button ?? 'Save'}</span>
                        </button>
                    </div>
                </div>}
            </section>

        </Fragment>
    )
}
export default HistoriaFamiliarPage;