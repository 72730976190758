import styleForm from "../scss/form.module.scss";
import { TextsContext } from '../context/textsContext';
import React, { useState, useContext } from "react";
import { useCotizador } from "../context/apiContext";
import { Fragment } from "react";
import ValidaComponent from "./ValidaComponent";
import { useBenefitComplement } from "hooks/cotizador/BenefitComplementHook";
import { useBoolComplement } from "hooks/cotizador/BoolComplementHook";

const SelectBenefitsComponent = ({
    providersSignificant = false,
    setProviders, setMaternity,setRecienNacido, className, benefits, benefit, setMostrarPais = () => {
    },
    complements = "", boolComplements = ""
}) => {
    const { texts } = useContext(TextsContext);
    const {
        handleOnChangeBenefit,
        selectedBenefits,
        ignoredBenefits,
        selectedBenefitsNames,
        changeDataValue,
        preferreds
    } = useCotizador();
    const [isOpen, setIsOpen] = useState(false);
    const complementsHandler = useBenefitComplement(complements);
    const boolComplementsHandler = useBoolComplement(boolComplements);

    const getOptions = () => {
        try {
            if (!benefits?.length || !benefit) return [];
            let newList = benefits
                .filter((i) => i["factorId.nameFactor"] === benefit)
                .filter((i) => !ignoredBenefits.includes(i.id))
                ;

            newList.sort(
                (a, b) => {
                    if (newList.map(i => extractNumberFromString(i?.description)).every(i => isNaN(i))) {
                        if (preferreds.includes(b.id)) return 1;
                        if (a.description < b.description) {
                            return -1;
                        }
                        if (a.description > b.description) {
                            return 1;
                        }
                        return 0;
                    }
                    return extractNumberFromString(b?.description) -
                        extractNumberFromString(a?.description)
                }
            )

            if (benefit === "Maximum outpatient coverage per insured, per policy year") {
                newList.sort(
                    (a, b) => {
                        if (preferreds.includes(b.id)) return 1;
                        return extractNumberFromString(b?.description) -
                            extractNumberFromString(a?.description)
                    }
                )
                newList = newList.filter(i => i.id !== 82);
            }
            if (benefit === "Deductible elimination") {

                newList.sort(
                    (a, b) => {

                        // If description is "none", move to start
                        if (a.description.toLowerCase() === "none") return -1;
                        if (b.description.toLowerCase() === "none") return 1;

                        // Otherwise, sort alphabetically
                        if (a.description < b.description) return -1;
                        if (a.description > b.description) return 1;

                        return 0; // equal descriptions
                    }
                )
            }

            return newList;
        } catch (e) {
            return [];
        }

    };

    const extractNumberFromString = (inputString) => {
        if (!inputString) return NaN;
        const stringWithoutCommas = String(inputString).replace(/,/g, "");
        const match = stringWithoutCommas.match(/[\d.]+/);

        if (match) {
            const numberString = match[0];
            return parseFloat(numberString);
        }

        return NaN;
    };

    const isPreferred = (id) => {
        return preferreds.includes(id);
    };


    // useEffect(() => {
    //     if (!selectedBenefitsIds[benefit]) {
    //         const preferred = getOptions().find((i) => isPreferred(i.id));
    //         let selected = null;
    //         if (preferred) selected = preferred;
    //         if (!preferred && benefit != "Providers Network" ) selected = getOptions()?.length && getOptions()[0];
    //         if (benefit === "Providers Network") selected = getOptions()[1];
    //         const value = selected?.factor;
    //
    //         if (value) {
    //             const name = benefit;
    //             handleOnChangeBenefit({target: {value, name}}, {text: selected?.description, id: selected?.id});
    //
    //         }
    //     }
    // }, []);

    if (benefit === "Geographical coverage") {
        setMostrarPais?.(selectedBenefitsNames[benefit]);
    }

    if (benefit === "Providers Network") {
        setProviders?.(selectedBenefitsNames[benefit]);
    }

    if (benefit === "Maternity and cesarean") {
        setMaternity?.(selectedBenefitsNames[benefit]);
    }

    if (benefit === "Maternity and newborn complications") {
        setRecienNacido?.(selectedBenefitsNames[benefit]);
    }

    const handleOnChangeBenefitWrapper = (e) => {
        if (complements){
            complementsHandler.updateComplements();
        }
        if (boolComplements) {
            boolComplementsHandler.updateComplements();
        }
        handleOnChangeBenefit(e);
    }

    const handleOnProviderChange = (e) => {
        changeDataValue("limitedCover", true);
        handleOnChangeBenefitWrapper(e);
    }

    const getBenefitText = (description) => {
        if (description === "Policy limit") {
            description = "Up to the policy limit"
        }
        return texts[description] !== undefined ? texts[description] : description;
    }

    return (
        <Fragment>
            {/*<span onClick={() => setIsOpen(true)}>{selectedBenefitsNames[benefit]}</span>*/}
            <select
                name={benefit}
                className={className}
                onChange={providersSignificant ? handleOnProviderChange : handleOnChangeBenefitWrapper}
                value={selectedBenefits?.[benefit] ?? 0}
                onClick={() => setIsOpen(!isOpen)}
                onBlur={() => setIsOpen(false)}
                onFocus={() => setIsOpen(false)}
            >

                <option value={0} disabled={true}>
                    {texts?.general_form_field_select ?? 'Select'}
                </option>

                {getOptions().map((item) => (
                    <option key={item.id} value={item.factor} data-description={item.description} data-id={item.id}>

                        <span>
                            {getBenefitText(item.description)}
                        </span>

                        {isPreferred(item.id) && isOpen &&
                            (selectedBenefits[benefit] !== item.factor || isOpen) && (
                                <span></span>
                            )}{" "}

                        {item.description === 'Worldwide Excl. Premium Countries' && <span>(*)</span>}
                    </option>

                ))}


            </select>
            <div className={`${styleForm.validador}`}>
                <ValidaComponent benefit={true} keyName={benefit} styleGroup={`${styleForm.valida}`}></ValidaComponent>
            </div>
        </Fragment>

    );
};

export default SelectBenefitsComponent;
