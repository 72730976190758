import { getPlan, validateAgentNumber } from "api/validatorApi";

/**
 * Validates the username from vumi at the beginning
 */
export const useAgentNumberValidation = () => {

    const validate = async (agentNumber) => {
        const data = await getPlan();
        // console.log({data});
        if (data) {
            const validation = await validateAgentNumber(agentNumber, data['planId.description']);
            return !!validation?.data?.is_valid;
        }
        return false;
    }

    return {
        validate
    };
}