import React, { Fragment, useEffect, useContext, useState } from "react";
import { TextsContext } from '../../context/textsContext';
import Stylegeneral from "../../scss/general.module.scss";
import { TitleH1Component } from "../../components/TitleH1Component";
import style from '../../scss/tabla.module.scss';
import styleForm from '../../scss/form.module.scss';
import { useCotizador } from "../../context/apiContext";
import ModalEnvioEmailComponent from "../../components/ModalEnvioEmailComponent";
import { useFeeTable } from "../../hooks/FeeTableHook";
import StyleBtn from "../../scss/btn.module.scss";
import { Icon } from "@iconify/react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { usePdf } from "../../hooks/PdfHook";
import { useNavigate } from "react-router-dom";
import { sendEmailWithPdf } from "../../api/emailApi";
import { useCotizacionAdapter } from "../../hooks/CotizadorAdapterHook";
import Stylemodal from "../../scss/modal.module.scss";
import { TitleH3Component } from "components/TitleH3Component";
import { getEnvVariables } from "helpers/getEnvVariables";
import { useAuth } from "context/userContext";
import { saveCotizador } from "../../api/cotizadorApi";
import { downloadPdfWithData } from "../../api/pdfGeneratorApi";
import { useCotizacionSaveAdapter } from "../../hooks/CotizadorSaveAdapterHook";
import Loader from "components/LoaderComponent";
import Stylestep8 from 'scss/step8.module.scss'
import Styleboton from "scss/btn.module.scss";
import { TitleH2Component } from "components/TitleH2Component";
import { CircularProgress } from "@mui/material";
import { isInFrame } from "../../helpers/toolsHelper";
import { scrollToTopNew } from "components/GlobalFunction";
const { VUMI_LOGIN_URL, VUMI_LOGIN_URL_TEST } = getEnvVariables()

const Step9Pages = () => {
  const { texts, verifyCurrentLanguage } = useContext(TextsContext);
  const { setShow, timely, handleOnChange, email, calculation, name, surname, fee, fare, coverage, fareDependant, fareRider } = useCotizador();
  const [loading, setLoading] = useState(false);
  const [showMessager, setShowMenssager] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const handleClose = () => setShowMenssager(false);
  const [currentPdf, setCurrentPdf] = React.useState(null);
  const handleCloseSave = () => {
    setShowSave(false);
    window.location.href = '/home';
  }
  const { user } = useAuth();

  const [values] = useState({
    semiannual: texts?.step9_second_payment ?? "Second payment",
    quarterly: texts?.step9_2_3_4_payment ?? "2,3 and 4 payment",
    monthly: texts?.step9_other_payments ?? "Other payments"
  })
  const { getPdfData } = usePdf();
  const { createApplication } = useCotizacionAdapter();
  const { list, getOtherPayments } = useFeeTable();
  const navigate = useNavigate();

  useEffect(() => {
    verifyCurrentLanguage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApply = async () => {
    setLoading(true);
    setShowMenssager(true);
    try {
      const { last_inserted_application_id, url } = await createApplication();
      await sendEmailWithPdf({ ...getPdfData(), to: email, applicationId: last_inserted_application_id, url }, user);
      setLoading(false);
      redirect(url);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  }

  const redirect = (url) => {
    if (!isInFrame()) {
      if (user.userId.toString() === "7783" || user.agentId.toString() === "10159") {
        window.location.href = url;
      } else {
        window.location.href = url;
      }
    } else {
      window.parent.postMessage({url}, "*");
    }
    // navigate("/login-app");
  }

  const isNotActive = () => {
    return false;
  }
  
  const { createApplicationSave } = useCotizacionSaveAdapter();

  const handleSaveButton = () => {
    setLoading(true);
    saveCotizador(getPdfData())
      .then(({ data }) => {
        return createApplicationSave(data.id)
      }).then(({last_inserted_quotation_id}) => {
        return sendEmailWithPdf({ ...getPdfData(), to: email, applicationId: last_inserted_quotation_id, url: VUMI_LOGIN_URL }, user);
      }).then(() => {
        setLoading(false);
        setShowSave(true);
      }).catch((e) => {
        console.error(e);
        setLoading(false);
      });
    // setShow(true); funcionalidad anterior
  }

  const irInicio = () => {
    window.location.href = '/home'
  }

  const getCalculation = () => {
    if (fee.isFetching || fare.isFetching || fareDependant.isFetching || fareRider.isFetching) {
      return texts?.footer_value ?? 'Calculating...';
    }
    return "$" + parseFloat(calculation.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2 });
  }
  const handlePdf = () => {
    if (loading) return;
    setLoading(true);
    downloadPdfWithData({ ...getPdfData() }, isInFrame()).then(async (resp) => {
      if (resp) {
        setCurrentPdf(btoa(resp))
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }

  function scrollToTop() {
    navigate("/step8")
	  scrollToTopNew();
    
	}
  return (
    <Fragment>
      <section className="mt-4" id="sectionscroll">
        <div className="container">
          <TitleH1Component styleGroup={`raleway_semibold fw_700  lh_30 color_primary pb-4 ${Stylegeneral.titulo}`}>
            {texts?.step9_title ?? "Here's the breakdown of your customized quote"}
          </TitleH1Component>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-7 col-xl-8 order-2 order-md-1 ">
              <div className="row">
                <div className="col-xl-10 offset-xl-2 text-end">
                  <div className="py-4 d-flex justify-content-between alignitems-center mx-sm-0 mx-2">
                    <div className="d-flex align-items-center">
                      <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1 `}>{texts?.step9_label_Payment_frequency ?? 'Payment_frequency'}</label>
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={<Tooltip id="tooltip-top" className="custom-tooltip">
                          {texts?.step9_tooltip_Payment_frequency}
                        </Tooltip>}
                      >
                        <Icon icon={'mdi:information-outline'} className={` color_gray_5 ${styleForm.iconselect}`} />
                      </OverlayTrigger>
                    </div>
                    <div className={style.ContainerSelector}>
                      <select value={timely} onChange={handleOnChange} name="timely" className={style.sSelector} >
                        <option value="annual">{texts?.step9_radio_title_annual ?? 'Annual'}</option>
                        <option value="semiannual">{texts?.step9_radio_title_semiannual ?? 'Semi-Annual'}</option>
                        <option value="quarterly">{texts?.step9_radio_title_quarterly ?? 'Quarterly'}</option>
                        <option value="monthly">{texts?.step9_radio_title_monthly ?? 'Monthly'}</option>
                      </select>
                    </div>

                  </div>
                </div>

                <div className="col-xl-10 offset-xl-2">
                  <div className={style.table_container}>
                    {React.Children.toArray(list.map(item => (
                      <div className={`${style.tabla_row} d-flex w-100  raleway`}>
                        <div className={`w-50 h-auto text-start  ${(!values[timely] && item.name === "Total") ? style.tabla_cellstarttotal : style.tabla_cellstart}`}><span className="fw_700">{(values[timely] && item.name === "Total") ? (texts?.step9_first_payment ?? "First payment") : item.name}</span></div>
                        <div className={`w-50 h-auto text-end fw_700 ${(!values[timely] && item.name === "Total") ? style.tabla_celltotal : style.tabla_cell} `}>${parseFloat(item[timely]).toLocaleString('en-US', { minimumFractionDigits: 2 })}</div>
                      </div>
                    )))}
                    {
                      values[timely] && (
                        <div className={`${style.tabla_row} d-flex w-100  raleway`}>
                          <div className={`w-50 h-auto text-start ${style.tabla_cellstart}`}><span className="fw-bold">{values[timely]}</span></div>
                          <div className={`w-50 h-auto text-end ${style.tabla_cell}`}>${parseFloat(getOtherPayments()[timely]).toLocaleString('en-US', { minimumFractionDigits: 2 })}</div>
                        </div>
                      )
                    }
                  </div>
                </div>
                <div className="col-xl-10 offset-xl-2 ">
                  <div className="d-flex justify-content-center mt-5 ">
                    <button disabled={loading} type="button" className={`Poppins fw_500 color_primary px-4 me-2 ${Stylegeneral.vwbtn} ${StyleBtn.btn}`} onClick={handleSaveButton}>
                      <Icon icon="ant-design:save-outlined" className={`${StyleBtn.icono} me-2`} />
                      <span>{texts?.step9_share_pdf ?? "Save"}</span>
                    </button>
                    <button disabled={(loading || isNotActive())} type="button" className={`Poppins fw_500 color_primary px-4 me-2 ${Stylegeneral.vwbtn} ${style.btnApply} ${StyleBtn.btn}`} onClick={() => { handleApply() }}>
                      <Icon icon="la:user-check" className={`${StyleBtn.icono} me-2`} />
                      <span>{texts?.step9_button_Apply_Now ?? 'Apply Now'}</span>
                    </button>
                  </div>
                  <div className="d-flex justify-content-center mt-5">
                    <button type="button" disabled={loading} className={`Poppins fw_500 px-4 me-2  ${StyleBtn.btnNeutro} ${Stylegeneral.vwbtn} ${StyleBtn.btn}`} onClick={scrollToTop }>
                      <span className="color_secundary">{texts?.footer_button_previous ?? 'Previous'}</span>
                    </button>
                  </div>
                </div>
              </div>

            </div>
            <div className="col-12 col-md-5 col-xl-4 order-1 order-md-2">
              <div className={`${Stylestep8.tarjeta_info} mx-sm-0 mx-2`}>
                <TitleH3Component styleGroup="raleway_bold fw_700  color_primary mb-3 mb-md-2">
                  {texts?.step8_phrase_summary ?? 'Summary'}
                </TitleH3Component>
                <TitleH2Component styleGroup="raleway fw_400  color_secundary mb-2 ">
                  {name} {surname}
                </TitleH2Component>
                <TitleH3Component styleGroup=" pb-2 pb-md-0 ">
                  <span
                    className={`color_primary raleway_bold  ${Stylestep8.tama_subtitle}`}>{texts?.step8_phrase_date ?? 'Coverage Start Date'}</span>
                  <p

                    className={`${Stylestep8.tama_date}  raleway color_primary`}>{coverage}</p>
                </TitleH3Component>

                {loading ? <CircularProgress size={50} />
                  :
                  <button type="button" onClick={handlePdf}
                    className={`${Styleboton.btn} d-flex align-items-center my-3 py-3 py-md-2 mb-1 ${Styleboton.btndescarga} w-100`}>
                    <Icon icon="prime:file-pdf" className={`${Stylestep8.icono}`} /> <span
                      className={`${Styleboton.text_boton} raleway`}>{texts?.step8_phrase_download ?? 'Download quote'}</span>
                  </button>
                }
                <hr className={`${Stylestep8.line}`} />
                <TitleH2Component styleGroup="raleway fw_400  color_primary_2 ">
                  {texts?.step8_phrase_cost ?? 'Estimated annual cost:'}
                </TitleH2Component>
                <TitleH1Component styleGroup={`raleway fw_600 color_secundary`}>
                  {getCalculation()}
                </TitleH1Component>
              </div>
            </div>

          </div>
        </div>
      </section>
      <ModalEnvioEmailComponent />


      <Modal show={showMessager} onHide={handleClose} className={`modal-apply d-flex justify-content-center align-items-center `}>
        <Modal.Body className={`bg_gray_3  ${Stylemodal.modalconfirmar} `}>
          <div className={`d-flex align-items-center justify-content-end w-100 pb-1 ${Stylemodal.modal_content}`}>
            <div className={`  ${Stylemodal.iconClose} ${Stylemodal.iconCloseflex}`}>
              <Icon icon='mdi:close' onClick={() => { handleClose() }} />
            </div>
          </div>

          <div className={`${Stylemodal.iconModal} d-flex justify-content-center align-items-center pb-2`}>
            <TitleH1Component styleGroup={`raleway mb-0  color_primary mb-0 fw_700  text-center `}>
              {texts?.Modal_title_Apply ?? '¡Just one step to go!'}
            </TitleH1Component>
            <Icon icon='mdi:checkbox-marked-circle' className={`${Stylemodal.iconcheckbox} ms-2`} />
          </div>
          <div className="d-flex justify-content-center flex-column w-100 pb-4 pb-md-3">
            <TitleH3Component styleGroup={`raleway mb-0 pt-2 color_primary mb-0 fw_400  text-center  ${Stylemodal.textShere}`}>
              {texts?.modal_textTitle_Apply ?? '¡Just one step to go!'}
            </TitleH3Component>
            <TitleH3Component styleGroup={`raleway mb-0 pt-3 color_primary mb-0 fw_400  text-center pb-2 ${Stylemodal.textShere}`}>
              {texts?.modal_textTitle_Apply2 ?? '¡Just one step to go!'}
            </TitleH3Component>
          </div>


          <div className="d-flex justify-content-center align-items-center">
            {/*<button onClick={() => { handleClose() }} type="button" className={`Poppins fw_500 color_primary px-4  ${StyleBtn.btn}  d-flex justify-content-center align-items-center`}  ><span>{texts?.modal_button_understood ?? 'Entendido'}</span></button>*/}
            <Loader Loaderpunto></Loader>

          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showSave} onHide={handleCloseSave} className={`modal-apply d-flex justify-content-center align-items-center `}>
        <Modal.Body className={`bg_gray_3  ${Stylemodal.modalconfirmar} `}>
          <div className={`d-flex align-items-center justify-content-end w-100 pb-1 ${Stylemodal.modal_content}`}>
            <div className={`  ${Stylemodal.iconClose} ${Stylemodal.iconCloseflex}`}>
              <Icon icon='mdi:close' onClick={() => { handleCloseSave() }} />
            </div>
          </div>
          <div className={`${Stylemodal.iconModal}`}>
            <Icon icon='mdi:checkbox-marked-circle' className={`${Stylemodal.iconcheckbox}`} />
          </div>
          <div className="d-flex justify-content-center w-100 mb-3 mb-lg-4">
            <TitleH3Component styleGroup={`raleway mb-0 py-0 color_primary mb-0 fw_700  text-center  ${Stylemodal.textShere}`}>
              {texts?.modal_textTitle_save ?? 'Se guardo con éxito '}
            </TitleH3Component>
          </div>

          <div className="d-flex justify-content-center align-items-center">
            <button onClick={() => { irInicio() }} type="button" className={`Poppins fw_500 color_primary px-4  ${StyleBtn.btn}  d-flex justify-content-center align-items-center`}  ><span>{texts?.modal_button_understood ?? 'Entendido'}</span></button>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default Step9Pages;
