import { useCotizador } from 'context/apiContext';
import { useEffect, useState } from 'react'


/**
 * 
 * @param {string[]|string} complementBoolName 
 * @returns 
 */
export const useBoolComplement = (complementBoolName) => {
    const [bool, setBool] = useState(complementBoolName);
    const {
        contextState,
        changeDataValue
    } = useCotizador();

    useEffect(() => {
      setBool(complementBoolName);
    }, [complementBoolName])
    

    const updateComplement = (boolName = null) => {
        if (!boolName) boolName = bool;
        if (contextState[boolName] === undefined) return; //delete in case of changing the way context saves variables
        if (contextState[boolName] === true) return;
        changeDataValue(boolName, true);
    }

    const updateComplements = () => {
        if (typeof bool == 'string' ) {
            updateComplement();
            return;
        }
        for(const _bool of bool) {
            updateComplement(_bool);
        }
    }

  return {
    updateComplement,
    updateComplements
};
}
