import React, { Fragment, useState, useContext } from 'react';
import { TextsContext } from '../../../../context/textsContext';
import style from '../../../../scss/collapse.module.scss';
import { Icon } from '@iconify/react';
import styleText from '../../../../scss/text.module.scss';
import { NavLink } from 'react-router-dom';

function CollapsibleIndependienteComponent(props) {
    const { texts } = useContext(TextsContext);
    const [isOpen, setIsOpen] = useState(false);

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };
console.log(props.key)
    return (
        <Fragment>
            <div className={`${style.collapseHeader}  ${!isOpen && style.bordeBottom} d-flex align-items-center`}
                data-bs-toggle="collapse"
                data-bs-target="#miColapso"
                onClick={toggleCollapse}
            >
                <Icon icon={isOpen ? 'mdi:chevron-up' : 'mdi:chevron-down'} className={`${style.iconcollapse} me-2`} />
                <span className='raleway'>{texts?.dependents_info_collapsible_title ?? 'Dependientes'} {props.key}</span>
            </div>

            <div className={`collapse ${isOpen ? 'show' : ''}`} id="miColapso">
                <div className={`card-body ${style.card}`}>
                    <div className={`${style.cardInfo} pb-2`}>
                        <span className={`raleway_bold color_primary  ${styleText.titulo_informativo}`}>
                            {texts?.dependents_info_collapsible_name ?? 'Nombre(s)'}
                        </span>
                        <span className={`raleway color_gray_1  ${styleText.titulo_informativo}`}>
                            {'Ingrid'}
                        </span>
                    </div>

                    <div className={`${style.cardInfo} pb-2`}>
                        <span className={`raleway_bold color_primary  ${styleText.titulo_informativo}`}>
                            {texts?.dependents_info_collapsible_surname ?? 'Apellido(s)'}
                        </span>
                        <span className={`raleway color_gray_1  ${styleText.titulo_informativo}`}>
                            {'Ortiz'}
                        </span>
                    </div>
                    <div className={`${style.cardInfo} pb-2`}>
                        <span className={`raleway_bold color_primary  ${styleText.titulo_informativo}`}>
                            {texts?.dependents_info_collapsible_sex ?? 'Sexo'}
                        </span>
                        <span className={`raleway color_gray_1  ${styleText.titulo_informativo}`}>
                            {'Femenino'}
                        </span>
                    </div>
                    <div className={`${style.cardInfo} pb-2`}>
                        <span className={`raleway_bold color_primary  ${styleText.titulo_informativo}`}>
                            {texts?.dependents_info_collapsible_birth_date ?? 'Fecha de nacimiento'}
                        </span>
                        <span className={`raleway color_gray_1  ${styleText.titulo_informativo}`}>
                            {'Oct-11-1990'}
                        </span>
                    </div>

                    <div className={`${style.cardInfo} pb-2`}>
                        <span className={`raleway_bold color_primary  ${styleText.titulo_informativo}`}>
                            {texts?.dependents_info_collapsible_relation ?? 'Relación con el solicitante principal'}
                        </span>
                        <span className={`raleway color_gray_1  ${styleText.titulo_informativo}`}>
                            {'Casado'}
                        </span>
                    </div>

                    <div className={`${style.cardInfo} pb-2`}>
                        <span className={`raleway_bold color_primary  ${styleText.titulo_informativo}`}>
                            {texts?.dependents_info_collapsible_height ?? 'Estatura'}
                        </span>
                        <span className={`raleway color_gray_1  ${styleText.titulo_informativo}`}>
                            {' 1.60 '}
                        </span>
                    </div>

                    <div className={`${style.cardInfo} pb-2`}>
                        <span className={`raleway_bold color_primary  ${styleText.titulo_informativo}`}>
                            {texts?.dependents_info_collapsible_weight ?? 'Peso'}
                        </span>
                        <span className={`raleway color_gray_1  ${styleText.titulo_informativo}`}>
                            {'60kl'}
                        </span>
                    </div>
                    <NavLink to={'/editar-dependientes'} className={`${style.btnEditar} raleway color_secundary`}>{texts?.dependents_info_collapsible_edit ?? 'Editar »'}</NavLink>
                </div>


            </div>
        </Fragment>
    );
}

export default CollapsibleIndependienteComponent;