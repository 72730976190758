import React, { Fragment } from "react";
import { TitleH3Component } from "../../../components/TitleH3Component";
import style from "../../../scss/collapse.module.scss";
import styleForm from "../../../scss/form.module.scss";
import SelectBenefitsComponent from "../../../components/SelectBenefitsComponent";
import { useSteps } from "../../../hooks/useSteps";

const AccordionSelectComponent = ({
  title = "",
  benefit = "",
  benefits = [],
  providersSignificant = false,
  complements = "",
  boolComplements = "",
}) => {
  const { getBenefitsPerStep } = useSteps();
  return (
    <Fragment>
      {getBenefitsPerStep().includes(benefit) && (
        <div className="d-flex justify-content-between align-items-center  pb-2">
          <TitleH3Component
            styleGroup={`raleway   color_primary ${style.titulo} ${style.titulocollasep}`}
          >
            {title}
          </TitleH3Component>

          <div
            className={`bg_gray_6 py-1 px-3  ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}
          >
            <SelectBenefitsComponent
              className={` ${styleForm.selecfondo} raleway `}
              benefit={benefit}
              benefits={benefits}
              providersSignificant={providersSignificant}
              complements={complements}
              boolComplements={boolComplements}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default AccordionSelectComponent;
