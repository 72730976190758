import { useContext } from "react";
import { TextsContext } from "../context/textsContext";
import { useCountries } from "../api/quoterApi";
import { useCotizador } from "../context/apiContext";
import { getAge } from "../helpers/toolsHelper";
import { useFeeTable } from "./FeeTableHook";
import { useSteps } from "./useSteps";

export const usePdf = () => {
  const { getCurrentLanguage, texts } = useContext(TextsContext);
  const { contextState, plan, isFemale } = useCotizador();
  const { getValuesPdf } = useFeeTable();
  const { data: countries, getCountryName } = useCountries();
  const {getBenefitsPerStep} = useSteps()

  const getGenderName = (value) => {
    // eslint-disable-next-line eqeqeq
    return value == 1 ? texts?.general_form_option_male ?? "MALE" : texts?.general_form_option_female ?? "FEMALE";
  }

  const getSpouse = () => {
    return contextState.dependants.find(
      // eslint-disable-next-line eqeqeq
      (dependant) => dependant.componentId == "15"
    );
  };

  const todayDate = new Date();
  const today = `${todayDate.getUTCFullYear()}-${
    todayDate.getMonth() + 1
  }-${todayDate.getUTCDate()}`;

  const getPdfData = () => ({
    locale: getCurrentLanguage(),
    ...contextState,
    outpationBenefitsmaternidad: (contextState?.outpationBenefitsmaternidad && isFemale()),
    dependants: contextState.dependants.map((dependant) => ({
      ...dependant,
      age: getAge(dependant.birthday),
      countryName: getCountryName(dependant.country, getCurrentLanguage()),
      genderName: getGenderName(dependant.gender)
    })),
    list: getValuesPdf(),
    today,
    selectedBenefitsNames: Object.entries(contextState.selectedBenefitsNames).reduce((arr, [key, value]) => {
      arr[key] = getBenefitsPerStep().includes(key) ? value : ("None"); 
      return arr;
    }, {}),
    selectedBenefitsIds: Object.entries(contextState.selectedBenefitsIds).reduce((arr, [key, value]) => {
      arr[key] = getBenefitsPerStep().includes(key) ? value : (0); 
      return arr;
    }, {}),
    countryName: getCountryName(contextState.country, getCurrentLanguage()),
    // eslint-disable-next-line eqeqeq
    genderName: contextState.gender == 1 ? texts?.general_form_option_male ?? "MALE" : texts?.general_form_option_female ?? "FEMALE",
    applicantAge: getAge(contextState.birthday),
    planName: plan?.data?.data?.length ? plan.data.data[0]['planId.description'] : "",
    spouseData: getSpouse()
      ? {
          ...getSpouse(),
          age: getAge(getSpouse().birthday),
          countryName: getCountryName(getSpouse().country, getCurrentLanguage()),
          genderName: getGenderName(getSpouse().gender)
        }
      : {},
  });

  return {
    getPdfData,
  };
};
