import React, { Fragment, useContext } from "react";
import { TextsContext } from "context/textsContext";
import StyleFooter from "../scss/footer.module.scss";
import StyleBtn from "../scss/btn.module.scss";
import { useApplicationStep } from "../hooks/ApplicationStepManagerHook";

const FooterAppComponent = () => {
  const { texts } = useContext(TextsContext);
  const currentPath = window.location.pathname;
  const {
    goNextValidation: goNext,
    goPrevious,
    getCurrentPath,
  } = useApplicationStep();

  const path = getCurrentPath();
  return (
    <Fragment>
      <div className="container">
        <div
          className={
            path?.baseButtonCss
              ? path.baseButtonCss
              : `${
                  currentPath === "/info-solicitud"
                    ? StyleFooter.footerAppBtns
                    : "d-flex justify-content-between justify-content-sm-end align-items-center "
                }`
          }
        >
          {path?.leftButton ? (
            path?.leftButton
          ) : (
            <button
              type="button"
              onClick={goPrevious}
              className={`raleway  color_primary  ${StyleBtn.btnApp} ${StyleBtn.wpbtn} ${StyleBtn.btnant} me-sm-3`}
            >
              {texts?.footer_button_previous ?? 'Previous'}
            </button>
          )}
          {path?.rightButton ? (
            path?.rightButton
          ) : (
            <button
              type="button"
              onClick={goNext}
              className={`raleway  color_primary  ${StyleBtn.btnApp} ${StyleBtn.wpbtn} ${StyleBtn.btnsig}`}
            >
              {texts?.footer_button_next ?? 'Next'}
            </button>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default FooterAppComponent;
