import React, { Fragment, useEffect, useContext } from "react";
import { TextsContext } from "context/textsContext";
import styleForm from 'scss/form.module.scss';
import ValidaComponent, { APPLICATION } from "components/ValidaComponent";
import { SelectAppComponent } from "components/application/SelectAppComponent";
import Stylegeneral from 'scss/general.module.scss'
import { InputTextComponent } from "components/application/InputTextComponent";
import { CheckboxComponent } from "components/CheckboxComponent";
import { useLocation } from "react-router-dom";

const Registro5Pag = () => {
    const { texts, verifyCurrentLanguage } = useContext(TextsContext);
    const {pathname} = useLocation();

    useEffect(() => {
		verifyCurrentLanguage();
	}, []);

    return (
        <Fragment>
            <section>
                <div className="container py-3">
                    <div className={`${styleForm.btn_activar}`}>
                        <label htmlFor="examenpediatrico" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                            {texts?.page_five_checkbox_one ?? '¿Algunos de los solicitantes ha tenido un examen pediátrico, ginecológico o de rutina en los últimos 5 años? En caso afirmativo, indique:'}
                        </label>
                        <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                            <CheckboxComponent className="form-check-input" id="examenpediatrico" name={"have5yearPriorExamPedGinRu"} section={"/coberturaprevia"} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="pb-5">
                <div className="container">
                    <div className="row gy-4">
                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <SelectAppComponent
                                name="solicitante"
                                label={texts?.general_form_field_applicant ?? "Solicitante"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                placeholder='Ej:Colombia'
                                type='text'
                                options={[]}
                                section={pathname}
                            />

                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"solicitante"} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={pathname}
                                name="texamen"
                                label={texts?.page_five_form_field_exam ?? "Tipo de examen"}
                                placeholder={texts?.page_five_form_placeholder_exam ?? "Ej: Examen general"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                type='text'
                                required
                            />
                            <ValidaComponent keyName={"texamen"} phase={APPLICATION} section={pathname} />
                        </div>

                        <div className={`col-12 ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={pathname}
                                name="fecha"
                                label={texts?.page_five_form_field_exam ?? "Tipo de examen"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                type='date'
                                required
                            />
                            <ValidaComponent keyName={"fecha"} phase={APPLICATION} section={pathname} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={pathname}
                                name="resultados"
                                label={texts?.page_five_form_field_result ?? "Resultado"}
                                placeholder={texts?.page_five_form_placeholder_result ?? "Ej: Control"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                type='text'
                                required
                            />
                            <ValidaComponent keyName={"resultados"} phase={APPLICATION} section={pathname} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.btn_activar}`}>
                                <label htmlFor="examenp" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                    {texts?.page_five_checkbox_two ?? '¿Se realizaron exámenes posteriores? En caso afirmativo, detalle los resultados:'}
                                </label>
                                <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                    <CheckboxComponent className="form-check-input" type="checkbox" id="examenp" name={"addDependant"} />
                                </div>
                            </div>
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={pathname}
                                name="resultados"
                                label={texts?.page_five_form_field_result ?? "Resultado"}
                                placeholder={texts?.page_five_form_placeholder_result ?? "Ej: Control"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                type='text'
                                required
                            />
                            <ValidaComponent keyName={"resultados"} phase={APPLICATION} section={pathname} />
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}
export default Registro5Pag;