import React from "react";
import Stylegeneral from "scss/general.module.scss";
import styleForm from "scss/form.module.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { useApplicationInput } from "../../hooks/ApplicationInputHook";
import { ParagraphComponent } from "../ParagraphComponent";

/** 
 * @description No usar con type "number", en vez de eso quiten el type y pongan el atributo "numeric". El type number está dando error con las validaciones de numeros
 */
export const InputTextComponent = (props) => {
  const {
    isMobileDevice = false,
    label = "",
    placeholder = "",
    tooltip = "",
    name = "",
    required = false,
    type = "",
    disabled = false,
    textej="",
    styleicon
  } = props;
  const {handleOnChange, value} = useApplicationInput(props);
  
  return (
    <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
      <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
        <label
          htmlFor={name}
          className={`${styleForm.labels} ${Stylegeneral.contentLabel} ${styleicon} raleway color_primary fw_700`}
        >
          {required && <span className={styleForm.punto}>*</span>}
          {label}
         <ParagraphComponent styleGroup="raleway" >{textej}</ParagraphComponent> 
        </label>
        <OverlayTrigger
          key={isMobileDevice ? "left" : "top"}
          placement={isMobileDevice ? "left" : "top"}
          overlay={
            <Tooltip id="tooltip-top" className="custom-tooltip">
              {tooltip}
            </Tooltip>
          }
        >
          <Icon
            icon={"mdi:information-outline"}
            className={`color_gray_5 ${styleForm.iconselect}`}
          />
        </OverlayTrigger>
      </div>

      <input
        type={type !== "number" ? type : "text"}
        className={`${styleForm.input_with_line} raleway fw_400`}
        placeholder={placeholder}
        name={name}
        onChange={handleOnChange}
        value={value}
        required={required}
        disabled={disabled}
      />
    </div>
  );
};
