import { useFooterApplication } from "hooks/FooterApplicationHook";
import { useContext } from "react";
import { TextsContext } from "context/textsContext";
import { useNavigate } from "react-router-dom";
import StyleBtn from "scss/btn.module.scss";

export function CancelButton({ href, action }) {
  const { texts } = useContext(TextsContext);
  const navigate = useNavigate();
  const {[action]: fn} = useFooterApplication();

  const handleClick = () => {
    fn?.();
    navigate(href);
  }

  return (
    <button
      type="button"
      onClick={handleClick}
      className={`raleway  color_secundary ${StyleBtn.btnApp} ${StyleBtn.btnAppcancel}  me-sm-3`}
    >
      {texts?.footer_button_cancel ?? 'Cancel'} 
    </button>
  );
}
