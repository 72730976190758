import React, { useEffect, Fragment } from "react";
import load from "../scss/loader.module.scss";

const Loader = (props) => {
  const Loaderpunto = props.Loaderpunto;
  useEffect(() => {
    //console.log("loaders ", load);
  }, []);

  return (
    <Fragment>
      {!Loaderpunto ? <div className="d-flex justify-content-center">
        <div className={`d-flex align-items-center`}>
          <div className={load.loader}></div>
        </div>
      </div>:null}
      {Loaderpunto ? <div className={load.loader_container}>
            <div className={load.dot}></div>
            <div className={load.dot}></div>
            <div className={load.dot}></div>
          </div>:null}
    </Fragment>
  );
};

export default Loader;