import { useLocation, useNavigate } from "react-router-dom";
import { useApplicationList } from "./ApplicationListHook";
import { useApplication } from "context/applicationContext";

export const useFooterApplication = () => {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const {add: add_, edit, resetData} = useApplicationList();
  const {sectionHasNoErrors, setFailedNextIntent, applicationState} = useApplication();

  const add = () => {
    if (sectionHasNoErrors(pathname)) {
      setFailedNextIntent(false);
      const isEdit = (applicationState[pathname]?.id !== null && applicationState[pathname]?.id !== undefined);
      isEdit ? edit(pathname) : add_(pathname);
      resetData(pathname);
      navigate('/coberturaprevia');
      return;
    }
    setFailedNextIntent(true);
  }

  const reset = () => {
    resetData(pathname);
  }

  return {
    add,
    reset
  }
}
