import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { TextsContext } from '../../context/textsContext';
import Picture from '../../components/PictureComponent';
import Stylegeneral from '../../scss/general.module.scss';
import { TitleH2Component } from '../../components/TitleH2Component';
import { BtnComponent } from '../../components/BtnComponent';
import { Icon } from '@iconify/react';
import { TitleH1Component } from '../../components/TitleH1Component';
import style from '../../scss/home.module.scss';
import VideoCover from 'react-video-cover';
import Stylebnt from "../../scss/btn.module.scss";
import { useCotizador } from '../../context/apiContext';
import StyleGrid from "../../scss/grid.module.scss";
import MenuComponent from '../../components/MenuComponent';
import { BrowserView, MobileView } from 'react-device-detect';
import { Navigate, useParams } from 'react-router-dom';
import { useAuth } from 'context/userContext';
import { useAgentNumberValidation } from 'hooks/AgentNumberValidation';
import Loader from 'components/LoaderComponent';

const Home = () => {
  const {userdata} = useParams();
  const { loginWithUserData, logout } = useAuth();
  const { texts, updateTexts, verifyCurrentLanguage } = useContext(TextsContext);
  const { resetData } = useCotizador();
  const [redir, setRedir] = useState(false);

  const {validate} = useAgentNumberValidation();


  useEffect(() => {
    verifyCurrentLanguage();
  }, []);
  const hHeader = useRef(null);
  const [hamburger, sethamburger] = useState(true)
  const paragraph = texts?.home_phrase_one + ' ' + texts?.home_phrase_two;
  const home_phrase_one_marca = paragraph?.replace?.("®", `<span>&reg;</span>`) ?? '';

  useEffect(() => {
    if (userdata) {
      validate(userdata).then((isValid) => {
        if (isValid) {
          if (!loginWithUserData(userdata)) {
            logout();
          };
        } else {
          logout();
        }
        setRedir(true);
      });
    }
  }, [userdata, loginWithUserData]);

  if (redir && window.location.pathname !== "/home") {
    return <Navigate to={"/home"} />
  }

  return (
    <Fragment>

      <div className={style.home}>

        <MobileView className={style.videoFondoContent}>
          <img src={'/img/fondo-video.jpg'} alt={'img fondo login'} className={style.videoFondo} />
        </MobileView>
        <BrowserView>
          <VideoCover
            videoOptions={{
              src: '/video/home.mp4',
              autoPlay: true,
              loop: true,
              muted: true,
            }}
            className={style.video}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              objectFit: 'cover',
            }}
          />
        </BrowserView>



        <div className={style.contenido}>
          <div className={` d-md-none ${StyleGrid.wpMenu} ${!hamburger ? StyleGrid.active : null}`} ref={hHeader}>
            <div className={StyleGrid.wpScroll}>
              <MenuComponent sethamburger={sethamburger} hamburger={hamburger} menuHome />
            </div>
          </div>
          <section >
            <div className="container d-none d-md-block">
              <header className='py-4'>
                <div className="container">
                  <div className={style.headerbtn}>
                    <img className={` ${Stylegeneral.logoh}`} src={'/img/VUMI_WhiteC.svg'} alt={'img logo vumi'} />
                    <div className='d-md-flex align-items-center d-none '>
                      <div className='d-flex align-items-center me-4'>
                        <div className={style.icon}>
                          <Icon icon={'twemoji:flag-spain'} />
                        </div>
                        <button type='button' className={Stylebnt.bntIdioma} onClick={() => { updateTexts('es') }}>Español</button>
                      </div>
                      <div className='d-flex align-items-center me-4'>
                        <div className={style.icon}>
                          <Icon icon={'twemoji:flag-england'} />
                        </div>
                        <button type='button' className={Stylebnt.bntIdioma} onClick={() => { updateTexts('en') }}>English</button>

                      </div>
                      <div className='d-flex align-items-center'>
                        <div className={style.icon}>
                          <Icon icon={'twemoji:flag-portugal'} />
                        </div>
                        <button type='button' className={Stylebnt.bntIdioma} onClick={() => { updateTexts('pt') }}>Português</button>

                      </div>

                    </div>
                    <div className={` ${Stylegeneral.logoDesktop} d-md-none`}>
                      <Picture src="/img/logomyplanb.png" alt="VUMI" />
                    </div>

                  </div>

                </div>
              </header>
            </div>
          </section>

          <section>
            <div className={`container ${style.body_home}`}>
              <div className='row d-flex align-items-center justify-content-center'>

                <div className='col-12 col-md-6'>
                  <div className={` ${Stylegeneral.logoDesktop} d-flex w-100  justify-content-center justify-content-md-start `}>
                    <Picture src="/img/logoMycareTm.png" alt="VUMI" styleGroup={`${style.logoPlanb} w-auto`} />
                  </div>
                  <div className="pt-3 pb-4 text-center text-md-start px-2 px-md-0">
                    <TitleH2Component styleGroup={`raleway fw_700 mb-0 color-white   ${style.tamamovil}`} >
                      <p dangerouslySetInnerHTML={{ __html: home_phrase_one_marca }} className={Stylegeneral.marca_registradaContainer}></p>

                    </TitleH2Component>

                  </div>
                  <div className=' d-flex justify-content-center justify-content-md-start py-4'>
                  {userdata ? <Loader /> :
                    <BtnComponent onClick={resetData} to={`/step1`} styleGroup={`bg_secundaryhome text-white text-center ${style.btnHomeStart}`} >
                      {texts?.home_button ?? 'Start Now!'}
                    </BtnComponent>
                  }
                  </div>
                  <div className={`${style.iconBajo}`}>
                    <Icon icon={'mdi:chevron-down'} />
                  </div>

                </div>

                <div className={`col-12 col-md-6 ${style.colitems} `}>
                  <TitleH1Component styleGroup={`raleway fw_700 mb-0 color-white mb-4 ${style.tamamovilsub} `}>
                    {texts?.home_phrase_three ?? 'With My VIP Care you can tailor a plan in less than 5 minutes:'}
                  </TitleH1Component>
                  <div className={`${Stylegeneral.lista}`}>
                    <div className={`${Stylegeneral.items} mb-3`}>
                      <div className={`color_secundary ${Stylegeneral.icon}`}>
                        <Icon icon='mdi:checkbox-marked-circle' />
                      </div>
                      <TitleH2Component styleGroup={`raleway fw_400 mb-0 text-white  ${style.tamamovilitem}`}>
                        {texts?.home_phrase_four ?? 'Choose the scope of your plan that fits with your needs.'}
                      </TitleH2Component>
                    </div>
                    <div className={`${Stylegeneral.items} mb-3`}>
                      <div className={`color_secundary ${Stylegeneral.icon}`}>
                        <Icon icon='mdi:check-circle' />
                      </div>
                      <TitleH2Component styleGroup={`raleway fw_400 mb-0 text-white  ${style.tamamovilitem}`}>
                        {texts?.home_phrase_five ?? 'See an automatic cost tabulation based on your choices.'}
                      </TitleH2Component>
                    </div>
                    <div className={`${Stylegeneral.items} mb-3`}>
                      <div className={`color_secundary ${Stylegeneral.icon}`}>
                        <Icon icon='mdi:check-circle' />
                      </div>
                      <TitleH2Component styleGroup={`raleway fw_400 mb-0 text-white  ${style.tamamovilitem}`}>
                        {texts?.home_phrase_six ?? 'Manage options according to your budget; and much more!'}
                      </TitleH2Component>
                    </div>
                    <div className=' d-flex justify-content-center justify-content-md-start py-4 d-md-none'>
                      {userdata ? <Loader /> :
                        <BtnComponent onClick={resetData} to={`/step1`} styleGroup={`bg_secundaryhome text-white text-center ${style.btnHomeStart}`} >
                          {texts?.home_button ?? 'Start Now!'}
                        </BtnComponent>
                      }
                    </div>
                    {/*<TitleH2Component styleGroup={`raleway fw_700 mb-0 color_primary_2  `}>
                      {texts?.home_phrase_one ?? 'A health insurance plan designed by you, powered by VUMI'}
                      <span className={Stylegeneral.marca_registrada}>&reg;</span>
        </TitleH2Component>*/}

                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* <SwiperSlide>
          <section className={` ${Stylegeneral.secction_video} `}>
            <div className="container py-5 ">
              <div className="row gy-4">
                <div className="col-12 col-lg-6 ">
                
                  <Picture src="/img/imghome.jpg" alt="VUMI" styleGroup='me-0' />
                </div>
                <div className="col-12 col-lg-6">
                  <Fragment>
                    <div className={` ${Stylegeneral.logoDesktop}`}>
                      <Picture src="/img/logoplanwhite.png" alt="VUMI" />
                    </div>
                    <TitleH1Component styleGroup={`raleway fw_700 mb-0 color-white mb-4 `}>
                      {texts?.home_phrase_three  ?? 'With My VIP care you  can tailor a plan in less than 5 minutes:'}
                    </TitleH1Component>
                    <div className={`${Stylegeneral.lista}`}>
                      <div className={`${Stylegeneral.items} mb-3`}>
                        <div className={`color-verde ${Stylegeneral.icon}`}>
                          <Icon icon='mdi:checkbox-marked-circle' />
                        </div>
                        <TitleH2Component styleGroup={`raleway fw_400 mb-0 text-white `}>
                          {texts?.home_phrase_four ?? 'Choose the scope of your plan that fits with your needs.'}
                        </TitleH2Component>
                      </div>
                      <div className={`${Stylegeneral.items} mb-3`}>
                        <div className={`color-verde ${Stylegeneral.icon}`}>
                          <Icon icon='mdi:check-circle' />
                        </div>
                        <TitleH2Component styleGroup={`raleway fw_400 mb-0 text-white `}>
                          {texts?.home_phrase_five ?? 'See an automatic cost tabulation based on your choices.'}
                        </TitleH2Component>
                      </div>
                      <div className={`${Stylegeneral.items} mb-3`}>
                        <div className={`color-verde ${Stylegeneral.icon}`}>
                          <Icon icon='mdi:check-circle' />
                        </div>
                        <TitleH2Component styleGroup={`raleway fw_400 mb-0 text-white `}>
                          {texts?.home_phrase_six ?? 'Manage options according to your budget; and much more!'}
                        </TitleH2Component>
                      </div>
                    </div>
                    <BtnComponent to={`/step1`} styleGroup={'bg_secundaryhome text-white'} >
                      {texts?.home_button ?? 'Start Now!'}
                    </BtnComponent>
                  </Fragment>
                </div>
              </div>
            </div>
          </section>
        </SwiperSlide>*/}

      {/* <section>
        <div className='d-flex justify-content-center align-items-center mt-4'>
          <ParagraphComponent styleGroup='text-center'>
            VUMI® 2022 - All rights reserved  Careers | Terms of Use | Privacy Policy
          </ParagraphComponent>
7
        </div>
      </div>

      {/*<div className={style.home}>
        <div className={style.video_container}>
          <React.Suspense >
            <LazyPlayer
              url="/video/home.mp4"
              playing={true}
              loop={true}
              muted={true}
              width="100%"
              height="100%"

            />
          </React.Suspense>
        </div>
</div>*/}

    </Fragment>
  )
}

export default Home;
