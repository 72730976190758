import { Fragment } from "react";
import Style from "../scss/btn.module.scss";
import { NavLink } from "react-router-dom";

export function BtnComponent(props){
    return (
        <Fragment>
            <NavLink onClick={props.onClick} to={props?.disabled ? null : props.to} className={`${Style.btn} ${props.styleGroup}`}>
                <span className={Style.wpChild}>
                    {props.children}
                </span>
                <span className={Style.loadingContainer}>
                    <span className={Style.dot}></span>
                    <span className={Style.dot}></span>
                    <span className={Style.dot}></span>
                </span>
            </NavLink>
        </Fragment>  
    );
}
