import React, {useContext} from "react";
import styleForm from "../scss/form.module.scss";
import {useDateSelects} from "hooks/DateSelectsHook";
import {TextsContext} from "../context/textsContext";

export const DateSelectComponent = (props) => {
    const {disabled, maxYear, minYear} = props;
    const {getCurrentLanguage} = useContext(TextsContext);
    const {getMonthNames, year, month, day, setDay, setMonth, setYear, daysInMonth, getArray} = useDateSelects(props);

    const daySelect = () => {
        return (
            <select
                disabled={disabled}
                value={day}
                onChange={(e) => setDay(e.target.value)}
                className={`${styleForm.selectSinfondo} raleway me-3 me-md-1`}
            >
                <option value={0} disabled>
                    DD
                </option>
                {React.Children.toArray(
                    getArray(daysInMonth(month ?? null, year ?? null))
                        .sort((a, b) => b - a)
                        .map((day) => (
                            <option value={day}>{day}</option>
                        ))
                )}
            </select>
        );
    };

    const monthSelect = () => {
        return (
            <select
                disabled={disabled}
                value={month}
                onChange={(e) => setMonth(e.target.value)}
                className={`${styleForm.selectSinfondo} raleway me-3 me-md-1`}
            >

                <option value={0} disabled>
                    MM
                </option>
                {React.Children.toArray(
                    getMonthNames("short")
                        .map((month) => <option
                            value={month.number}>{month.name.charAt(0).toUpperCase() + month.name.slice(1)}</option>)
                )}
            </select>
        )
    };
    const yearSelect = () => {
        return (
            <select
                value={year}
                disabled={disabled}
                onChange={(e) => setYear(e.target.value)}
                className={`${styleForm.selectSinfondo} raleway me-3 me-md-1`}
            >
                <option value={-1} disabled>
                    YYYY
                </option>
                {React.Children.toArray(
                    getArray(maxYear, minYear)
                        .sort((a, b) => b - a)
                        .map((year) => <option value={year}>{year}</option>)
                )}
            </select>
        );
    };


    return (
        <div className="d-flex align-items-center" style={{gap: "4pt"}}>
            {
                getCurrentLanguage() === "pt" ?
                    <>
                        {daySelect()}
                        {monthSelect()}
                        {yearSelect()}
                    </> :
                    <>
                        {monthSelect()}
                        {daySelect()}
                        {yearSelect()}
                    </>
            }
        </div>
    );
};
