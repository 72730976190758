import { CotizadorGuard } from "../guards/CotizadorGuard";
import Step1Pages from "../pages/Step1/Step1Pages";
import Step11Page from "../pages/Step11/Step11Page";
import Step12Page from "../pages/Step12/Step12Page";
import Step2Pages from "../pages/Step2/Step2Pages";
import Step3Pages from "../pages/Step3/Step3Pages";
import Step4Pages from "../pages/Step4/Step4Pages";
import Step5Pages from "../pages/Step5/Step5Pages";
import Step6Pages from "../pages/Step6/Step6Pages";
import Step7Pages from "../pages/Step7/Step7Pages";
import Step8Pages from "../pages/Step8/Step8Pages";
import Step9Pages from "../pages/Step9/Step9Pages";

export const CotizadorRoutes = [
    {
        path: "/step1",
        element: <Step1Pages />
    },
    {
        path: "/step2",
        element: <Step2Pages />
    },
    {
        path: "/step3",
        element: <Step3Pages />
    },
    {
        path: "/step4",
        element: <Step4Pages />
    },
    {
        path: "/step5",
        element: <Step5Pages />
    },
    {
        path: "/step6",
        element: <Step6Pages />
    },
    {
        path: "/step7",
        element: <Step7Pages />
    },
    {
        path: "/step8",
        element: <Step8Pages />
    },
    {
        path: "/step9",
        element: <Step9Pages />
    },
    {
        path: "/step11",
        element: <Step11Page />
    },
    {
        path: "/step12",
        element: <Step12Page />
    }
].map((step) => {
    step.element = <CotizadorGuard {...step} />
    return step;
});