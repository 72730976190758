import {useContext, useEffect, useState} from "react";
import {TextsContext} from "../../context/textsContext";

const initialDates = {
    birthday: "",
    dependantB: "",
    coverage: new Date()
};

export const useDate = (changeDependantValue, changeDataValue) => {
    const [dates, setDates] = useState(initialDates);
    const {getCurrentLanguage} = useContext(TextsContext);

    const zeroPad = (num, places) => String(num).padStart(places, '0');
    const yearPad = (value) => zeroPad(value?.getFullYear?.(), 4);
    const monthPad = (value) => zeroPad(value?.getMonth?.() + 1, 2);
    const dayPad = (value) => zeroPad(value?.getDate?.(), 2);
    const getLangFormat = (lang, value) => {
        const list = {
            pt: `${dayPad(value)}-${monthPad(value)}-${yearPad(value)}`,
            default: `${monthPad(value)}-${dayPad(value)}-${yearPad(value)}`
        }
        return list[lang] ?? list['default'];
    }


    useEffect(() => {
        Object.entries(dates).forEach(([key, value]) => {
            if (key === "dependantB") {
                handleDependantBirthday(value)
            } else {
                changeDataValue(key, value ? `${yearPad(value)}-${monthPad(value)}-${dayPad(value)}` : "")
                changeDataValue(`${key}Show`, value ? getLangFormat(getCurrentLanguage(), value) : "")
                changeDataValue(`${key}ShowDT`, value ? getLangFormat('default', value) : "")
            }
        })
    }, [dates]);

    const handleDependantBirthday = (value) => {
        changeDependantValue("birthday", value ? `${yearPad(value)}-${monthPad(value)}-${dayPad(value)}` : "")
        changeDependantValue("birthdayShow", value ? getLangFormat(getCurrentLanguage(), value) : "")
        changeDependantValue("birthdayShowDT", value ? getLangFormat('default', value) : "")

    }

    const changeDate = (name, value) => {
        setDates(dates => ({...dates, [name]: value}));
    }

    const resetDates = () => {
        setDates(initialDates);
    }

    return {dates, changeDate, setDates, resetDates};
}