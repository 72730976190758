export function scrollToTopNew() {
  /*const duration = 100; // duración de la animación en milisegundos
  const distance = window.scrollY; // distancia a recorrer, en este caso hasta el inicio de la página
  const increment = distance / (duration / 10); // cantidad de pixeles que se desplaza en cada iteración
  let currentPosition = window.scrollY;
  setTimeout(() => {
    const scrollInterval = setInterval(() => {
      currentPosition -= increment;
      if (currentPosition <= 0) {
        clearInterval(scrollInterval);
        window.scrollTo(0, 0);
        console.log("Scroll to top");
      } else {
        window.scrollTo(0, currentPosition);
        console.log("Scrolling...");
      }
    }, 5);  

  }, 500);*/
  const timer = setTimeout(() => {

    const targetSection = document.getElementById('sectionscroll');
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: 'smooth' });
    }
  }, 200);
  return () => clearTimeout(timer);
};