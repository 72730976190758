/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, {useContext, createContext, useState} from "react";
import { useApplicationErrors } from "../hooks/ApplicationErrorsHook";

const ApplicationContext = createContext();

const CHANGE_DATA = "application-change_data";
const CHANGE_DATA_KEY = "application-change_data_key";
const RESET_DATA = "application-reset_data";
const LOAD_DATA = "application-load_data";


const initialState = {
};

const application = (state, action) => {
    switch (action.type) {
        case LOAD_DATA:
            return action.payload;
        case CHANGE_DATA:
            return {...state, [action.payload.name]: action.payload.value};
        case CHANGE_DATA_KEY:
            return {...state, [action.payload.key]: { ...state[action.payload.key] ?? [], [action.payload.name]: action.payload.value}};
        case RESET_DATA:
            return initialState;
        default:
            return state;
    }
};

/**
 * @typedef {Object} ApplicationContext
 * @property {object} applicationState
 * @property {(name, value) => void} changeDataValue
 * @property {(key, name, value) => void} changeDataKeyValue
 * @property {(e, key = "", meta = null) => void} handleOnChange
 * @property {(key) => (e) => void} handleOnChangeFn
 * @property {object} errors
 * @property {(section, key, code, text) => void} addError
 * @property {(section, key, code) => void} removeError
 * @property {(section) => void} sectionHasNoErrors
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setFailedNextIntent
 * @property {boolean} failedNextIntent
 * @property {string[]} finishedSteps
 * @property {(path) => void} addFinishedStep
 * @property {(path) => void} removeFinishedStep
 */

/** 
 * @function
 * @returns {ApplicationContext} */
export function useApplication() {
    return useContext(ApplicationContext);
}

/** 
 * @function
 * @returns {ApplicationContext} */
const useApplicationValues = () => {
    const [applicationState, dispatch] = React.useReducer(application, initialState);
    const [failedNextIntent, setFailedNextIntent] = useState(false);
    const [finishedSteps, setFinishedSteps] = useState([]);
    const errors = useApplicationErrors();

    const changeDataValue = (name, value) => {
        dispatch({type: CHANGE_DATA, payload: {name, value}});
    }

    const changeDataKeyValue = (key, name, value) => {
        dispatch({type: CHANGE_DATA_KEY, payload: {key, name, value}});
    }

    const handleOnChange = async (e, key = "", meta = null) => {
        const name = e?.target?.name ?? meta?.name;
        const value = e?.target?.value ?? e?.value;
        key = key ? key : e?.currentTarget?.dataset?.numeric ?? "";
        await changeDataKeyValue(key, name, value);
    }

    const handleOnChangeFn = async (key) => {
        return (e) => handleOnChange(e, key)
    }

    const addFinishedStep = (path) => {
        if (finishedSteps.includes(path)) return;
        setFinishedSteps([...finishedSteps, path])
    }

    const removeFinishedStep = (path) => {
        if (!finishedSteps.includes(path)) return;
        setFinishedSteps([...finishedSteps.filter(i => i !== path)])
    }

    return {
        ...applicationState,
        applicationState,
        changeDataValue,
        changeDataKeyValue,
        handleOnChange,
        handleOnChangeFn,
        setFailedNextIntent,
        failedNextIntent,
        addFinishedStep,
        removeFinishedStep,
        finishedSteps,
        ...errors
    };
}

export function ApplicationProvider({ children }) {
    const value = useApplicationValues()
    return <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>;
}
