import React, { useState, useEffect, useContext } from "react";
import { TextsContext } from '../../context/textsContext';
import { Fragment } from "react";
import Stylegeneral from '../../scss/general.module.scss';
import styleForm from '../../scss/form.module.scss';
import { TitleH2Component } from "../../components/TitleH2Component";
import { TitleH1Component } from "../../components/TitleH1Component";
import { TitleH3Component } from "../../components/TitleH3Component";
import SelectBenefitsComponent from "../../components/SelectBenefitsComponent";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { useCotizador } from "../../context/apiContext";
import { useSteps } from "../../hooks/useSteps";
import { arrayIncludesNumber } from "../../helpers/toolsHelper";
import styleTexto from '../../scss/text.module.scss';
import ValidaComponent from "../../components/ValidaComponent";
import { ImportantMessage } from "./component/ImportantMessage";
const Step11Page = () => {
    const { texts, verifyCurrentLanguage } = useContext(TextsContext);
    const { benefits, selectedBenefitsIds, limitedCover, handleChecks } = useCotizador();
    const [isMobileDevice, setIsMobileDevice] = useState(false);
    const { getBenefitsPerStep } = useSteps();
    const [mostarPais, setMostrarPais] = useState(false);
    const [Providers, setProviders] = useState(false);

    useEffect(() => {
        verifyCurrentLanguage();
      
    }, []);

    function handleResize() {
        setIsMobileDevice(window.innerWidth <= 768);
    }
    const paragraphgeneral_header_title = texts?.general_header_title;
    const general_header_title_marca = paragraphgeneral_header_title?.replace?.("®", `<span>&reg;</span>`) ?? '';
    return (
        <Fragment>
            <section id="sectionscroll">
                <div className="container ">
                    <div className="d-block d-lg-none ">
                        <TitleH2Component styleGroup={`raleway fw_400 lh_40 color_secundary mb-0 py-2 ${Stylegeneral.titulo_principal}`}>
                            <p dangerouslySetInnerHTML={{ __html: general_header_title_marca }} className={Stylegeneral.marca_registradaContainer}></p>

                        </TitleH2Component>
                    </div>
                    <TitleH1Component styleGroup={`raleway_semibold fw_700  lh_30 color_primary pb-2 ${Stylegeneral.titulo}`}>
                        {texts?.step11_title ?? 'Choose coverage that works best for you and your family'}
                    </TitleH1Component>

                    <TitleH3Component styleGroup={`Poppins fw_400 color_primary pb-3 ${styleTexto.tamasubparrafo} ${Stylegeneral.titulo}`}>
                        {texts?.step11_phrase ?? "Select  the options for the plan you'd like to build"}
                    </TitleH3Component>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className={`${Stylegeneral.rowForm} row gy-5 pb-5`}>

                        <div className={`col-12 col-md-4 ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                    <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1 `}>
                                        {texts?.step11_form_field_maximum ?? 'Maximum Coverage Amount'}
                                    </label>

                                    <OverlayTrigger
                                        key={isMobileDevice ? 'left' : 'top'}
                                        placement={isMobileDevice ? 'left' : 'top'}
                                        overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{texts?.step11_tooltip_maximum ?? 'Maximum coverage amount that the company covers during a contingency'}</Tooltip>}
                                    >
                                        <Icon icon={'mdi:information-outline'} className={` color_gray_5 ${styleForm.iconselect}`} />
                                    </OverlayTrigger>
                                </div>

                                <SelectBenefitsComponent benefits={benefits.structuralData} benefit={"Maximum coverage per insured, per policy year"} className={`${styleForm.customselectLine}`} />

                            </div>
                        </div>

                        <div className={`col-12 col-md-4 ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                    <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1 `}>
                                        {texts?.step11_form_field_geo ?? 'Geographical coverage'}
                                    </label>

                                    <OverlayTrigger
                                        key={isMobileDevice ? 'left' : 'top'}
                                        placement={isMobileDevice ? 'left' : 'top'}
                                        overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{texts?.step11_tooltip_geo ?? "Geographic area where the policy´s benefits apply"}</Tooltip>}
                                    >
                                        <Icon icon={'mdi:information-outline'} className={`color_gray_5 ${styleForm.iconselect}`} />
                                    </OverlayTrigger>
                                </div>

                                <SelectBenefitsComponent setMostrarPais={setMostrarPais} benefits={benefits.structuralData} benefit={"Geographical coverage"} className={`${styleForm.customselectLine}`} />
                            </div>
                        </div>
                        {
                            getBenefitsPerStep().includes('Providers Network') &&
                            <div className={`col-12 col-md-4 ${Stylegeneral.colform}`}>
                                <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                    <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                        <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1 `}>
                                            {texts?.step11_form_field_providers_network ?? 'Providers Network'}
                                        </label>

                                        <OverlayTrigger
                                            key={isMobileDevice ? 'left' : 'top'}
                                            placement={isMobileDevice ? 'left' : 'top'}
                                            overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{texts?.step11_tooltip_us_coverage ?? 'Providers and hospital networks in the United States where the policy can be used'}</Tooltip>}
                                        >
                                            <Icon icon={'mdi:information-outline'} className={` color_gray_5 ${styleForm.iconselect}`} />
                                        </OverlayTrigger>

                                    </div>
                                    <SelectBenefitsComponent setProviders={setProviders} benefits={benefits.structuralData} benefit={"Providers Network"} className={`${styleForm.customselectLine}`} />

                                </div>

                            </div>

                        }
                       <ImportantMessage geoId={selectedBenefitsIds['Geographical coverage']} providerId={selectedBenefitsIds['Providers Network']} mostarPais={mostarPais} Providers={Providers} limitedCover={limitedCover} handleChecks={handleChecks} antiResponsive={true} />

                        <div className={`col-12 col-md-4 ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                    <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1 `}>
                                        {texts?.step11_form_field_coinsurance ?? 'Coinsurance'}
                                    </label>

                                    <OverlayTrigger
                                        key={isMobileDevice ? 'left' : 'top'}
                                        placement={isMobileDevice ? 'left' : 'top'}
                                        overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{texts?.step11_tooltip_coinsurance ?? 'Percentage that determines the amount and insured must contribute towards the total of covered expenses, once the deductible has been met'}</Tooltip>}
                                    >
                                        <Icon icon={'mdi:information-outline'} className={` color_gray_5 ${styleForm.iconselect}`} />
                                    </OverlayTrigger>
                                </div>

                                <SelectBenefitsComponent benefits={benefits.structuralData} benefit={"Coinsurance"} className={`${styleForm.customselectLine}`} />

                            </div>
                        </div>
                        {getBenefitsPerStep().includes('Maximum out of pocket') && <div className={`col-12 col-md-4 ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                    <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1 `}>
                                        {texts?.step11_form_field_pocket ?? 'Maximum Out of Pocket'}
                                    </label>

                                    <OverlayTrigger
                                        key={isMobileDevice ? 'left' : 'top'}
                                        placement={isMobileDevice ? 'left' : 'top'}
                                        overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{texts?.step11_tooltip_pocket ?? 'Maximum amount an insured could pay for covered medical services and/or prescriptions each year. This does not include premiums, but does typically include deductible, coinsurance and copays'}</Tooltip>}
                                    >
                                        <Icon icon={'mdi:information-outline'} className={` color_gray_5 ${styleForm.iconselect}`} />
                                    </OverlayTrigger>
                                </div>

                                <SelectBenefitsComponent benefits={benefits.structuralData} benefit={"Maximum out of pocket"} className={`${styleForm.customselectLine}`} />

                            </div>
                        </div>}
                        {getBenefitsPerStep().includes('Deductible Inside U.S.') &&
                            <div className={`col-12 col-md-4 ${Stylegeneral.colform}`}>
                                <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                    <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                        <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1 `}>
                                            {texts?.step11_form_field_inside ?? 'Deductible inside U.S.'}
                                        </label>

                                        <OverlayTrigger
                                            key={isMobileDevice ? 'left' : 'top'}
                                            placement={isMobileDevice ? 'left' : 'top'}
                                            overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{texts?.step11_tooltip_inside ?? 'The portion of covered expenses that must be paid by the insured before the benefits of the policy become payable by the company'}</Tooltip>}
                                        >
                                            <Icon icon={'mdi:information-outline'} className={` color_gray_5 ${styleForm.iconselect}`} />
                                        </OverlayTrigger>
                                    </div>

                                    <SelectBenefitsComponent benefits={benefits.structuralData} benefit={"Deductible Inside U.S."} className={`${styleForm.customselectLine}`} />

                                </div>
                            </div>
                        }
                        {getBenefitsPerStep().includes('Deductible Outside U.S.') &&
                            <div className={`col-12 col-md-4 ${Stylegeneral.colform}`}>
                                <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                    <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                        <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1 `}>
                                            {arrayIncludesNumber(Object.values(selectedBenefitsIds), 44) || arrayIncludesNumber(Object.values(selectedBenefitsIds), 46) ? (texts?.step11_form_field_deductible ?? 'Deductible') : (texts?.step11_form_field_outside ?? 'Deductible outside U.S.')}
                                        </label>

                                        <OverlayTrigger
                                            key={isMobileDevice ? 'left' : 'top'}
                                            placement={isMobileDevice ? 'left' : 'top'}
                                            overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{texts?.step11_tooltip_outside ?? 'The portion of covered expenses that must be paid by the insured before the benefits of the policy become payable by the company'}</Tooltip>}
                                        >
                                            <Icon icon={'mdi:information-outline'} className={` color_gray_5 ${styleForm.iconselect}`} />
                                        </OverlayTrigger>
                                    </div>

                                    <SelectBenefitsComponent benefits={benefits.structuralData} benefit={"Deductible Outside U.S."} className={`${styleForm.customselectLine}`} />

                                </div>
                            </div>
                        }

                    </div>
                    <TitleH3Component styleGroup={`Poppins fw_400 color_primary pb-3 ${styleTexto.tamasubparrafo} ${Stylegeneral.titulo}`}>
                        {texts?.step11_deducible ?? "*Only one (1) Deductible per person, per Policy Year applies. For family Policies, a maximum of two (2) Deductibles accumulated per Policy, per Policy Year will be applied."}
                    </TitleH3Component>

                    <ImportantMessage geoId={selectedBenefitsIds['Geographical coverage']} providerId={selectedBenefitsIds['Providers Network']} mostarPais={mostarPais} Providers={Providers} limitedCover={limitedCover} handleChecks={handleChecks} />
                </div>
            </section>
        </Fragment>
    )
}

export default Step11Page;
