import React, { Fragment, useEffect, useState, useContext } from "react";
import { TextsContext } from '../../context/textsContext';
import styleForm from '../../scss/form.module.scss';
import { TitleH1Component } from "../../components/TitleH1Component";
import { TitleH3Component } from "../../components/TitleH3Component";
import { TitleH2Component } from "../../components/TitleH2Component";
import Stylegeneral from '../../scss/general.module.scss';
import { useCountries } from "../../api/quoterApi";
import { useCotizador } from "../../context/apiContext";
import ValidaComponent from "../../components/ValidaComponent";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Icon } from "@iconify/react";
import styleTexto from '../../scss/text.module.scss';
import { DateSelectComponent } from "../../components/DateSelectsComponent";
import {translateANT} from "../../helpers/toolsHelper";
const Step1Pages = () => {
    const { texts, verifyCurrentLanguage, getCurrentLanguage } = useContext(TextsContext);
    const { data: countries, getCountryName, countrySort } = useCountries();
    const {
        contextState,
        handleOnChange,
        changeDate,
        dates
    } = useCotizador();

    const handleTextInput = (e) => {

    }

    const handleChecks = (e) => {
        handleOnChange({ target: { value: e.target.checked, name: e.target.name } })
    }
    const [isMobileDevice, setIsMobileDevice] = useState(false);

    useEffect(() => {
        verifyCurrentLanguage();
        function handleResize() {
            setIsMobileDevice(window.innerWidth <= 768);
        }

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getCountries = () => {
        if (!countries) return [];
        if (!(typeof countries.sort === 'function')) return [];
        return countries
            ?.sort(countrySort(getCurrentLanguage()));
    }


    return (
        <Fragment>
            <section id="sectionscroll">
                <div className="container">
                    <div className="d-block d-lg-none">
                        <TitleH2Component styleGroup={`raleway fw_400 lh_40 color_secundary mb-0 py-2 ${Stylegeneral.titulo_principal}`}>
                            {texts?.header_title  ?? 'My VUMI VIP Care'}
                        </TitleH2Component>
                    </div>
                    <div className={`${styleTexto.cuadro_texto} p-2 mb-4 mb-md-3`}>
                        <TitleH3Component styleGroup={`Poppins fw_400 color_primary mb-0 ${styleTexto.tamaSpecial}`}>
                            {texts?.step1_paragraph ?? 'MY VIP Care plan comes with a comprehensive base plan which includes extensive benefits, and lets you customize others based on your specific needs. Having selected your base plan options, you can choose additional benefits as needed, including outpatient services, maternity, and organ transplant.'}
                        </TitleH3Component>
                    </div>

                    <TitleH1Component styleGroup={`raleway_semibold fw_700  color_primary py-md-3 py-lg-2  mb-5 ${Stylegeneral.titulo} `}>
                        {texts?.step1_title ?? 'Tell us about you'}
                    </TitleH1Component>

                    <TitleH3Component styleGroup={`Poppins fw_400 color_primary pb-3 mb-0 ${styleTexto.tamasubparrafo} ${Stylegeneral.titulo}`}>
                        {texts?.step1_phrase ?? 'Fill out the information below to start building a personalized plan'}
                    </TitleH3Component>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className={`${Stylegeneral.rowForm} row gy-5`}>

                        <div className={`col-12 col-md-4 ${Stylegeneral.colform} `}>

                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                <label htmlFor="name" className={`${styleForm.labels} ${Stylegeneral.contentLabel} mb-2 raleway color_primary_2`}><span className={styleForm.punto}>*</span>
                                    {texts?.step1_form_field_coverage_date ?? 'Coverage start date'}
                                </label>

                                {/* <DatePicker 
                                    views={['month', 'day', 'year']} 
                                    value={dates.coverage} 
                                    onChange={(newValue) => changeDate("coverage", newValue)}
                                    slotProps={{
                                        textField: {
                                            variant: 'standard',
                                            focused: true,
                                            color: 'primary',
                                          }
                                    }}
                                    /> */}

                                <DateSelectComponent
                                    minYear={(new Date().getFullYear())}
                                    maxYear={(new Date().getFullYear() + 1)}
                                    minDays={15}
                                    maxDays={45}
                                    value={dates.coverage}
                                    onChange={(newValue) => changeDate("coverage", newValue)}
                                />



                                {/* <DatePickerComponent style={`${styleForm.customselectLine}`} onChange={handleOnChange} name={"coverage"} value={contextState.coverage} max={new Date(9999, 11, 30)}/> */}
                                <ValidaComponent keyName={"coverage"} />
                            </div>
                        </div>

                        <div className={`col-12 col-md-4 ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                <label htmlFor="name" className={`${styleForm.labels} ${Stylegeneral.contentLabel} raleway color_primary_2`}><span className={styleForm.punto}>*</span>
                                    {texts?.general_form_field_name ?? 'Name'}
                                </label>
                                <input type="text" className={`${styleForm.input_with_line}`} onKeyDown={handleTextInput} placeholder={texts?.general_form_field_name ?? 'Name'} name={"name"} value={contextState.name} onChange={handleOnChange} required />

                            </div>
                            <ValidaComponent keyName={"name"} />
                        </div>
                        <div className={`col-12 col-md-4 ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                <label htmlFor="name" className={`${styleForm.labels} ${Stylegeneral.contentLabel} raleway color_primary_2`}><span className={styleForm.punto}>*</span>
                                    {texts?.general_form_field_last_name ?? 'Last name'}
                                </label>
                                <input type="text" className={`${styleForm.input_with_line}`} onKeyDown={handleTextInput} placeholder={texts?.general_form_field_last_name ?? 'Last name'} name={"surname"} value={contextState.surname} onChange={handleOnChange} />
                            </div>
                            <ValidaComponent keyName={"surname"} />
                        </div>
                        <div className={`col-12 col-md-4 ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                <label htmlFor="email" className={`${styleForm.labels} ${Stylegeneral.contentLabel} raleway color_primary_2`}><span className={styleForm.punto}>*</span>
                                    {texts?.step1_form_field_email ?? 'Email address'}
                                </label>
                                <input type="email" className={`${styleForm.input_with_line}`} placeholder={texts?.step1_form_placeholder_email ?? 'Email'} name={"email"} value={contextState.email} onChange={handleOnChange} />
                            </div>
                            <ValidaComponent keyName={"email"} />
                        </div>
                        <div className={`col-12 col-md-4 ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                <label htmlFor="name" className={`${styleForm.labels} ${Stylegeneral.contentLabel} mb-2 raleway color_primary_2`}><span className={styleForm.punto}>*</span>
                                    {texts?.general_form_field_birth_date ?? 'Date of birth'}
                                </label>
                                {/* <DatePicker 
                                    views={['month', 'day', 'year']}
                                    value={dayjs(dates.birthday, "YYYY-M-D")}
                                    onChange={(newValue) => changeDate("birthday", newValue)}
                                    slotProps={{
                                        textField: {
                                            variant: 'standard',
                                            focused: true,
                                            color: 'primary',
                                          }
                                    }}
                                    /> */}
                                    <DateSelectComponent
                                        minYear={(new Date().getFullYear() - 75)}
                                        maxYear={(new Date().getFullYear() - 18)}
                                        value={dates.birthday}
                                        onChange={(newValue) => changeDate("birthday", newValue)} 
                                    />
                                {/* <DatePickerComponent style={`${styleForm.customselectLine}`} onChange={handleOnChange} name={"birthday"} value={contextState.birthday} max={new Date()}/> */}
                            </div>
                            <ValidaComponent keyName={"birthday"} />
                        </div>

                        <div className="col-12 col-md-4">
                            <div className={`${styleForm.form_group}`}>
                                <label htmlFor="gender" className={`${styleForm.labels} raleway mb-3 color_primary_2`}><span className={styleForm.punto}>*</span>{texts?.general_form_field_gender ?? 'Gender'}</label>
                                <div className={`${styleForm.form_radio}`}>
                                    <div className={`${styleForm.custom_radio} pe-4`}>
                                        <label className={`me-2  ${styleForm.Control} ${contextState.gender == 1 ? styleForm.active : ""}`}
                                        >
                                            <span className={`${styleForm.title} } `}></span>
                                            <input type="radio" name={"gender"} value={1} className={styleForm.input} checked={contextState.gender == 1} onChange={handleOnChange} />
                                            <span className={styleForm.checkmark}></span>
                                        </label>
                                        <label htmlFor="femenina" className={`${styleForm.labels} raleway color_secundary raleway`}>{texts?.general_form_option_male ?? 'Male'}</label>
                                    </div>
                                    <div className={`${styleForm.custom_radio} `}>
                                        <label className={`me-2  ${styleForm.Control} ${contextState.gender == 2 ? styleForm.active : ""}`}
                                        >
                                            <span className={`${styleForm.title} } `}></span>
                                            <input type="radio" name={"gender"} className={styleForm.input} value={2} checked={contextState.gender == 2} onChange={handleOnChange} />
                                            <span className={styleForm.checkmark}></span>
                                        </label>
                                        <label htmlFor="masculino" className={`${styleForm.labels} raleway color_secundary raleway`}>{texts?.general_form_option_female ?? 'Female'}</label>
                                    </div>
                                </div>
                                <ValidaComponent keyName={"gender"} />

                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className={`${styleForm.form_group}`}>
                                <label htmlFor="country" className={`${styleForm.labels} raleway color_primary_2`}>
                                    <span className={styleForm.punto}>*</span>
                                    {texts?.general_form_field_country ?? 'Country of residence'}
                                </label>
                                <select className={`${styleForm.customselectLine}`} name={"country"} value={contextState.country} onChange={handleOnChange}>
                                    <option value={0} disabled={true}>{texts?.general_form_field_select ?? 'Select'}</option>
                                    {
                                        getCountries() // < (getCountryName(b.isoNum, getCurrentLanguage()) ?? b.name)) {
                                            ?.map(country => (
                                                <option key={country.id} value={country.isoNum}>{
                                                    country.isoNum === "530" ? translateANT(getCurrentLanguage()) : (getCountryName(country.isoNum, getCurrentLanguage()) ?? country.description)}</option>
                                            ))
                                    }
                                </select>
                                <ValidaComponent keyName={"country"} />

                                {/*<p className={`text-danger ${errors?.country?.noCover ? '' : 'd-none'}`}>This country has no cover</p>*/}
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className={`${styleForm.btn_activar}`}>
                                <div className={`d-flex align-items-center mb-2 ${styleForm.con_tootip}`}>
                                    <label htmlFor="travel" className={`${styleForm.labels} raleway mb-2 color_primary_2 pe-1`}>
                                        {texts?.general_form_field_light_rider ?? 'Travel VIP Light Rider '}
                                    </label>
                                    <OverlayTrigger
                                        key={isMobileDevice ? 'right' : 'top'}
                                        placement={isMobileDevice ? 'left' : 'top'}
                                        overlay={<Tooltip id="tooltip-top" className="custom-tooltip">
                                            {texts?.general_form_coverage_paragraph ?? 'Coverage of up to US $5,000 without deductible for emergency treatmen in an emergency room, hospital, convenience or outpatient clinic, or emergency center, when the insured is traveling outside his/her country of residence. This coverage also includes immediate follow-up treatments related to this emergency. For any expenses that exceeds the US $5,000 maximun Benefit, the remaining deductible of the policy Will apply. This Benefit will provide coverage for 30 consecutive days, or twice during a policy year.'}
                                        </Tooltip>}>
                                        <div className={` ${styleForm.iconselect} mb-2`}>
                                            <Icon icon={'mdi:information-outline'} className={` color_gray_5`} />

                                        </div>
                                    </OverlayTrigger>
                                </div>
                                <div className="form-check form-switch input-personalizado">
                                    <input className="form-check-input" type="checkbox" id="travel" onChange={handleChecks} checked={contextState.travel} name={"travel"} />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className={`${styleForm.btn_activar}`}>
                                <label htmlFor="need" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                    {texts?.step1_form_field_dependent ?? 'Do you need to add a dependent?'}
                                </label>
                                <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                    <input className="form-check-input" type="checkbox" id="need" name={"addDependant"} onChange={handleChecks} checked={contextState.addDependant} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment >
    )
}
export default Step1Pages;
