/* eslint-disable eqeqeq */
export const getAge = (dateString) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}
export const isInFrame = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

export const getAgeInDays = (dateString) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    const diffInTime = today.getTime() - birthDate.getTime();
    const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));
    return diffInDays;
}

export const iOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  }


export const getCountry = (value, list) => {
    if (!list) return -1;
    return list?.filter(i => i?.data?.length).find(i => i.data[0]["stateNumberId.countriesListId.isoNum"] == value)?.data[0].id
}

export const translateANT = (currentLang) => {
    if (currentLang.toLowerCase() === "es") {
        return "Antillas Holandesas";
    }
    if (currentLang.toLowerCase() === "en") {
        return "Netherlands Antilles";
    }
    if (currentLang.toLowerCase() === "pt") {
        return "Antilhas Holandesas";
    }
    return "Netherlands Antilles";

}

export const arrayIncludesNumber = (array, number) => {
    return array.includes(number) || array.includes(number?.toString()) || array.includes(Number(number));
}

/**
 * 
 * @param {*} value 
 * @returns 
 * @deprecated
 */
export const valusIsTrue = (value) => {
    return value === "true" || value === true;
}

export const boolIsTrue = (value) => {
    return value === "true" || value === true;
}

/**
 * 
 * @param {number|string} value 
 * @param {number|string} value2 
 * @returns 
 */
export const equal = (value, value2) => {
    if (typeof value === 'number') {
        value = value.toString();
    }

    if (typeof value2 === 'number') {
        value2 = value2.toString();
    }

    if (typeof value === 'string' && typeof value2 === 'string') {
        return value === value2;
    }

    return false;
}

/**
 * 
 * @param {number|string} value 
 * @param {number|string} value2 
 * @returns 
 */
export const notEqual = (value, value2) => {
    if (typeof value === 'number') {
        value = value.toString();
    }

    if (typeof value2 === 'number') {
        value2 = value2.toString();
    }

    if (typeof value === 'string' && typeof value2 === 'string') {
        return value !== value2;
    }

    return false;
}

export const extractNumberFromString = (inputString) => {
    if (!inputString) return NaN;
    const stringWithoutCommas = String(inputString).replace(/,/g, "");
    const match = stringWithoutCommas.match(/[\d.]+/);

    if (match) {
        const numberString = match[0];
        return parseFloat(numberString);
    }

    return NaN;
};

export const getDiffDays = (date1, date2) => {
    const diffTime = date2 - date1;
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    
}

export const filterByMaximun = (maximun) => {
    return (i) => {
        const value = extractNumberFromString(i.description);
        if (!isNaN(value) && maximun < value) {
            return false;
        }
        return true;
    }
}

export const userIsFemale = (birthday, gender) => {
    if (!birthday || !gender) return false;
    return 18 <= getAge(birthday) && getAge(birthday) <= 43 && (gender === 2 || gender === "2");
}