import { useApplication } from 'context/applicationContext';
import { TextsContext } from 'context/textsContext';
import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'

export const AddButtonText = () => {
    const { texts } = useContext(TextsContext);
    const {pathname} = useLocation();
    const {applicationState} = useApplication();
    const isEdit = (applicationState[pathname]?.id !== null && applicationState[pathname]?.id !== undefined);
    
  return (
    <>{isEdit ? (texts?.general_save_button ?? 'Save') :  (texts?.footer_button_add ?? 'Add') }</>
  )
}
