import React, { Fragment } from "react";
import { ParagraphComponent } from "./ParagraphComponent";
import style from '../scss/Valida.module.scss';
import {useCotizador} from "../context/apiContext";
import { useApplication } from "../context/applicationContext";

export const APPLICATION = "application";
export const COTIZADOR = "cotizador";


const ValidaComponent = ({keyName, dependant = false, benefit = false, styleGroup, phase = COTIZADOR, section = ""}) => {
    const {errors: errors_} = useCotizador();
    const {errors: errors_app} =  useApplication();
    const errorList = {
        [COTIZADOR]: errors_,
        [APPLICATION]: errors_app
    };

    let errors = errorList[phase];
    if (!errors) {
        errors = errorList[COTIZADOR];
    }
    if (dependant)
        errors = errors_['dependants'] ?? errors_;
    if (benefit)
        errors = errors_['benefitsList'] ?? errors_;

    if (section) {
        errors = errors[section];
    }


    if (errors?.[keyName] === undefined) return;
    const validateExist = () => {
        for (const i of Object.values(errors[keyName])) {
            if (i) {
                return true;
            }
        }
        return false;
    }
    if (!validateExist()) return;
    let values = Object.values(errors[keyName]).filter(i => i);
    values = values.length && [values[0]];
    if (values[0].includes("[hidden]") ) return;
    return (
        <Fragment>

            <div className={`${style.requiere} ${styleGroup}`}>
                {values.map((i, index) => (
                    <Fragment key={index}>
                        <ParagraphComponent styleGroup='mb-0 color-white'>* {i}</ParagraphComponent>
                    </Fragment>
                ))}
            </div>


        </Fragment>
    )
}

export default ValidaComponent;
