/* eslint-disable eqeqeq */
import { useEffect, useState, useContext } from "react";
import { TextsContext } from '../context/textsContext';
import StyleFooter from "../scss/footer.module.scss";
import { TitleH2Component } from "./TitleH2Component";
import { TitleH1Component } from "./TitleH1Component";
import StyleBtn from "../scss/btn.module.scss";
import { useCotizador } from "../context/apiContext";
import { useNavigate, } from "react-router-dom";
import { useSteps } from "../hooks/useSteps";
import { useCalculations } from "../hooks/CalculationsHook";
import { useValidation } from "../hooks/ValidationHook";
import Loader from "./LoaderComponent";
import { scrollToTopNew } from "./GlobalFunction";
export function FooterComponent(props) {
	const { texts } = useContext(TextsContext);
	const currentPath = window.location.pathname;
	const navigate = useNavigate();
	const { getSteps, getAllSteps, getStep } = useSteps();
	const { fee, fare, addDependant, fareRider,
		resetDependantData, dependantData, editDependant, addDependantToList, fareDependant, isEverythingLoading
	} = useCotizador();
	const { validateValues, validateDependantValues } = useValidation();

	const { calculation } = useCalculations();
	const [isMobile, setIsMobile] = useState(false);
	useEffect(() => {
		function handleResize() {
			setIsMobile(window.innerWidth <= 767);
		}

		window.addEventListener('resize', handleResize);
		handleResize();
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const currentPathIndex = getSteps(addDependant).find(i => i.path == currentPath)?.index;

	const scrollToTop = () => {
		/*window.scrollTo({
			top: 0,
			behavior: "smooth"
		});*/
		
	}

	const nextUrl = () => {
		//scrollToTop();
		scrollToTopNew();
		validateValues();
		if (currentPathIndex < getSteps(addDependant).length - 1) {
			navigate(getStep(currentPathIndex + 1)?.path);
			
		} else {
			// Paso final
		}
	};

	const backUrl = () => {
		scrollToTopNew();
		if (currentPathIndex > 0) {
			navigate(getStep(currentPathIndex - 1).path);
		} else {
			// Paso inicial
		}
	};

	const cancelUrl = () => {
		scrollToTopNew();
		const currentPathIndex = getAllSteps().find(i => i.path === currentPath)?.index;
		navigate(getAllSteps()[currentPathIndex - 1].path);
	};

	const handleAdd = async () => {
		scrollToTopNew();
		if (validateDependantValues()) {
			return;
		}
		const currentPathIndex = getAllSteps().find(i => i.path === currentPath)?.index;
		await navigate(getAllSteps()[currentPathIndex - 1].path);
		await addDependantToList();
		await resetDependantData();
	}

	const handleEdit = async () => {
		scrollToTopNew();
		if (validateDependantValues()) {
			return;
		}
		const currentPathIndex = getAllSteps().find(i => i.path === currentPath)?.index;
		navigate(getAllSteps()[currentPathIndex - 1].path);
		await editDependant();
		await resetDependantData();
	}

	const getCalculation = () => {
		if (fee.isFetching || fare.isFetching || fareDependant.isFetching || fareRider.isFetching) {
			return texts?.footer_value ?? 'Calculating...';
		}
		/** @var {number} calculation */
		return "$" + parseFloat(calculation.toFixed(2)).toLocaleString('en-US', {minimumFractionDigits: 2});
	}



	return (
		<footer className={` ${isMobile ? `` : `container`}  ${StyleFooter.footer}`}>
			<div className={` ${StyleFooter.colfootercontent} d-flex flex-md-column justify-content-between align-items-center   ${currentPath === '/step8' || currentPath === '/step9' ? StyleFooter.ocultar_elemento : ''}`}>
				<TitleH2Component styleGroup={`raleway fw_400 lh_20 color_primary_2  mb-0 me-2 ${StyleFooter.cost}`}>
					{texts?.footer_title ?? 'Estimated annual cost:'}
				</TitleH2Component>
				<TitleH1Component styleGroup="raleway_semibold fw_600 lh_40 color_secundary spacin_2 mb-0">
					{getCalculation()}
				</TitleH1Component>
			</div>

			<div className={`${StyleFooter.colfooterbtn} `}>
				{currentPath != '/step9' && currentPath != '/step3' && <div className={` d-flex justify-content-md-end justify-content-evenly my-2 my-md-0 my-lg-auto me-0 me-lg-5 ${StyleBtn.btnLine} `}>
					<button type="button" onClick={backUrl} className={`Poppins fw_500 color_secundary border-0 me-md-1 me-4  ${StyleBtn.btnNeutro}  ${StyleBtn.wpbtn}`}>{texts?.footer_button_previous ?? 'Previous'}</button>
					<button type="button" disabled={isEverythingLoading()} onClick={nextUrl} className={`Poppins fw_500 color_primary ms-1 ${StyleBtn.btn} ${StyleBtn.wpbtn} `}>
						{
							isEverythingLoading() ? <Loader Loaderpunto={true}></Loader> : (texts?.footer_button_next ?? 'Next')
						}
					</button>
				</div>}
				{currentPath === '/step9' && <div className={` d-flex justify-content-md-end justify-content-evenly my-4 my-md-auto ${StyleBtn.btnLine} `}>
					<button type="button" onClick={backUrl} className={`Poppins fw_500 color_secundary border-0 me-md-1 me-4  ${StyleBtn.btnNeutro}  ${StyleBtn.wpbtn}`}>{texts?.footer_button_previous ?? 'Previous'}</button>
					<button type="button" className={`Poppins fw_500 color_primary  px-4 ${StyleBtn.btnDisabled} me-0 me-md-5`}>  <span>Coming Soon</span>  </button>
				</div>}

				{currentPath === '/step3' && <div className={` d-flex justify-content-md-end justify-content-evenly my-2 my-md-4 my-lg-auto ${StyleBtn.btnLine} `}>
					<button type="button" onClick={cancelUrl} className={`Poppins fw_500 color_secundary border-0 me-4 ${StyleBtn.btnNeutro}  ${StyleBtn.wpbtn}`}>{texts?.footer_button_cancel ?? 'Cancel'}</button>
					<button type="button" onClick={dependantData.id !== undefined ? handleEdit : handleAdd} className={`Poppins fw_500 color_primary ${StyleBtn.btn} ${StyleBtn.wpbtn} `}>
						{dependantData.id !== undefined ? texts?.footer_button_edit ?? 'Edit' : texts?.footer_button_add ?? 'Add'}
					</button>
				</div>}
			</div>

		</footer>
	);
}
