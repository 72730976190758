import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
import { TextsProvider } from "./context/textsContext";
import { ApiProvider } from "./context/apiContext";
import { BrowserRouter } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AuthProvider } from "./context/userContext";
import { ApplicationProvider } from "./context/applicationContext";

const queryClient = new QueryClient();

const AppWrapper = () => {
  const combineProviders = (providers) =>
    providers.reduce((Combined, Provider) => ({ children }) => (
      <Combined>
        <Provider>{children}</Provider>
      </Combined>
    ));

  const Providers = combineProviders([
    TextsProvider,
    AuthProvider,
    ApiProvider,
    ApplicationProvider,
  ]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <QueryClientProvider client={queryClient}>
        <Providers>
          <App />
        </Providers>
      </QueryClientProvider>
    </LocalizationProvider>
  );
};

export default AppWrapper;
