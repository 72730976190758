import React from "react";
import { useApplicationInput } from "../hooks/ApplicationInputHook";

export const CheckboxComponent = (props) => {
  const {name, placeholder, required, className, id, disabled} = props;
  const {handleOnChange, value, failedRequired} = useApplicationInput({...props, type: "checkbox"});
  const yetToCheck =(failedRequired && !value && required);
  
  return (
      <input
        id={id}
        type={"checkbox"}
        className={`${className}`}
        style={yetToCheck ? {borderColor: "#F48989", transitionProperty: "all", transitionDuration: "0.2s"} : null}
        placeholder={placeholder}
        name={name}
        onChange={handleOnChange}
        checked={value}
        required={required}
        disabled={disabled}
      />
  );
};
