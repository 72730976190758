import React, { Fragment, useEffect, useState, useContext } from "react";
import { TextsContext } from '../../context/textsContext';
import Stylegeneral from '../../scss/general.module.scss';
import styleForm from '../../scss/form.module.scss';
import TooltipComponent from "../../components/TooltipComponent";
import { TitleH1Component } from "../../components/TitleH1Component";
import { TitleH3Component } from "../../components/TitleH3Component";
import PintureComponent from '../../components/PictureComponent';
import { Icon } from "@iconify/react";
import { TitleH2Component } from "../../components/TitleH2Component";
import SelectBenefitsComponent from "../../components/SelectBenefitsComponent";
import { useCotizador } from "../../context/apiContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styleTexto from '../../scss/text.module.scss';
import ValidaComponent from "components/ValidaComponent";
import {Disclaimer} from "./component/Disclaimer";
const Step6Pages = () => {
    const { texts, verifyCurrentLanguage } = useContext(TextsContext);
    const { benefits, outpationBenefitsmaternidad, handleOnChange, nonMaternidad } = useCotizador();
    const [isMobileDevice, setIsMobileDevice] = useState(false);
    const [maternity, setMaternity] = useState(false);
    const [recienNacido, setRecienNacido] = useState(false);
    useEffect(() => {
        verifyCurrentLanguage();
        function handleResize() {
            setIsMobileDevice(window.innerWidth <= 768);
        }

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleChecks = (e) => {
        handleOnChange({ target: { value: e.target.checked, name: e.target.name } })
    }

    console.log('ver maternidas', recienNacido);
    return (
        <Fragment>
            <section id="sectionscroll">
                <div className="container ">
                    <div className="d-block d-lg-none">
                        <TitleH2Component styleGroup={`raleway fw_400 lh_40 color_secundary mb-0 py-2 ${Stylegeneral.titulo_principal}`}>
                            {texts?.general_header_title ?? 'New VUMI VIP Care'}
                        </TitleH2Component>
                    </div>
                    <TitleH1Component styleGroup={`raleway_semibold fw_700 lh_30 color_primary pb-4 ${Stylegeneral.titulo}`}>
                        {texts?.step4_title ?? "Let’s continue customizing based on your exact needs"}
                    </TitleH1Component>

                    <TitleH3Component styleGroup={`Poppins fw_400 color_primary pb-0 ${Stylegeneral.titulo}`}>
                        {texts?.step6_note ?? 'Note: Eligibility for the maternity benefit is for female applicants between the ages of 18 and 43.'}
                    </TitleH3Component>
                </div>
            </section>

            <section>
                <div className="container">

                    <div className="row gy-5">
                        <div className="col-12 col-md-6 ">
                            <div className={`${Stylegeneral.col_img}`}>
                                <img src="../../img/Mask.jpg" alt="step6" className="w-100" />
                                <img src="../../img/marcaagua.png" alt="tep6" className={`${Stylegeneral.img}`} />
                            </div>

                        </div>
                        <div className="col-12  col-md-6 ">

                            <div className={Stylegeneral.col_form}>
                                <div className={`${styleForm.btn_activar} pb-3 `}>
                                    <div className={`${styleForm.con_tootip}`}>
                                        <label htmlFor="need" className={`${styleForm.labels}  raleway  mb-2 color_primary_2 fw_700 pe-1`}>
                                            {texts?.step6_Text_Benefitsmaternidad ?? "¿Quiere agregar beneficios de maternidad?"}
                                        </label>
                                    </div>

                                    <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                        <input className="form-check-input" type="checkbox" id="need" name={"outpationBenefitsmaternidad"} onChange={handleChecks} checked={outpationBenefitsmaternidad} />
                                    </div>
                                </div>
                                {/* <div className={`${styleForm.form_group} mb-5`}>
                                    <div className="d-flex align-items-center">
                                        <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1 `}>Apply deductible</label>

                                        <TooltipComponent text="Este es un tooltip "><Icon icon={'mdi:information-outline'} className={` color_gray_5 ${styleForm.iconselect}`} /></TooltipComponent>

                                    </div>

                                    <select className={`${styleForm.customselectLine}`}>
                                        <option value="opcion1">???</option>
                                    </select>
    </div>*/}
                                <div className={`${styleForm.form_group} mb-5 ${outpationBenefitsmaternidad ? '' : 'd-none'}`}>
                                    <div className="d-flex align-items-center">
                                        <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1 `}>
                                            {texts?.step6_form_field_cesarean ?? 'Maternity and elective cesarean'}
                                        </label>
                                        <OverlayTrigger
                                            key={isMobileDevice ? 'left' : 'top'}
                                            placement={isMobileDevice ? 'left' : 'top'}
                                            overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{texts?.step6_tooltip_pregnancy ?? 'A pregnancy ending by natural or cesarean delivery.'}</Tooltip>}
                                        >
                                            <Icon icon={'mdi:information-outline'} className={` color_gray_5 ${styleForm.iconselect}`} />
                                        </OverlayTrigger>
                                    </div>

                                    <SelectBenefitsComponent setMaternity={setMaternity} benefits={benefits.regularData} benefit={"Maternity and cesarean"} className={`${styleForm.customselectLine}`} />

                                </div>
                                <div className={`${styleForm.form_group} ${outpationBenefitsmaternidad ? '' : 'd-none'}`}>
                                    <div className="d-flex align-items-center">
                                        <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1 `}>
                                            {texts?.step6_form_field_newborn ?? 'Maternity and newborn complications'}
                                        </label>
                                        <OverlayTrigger
                                            key={isMobileDevice ? 'left' : 'top'}
                                            placement={isMobileDevice ? 'left' : 'top'}
                                            overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{texts?.step6_tooltip_pathology ?? 'Pathology or treatment resulting from the abnormal course of pregnancy and/or delivery, or any disorder related to a newborn that is not caused by genetic factors and that occurs during the first thirty (30) days of life.'}</Tooltip>}
                                        >
                                            <Icon icon={'mdi:information-outline'} className={` color_gray_5 ${styleForm.iconselect}`} />
                                        </OverlayTrigger>

                                    </div>

                                    <SelectBenefitsComponent setRecienNacido={setRecienNacido} benefits={benefits.regularData} benefit={"Maternity and newborn complications"} className={`${styleForm.customselectLine}`} />

                                </div>
                                <div className={`mt-4 ${outpationBenefitsmaternidad ? '' : 'd-none'}`}>
                                    <TitleH3Component styleGroup={`Poppins fw_400 color_primary pb-3 ${styleTexto.tamasubparrafo} ${Stylegeneral.titulo}`}>
                                        {texts?.step5_Text_beneficiomaternidad ?? "Para el beneficio de maternidad y de complicaciones de maternidad y del recién nacido aplica el deducible seleccionado y un período de espera de 10 meses. La inclusión del recién nacido será sin evaluación de riesgos si nace de una maternidad cubierta."}
                                    </TitleH3Component>
                                </div>

                                <Disclaimer />
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </Fragment>
    )
}
export default Step6Pages;