import React, { Fragment, useState, useEffect, useRef, useContext } from "react";
import style from '../../../scss/SelecctorIdioma.module.scss'
import { Icon } from "@iconify/react";
import { TextsContext } from "../../../context/textsContext";
const SelecctorIdiomaComponent = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState([]);
    const { updateTexts, getCurrentLanguage } = useContext(TextsContext);
    const options = [
        { nombre: 'English', icon: 'twemoji:flag-england', traductor: 'en' },
        { nombre: 'Español', icon: 'twemoji:flag-spain', traductor: 'es' },
        { nombre: 'Portugues', icon: 'twemoji:flag-portugal', traductor: 'pt' }
    ];
    const selectRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleSelect = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionChange = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    return (
        <Fragment>
            <div className={`${style.custom_select}`} ref={selectRef}>
                <div className={`${style.select_header} color_primary_2 fw_500 Poppins`} onClick={toggleSelect}>
                    <span> {selectedOption.nombre || options.find(option => option.traductor === getCurrentLanguage()).nombre}</span>
                    <div className={`${style.bandera} ms-2`}>
                        <Icon icon={selectedOption.icon || options.find(option => option.traductor === getCurrentLanguage()).icon} />
                    </div>
                    <div className={`${style.icon}`}>
                        <Icon icon={isOpen ? 'mdi:chevron-up' : 'mdi:chevron-down'} />
                    </div>

                </div>
                {isOpen && (
                    <div className={`${style.options}`}>
                        {options.map((option, index) => (
                            <div
                                key={index}
                                className={`${style.custom_option}  ${option.nombre === selectedOption ? 'selected' : ''}`}
                                onClick={() => { handleOptionChange(option); updateTexts(option.traductor) }}
                            >
                                <span>{option.nombre}</span>
                                <div className={`${style.bandera} ms-2`}>
                                    <Icon icon={option.icon} />
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </Fragment>
    )
}
export default SelecctorIdiomaComponent;