import Stylegeneral from "../../../scss/general.module.scss";
import {TitleH2Component} from "../../../components/TitleH2Component";
import styleTexto from "../../../scss/text.module.scss";
import styleForm from "../../../scss/form.module.scss";
import ValidaComponent from "../../../components/ValidaComponent";
import React, {useContext} from "react";
import {useCotizador} from "../../../context/apiContext";
import {TextsContext} from "../../../context/textsContext";
import { equal } from "helpers/toolsHelper";

export const Disclaimer = () => {
    const { outpationBenefitsmaternidad, handleOnChange, nonMaternidad, selectedBenefitsIds } = useCotizador();
    const { texts } = useContext(TextsContext);

    const maternityIsNo = equal(selectedBenefitsIds['Maternity and cesarean'], "182");
    const newbornIsNo = equal(selectedBenefitsIds['Maternity and newborn complications'], '202');

    const handleChecks = (e) => {
        handleOnChange({ target: { value: e.target.checked, name: e.target.name } })
    }

    const getText = () => {
        if ((maternityIsNo && newbornIsNo) || !outpationBenefitsmaternidad) {
            return texts?.general_disclaimer_no_mother_beneficios ?? '...';
        } else if (!maternityIsNo && newbornIsNo) {
            return texts?.general_disclaimer_no_newborn_beneficios ?? '...';
        } else if (maternityIsNo && !newbornIsNo) {
            return texts?.general_disclaimer_no_maternity_beneficios ?? '...';
        } else {
            return texts?.general_dislcaimer_nobeneficios ?? '...';
        }
    }

    return (
        <div className={`${!outpationBenefitsmaternidad || (maternityIsNo || newbornIsNo) ? `mt-3 ${Stylegeneral.mb_cuadroInf}` : 'd-none'}`}>
            <div className={`${Stylegeneral.cuadro_beneficio} p-2`}>
                <TitleH2Component styleGroup={`raleway fw_700 color_primary mb-4 text-shadow text-start`}>
                    {texts?.step11_provider_message_title ?? 'Important provider networks message:'}
                </TitleH2Component>
                <div className={`form-check mt-4 `}>
                    <input className="form-check-input" type="checkbox" value="" id="acepto" name={"nonMaternidad"} checked={nonMaternidad} onChange={handleChecks} />

                    <label className={`Poppins fw_700  typecursiva color_primary mb-0 ${styleTexto.h3}`} htmlFor="acepto">
                        {getText()}
                    </label>
                    <div className={`${styleForm.validador}`}>
                        <ValidaComponent keyName={"nonMaternidad"} styleGroup={`${styleForm.valida}`} />
                    </div>
                </div>
            </div>
        </div>
    )
}