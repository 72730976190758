export const BENEFITS_NEGATIVES = [
    {
        "factorId.id": 24,
        "id": 107,
    }, 
    {
        "factorId.id": 18,
        "id": 81,
    }, 
    {
        "factorId.id": 14,
        "id": 210,
    }
    , 
    {
        "factorId.id": 19,
        "id": 82,
    }, 
    {
        "factorId.id": 21,
        "id": 94,
    }, 
    {
        "factorId.id": 36,
        "id": 167,
    }, 
    {
        "factorId.id": 37,
        "id": 181,
    }
    , 
    {
        "factorId.id": 41,
        "id": 214,
    }
    ,
    {
        "factorId.id": 22,
        "id": 97
    }
    ,
    {
        "factorId.id": 17,
        "id": 74
    }
    ,
    {
        "factorId.id": 38,
        "id": 182
    }
    ,
    {
        "factorId.id": 40,
        "id": 202
    }
]