import React from "react";

const REMOVE_ERROR = "applicationError-remove";
const ADD_ERROR = "applicationError-add";

const initialState = {};

const errorsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_ERROR:
      return {
        ...state,
        [action.payload.section]: {
          ...(state[action.payload.section] ?? {}),
          [action.payload.key]: {
            ...(state[action.payload.section]?.[action.payload.key] ?? {}),
            [action.payload.code]: action.payload.text,
          },
        },
      };
    case REMOVE_ERROR:
      return {
        ...state,
        [action.payload.section]: {
          ...(state[action.payload.section] ?? {}),
          [action.payload.key]: {
            ...(state[action.payload.section]?.[action.payload.key] ?? {}),
            [action.payload.code]: null,
          },
        },
      };
    default:
      return state;
  }
};

export const useApplicationErrors = () => {
  const [errors, dispatchError] = React.useReducer(errorsReducer, initialState);

  const addError = (section, key, code, text) => {
    dispatchError({ type: ADD_ERROR, payload: { section, key, code, text } });
  };

  const removeError = (section, key, code) => {
    dispatchError({ type: REMOVE_ERROR, payload: { section, key, code } });
  };

  const sectionHasNoErrors = (section) => {
    if (!errors[section]) return true;
    return !Object.values(errors[section])
    .map((i) =>
    Object.values(i).reduce((a,b) => {
        if (a) return true;
        if (!b) return false;
        return b;
    }, false)
  )
      .filter((i) => i).length;
  };

  return {
    errors,
    addError,
    removeError,
    sectionHasNoErrors,
  };
};
