import React, { Fragment, useState, useEffect, useContext } from "react";
import { TextsContext } from "../../../context/textsContext";
import Picture from "../../../components/PictureComponent";
import { TitleH2Component } from "../../../components/TitleH2Component";
import Stylegeneral from '../../../scss/general.module.scss'
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ValidaComponent from "../../../components/ValidaComponent";
import styleForm from '../../../scss/form.module.scss';
import { Icon } from "@iconify/react";
import { CheckboxComponent } from "../../../components/CheckboxComponent";
import { useLocation } from "react-router-dom";
import { InputComponent } from "../../../components/application/InputComponent";
import { DateAppComponent } from "../../../components/application/DateAppComponent";
const CotizacionPage = () => {
    const { texts, verifyCurrentLanguage } = useContext(TextsContext);
    const [isMobileDevice, setIsMobileDevice] = useState(false);
    const { pathname } = useLocation();

    const handleChecks = (e) => { }

    const [getCheck, setGetCheck] = useState([
        { id: 1, text: texts?.quote_checkbox_option_one ?? 'Cuidados Preventivos (Solicitante)', checked: false, name: "preventiveCare" },
        { id: 2, text: texts?.quote_checkbox_option_two ?? 'Anexo de Travel VIP Light (Solicitante)', checked: false, name: "travelVip" }
    ]);

    const handleCheckboxChange = (id) => {
        // Copia el arreglo de elementos y actualiza el estado de la casilla de verificación
        const updatedItems = getCheck.map(item => {
            if (item.id === id) {
                return { ...item, checked: !item.checked };
            }
            return item;
        });
        setGetCheck(updatedItems);
    };

    useEffect(() => {
        verifyCurrentLanguage();
    }, []);

    return (
        <Fragment>
            <section>
                <div className="container">
                    <div className=" d-flex justify-content-center py-3  ">
                        <div className="d-flex align-items-center ">
                            <Picture src="/img/cotizacion.png" alt="VUMI" styleGroup={`${Stylegeneral.logosimg} w-auto mb-0 me-2`} />
                            <TitleH2Component styleGroup={`raleway_bold color-azul2 lh_30   mb-0 ${Stylegeneral.titulo} `}>
                                {texts?.quote_title ?? 'Cobertura'}
                            </TitleH2Component>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pb-5">
                <div className="container px-3">
                    <div className="row gy-4">
                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                    <label htmlFor="fsolicitud" className={`${styleForm.labels} ${Stylegeneral.contentLabel} raleway color_primary fw_700`}>
                                        {texts?.quote_effective_date_title ?? 'Fecha de efectividad solicitada'}
                                    </label>
                                    <OverlayTrigger
                                        key={isMobileDevice ? 'left' : 'top'}
                                        placement={isMobileDevice ? 'left' : 'top'}
                                        overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{"Geographic area where the policy´s benefits apply"}</Tooltip>}
                                    >
                                        <Icon icon={'mdi:information-outline'} className={`color_gray_5 ${styleForm.iconselect}`} />
                                    </OverlayTrigger>
                                </div>

                                {/* <input type="date" className={`${styleForm.input_with_line} raleway fw_400`} placeholder={'Ej:Publicidad'} name={"fsolicitud"} onChange={handleChecks} required /> */}
                                <DateAppComponent type="date" className={`${styleForm.input_with_line} raleway fw_400`} name={"fsolicitud"} section={pathname} disabled />

                            </div>
                            <ValidaComponent keyName={"name"} />
                        </div>


                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                    <label htmlFor="plan" className={`${styleForm.labels} ${Stylegeneral.contentLabel} raleway color_primary fw_700`}>
                                        {texts?.quote_plan_title ?? 'Plan'}
                                    </label>
                                    <OverlayTrigger
                                        key={isMobileDevice ? 'left' : 'top'}
                                        placement={isMobileDevice ? 'left' : 'top'}
                                        overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{"Geographic area where the policy´s benefits apply"}</Tooltip>}
                                    >
                                        <Icon icon={'mdi:information-outline'} className={`color_gray_5 ${styleForm.iconselect}`} />
                                    </OverlayTrigger>
                                </div>

                                {/* <input type="text" className={`${styleForm.input_with_line} raleway fw_400`} placeholder={texts?.quote_plan_placeholder ?? 'Universal VIP'} name={"plan"} onChange={handleChecks} required /> */}
                                <InputComponent type="text" className={`${styleForm.input_with_line} raleway fw_400`} placeholder={texts?.quote_plan_placeholder ?? 'Universal VIP'} name={"plan"} section={pathname} disabled={true} />

                            </div>
                            <ValidaComponent keyName={"name"} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                <div className={`d-flex align-items-center ${Stylegeneral.contentLabel} pb-4 pb-md-2`}>
                                    <label htmlFor="cadicinal" className={`${styleForm.labels} ${Stylegeneral.contentLabel} raleway color_primary fw_700`}>
                                        {texts?.quote_additional_coverage_title ?? 'Cobertura adicional'}
                                    </label>
                                </div>

                                {getCheck.map(item => (

                                    <label className={`checkbox blue pb-4 pb-md-2 d-flex align-items-center ${styleForm.labels} ${Stylegeneral.contentLabel} raleway color_primary fw_400`} key={item.id}>
                                        <CheckboxComponent
                                            className='me-2'
                                            name={item.name}
                                            section={pathname}
                                            disabled={true}
                                        />
                                        <span className='indicator'></span>
                                        <span>{item.text}</span>
                                    </label>

                                ))}

                            </div>

                        </div>
                    </div>

                    <div className={`pt-0 mt-0`}>
                        <div className={` ${Stylegeneral.contentLabel} mb-2`}>
                            <span className={`${styleForm.labels} ${Stylegeneral.contentLabel} raleway color_primary fw_700`}>
                                {texts?.quote_additional_coverage_title ?? 'Cobertura adicional'}
                            </span>
                        </div>
                        <p className={`${styleForm.text_informacion}  raleway color_primary fw_400`}>
                            {texts?.quote_additional_coverage_phrase ?? 'Este beneficio está disponible para todos los solicitantes desde el momento en que se recibe la solicitud* hasta que la póliza sea aprobada.'}
                        </p>
                    </div>



                    <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                        <div className={` ${Stylegeneral.contentLabel} `}>
                            <span className={`${styleForm.labels} ${Stylegeneral.contentLabel} raleway color_primary fw_700`}>
                                {texts?.quote_requirements_title ?? 'Requisitos'}
                            </span>
                        </div>
                        <p className={`${styleForm.text_informacion}  raleway color_primary fw_400`}>
                            {texts?.quote_requirements_phrase ?? '*Solicitud completa y firmada (con toda la información necesaria para ser aprobada de acuerdo a las guías de evaluación de riesgo de la Compañía) y el pago total de la prima, de acuerdo al modo de pago.'}
                        </p>
                    </div>


                    <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                        <div className={` ${Stylegeneral.contentLabel}`}>
                            <span className={`${styleForm.labels} ${Stylegeneral.contentLabel} raleway color_primary fw_400`}>
                                {texts?.quote_benefits_title ?? 'Beneficios'}
                            </span>
                        </div>
                        <p className={`${styleForm.text_informacion}  raleway color_primary fw_400`}>
                            {texts?.quote_benefits_phrase ?? '*Solicitud completa y firmada (con toda la información necesaria para ser aprobada de acuerdo a las guías de evaluación de riesgo de la Compañía) y el pago total de la prima, de acuerdo al modo de pago.'}
                        </p>
                    </div>



                    <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                        <div className={` ${Stylegeneral.contentLabel} `}>
                            <span className={`${styleForm.labels} ${Stylegeneral.contentLabel} raleway color_primary fw_400`}>
                                {texts?.quote_term_title ?? 'Término'}
                            </span>
                        </div>
                        <p className={`${styleForm.text_informacion}  raleway color_primary fw_400`}>
                            {texts?.quote_term_pharse ?? 'Máximo de sesenta (60) días o hasta la fecha de efectividad de la póliza, lo que ocurra primero. Este beneficio cubre los gastos por lesiones causadas por accidentes que ocurran durante el proceso de evaluación de riesgo y está sujeto a los términos y condiciones de la póliza y a la aplicación del deducible del plan/opción seleccionado. La eliminación del deducible en caso de accidente serio no aplica para este beneficio temporal. Los pagos se harán vía reembolso cuando la póliza esté aprobada. El beneficio está disponible en casos de adición de asegurados a una póliza existente. El accidente cubierto bajo este beneficio y/o sus consecuencias no afectarán la aprobación de la solicitud.'}
                        </p>

                    </div>

                </div>

            </section>
        </Fragment >
    )
}

export default CotizacionPage;