import { sendEmailWithPdfUsingAppNumber } from 'api/emailApi';
import {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';

export const DownloadPdf = () => {
    const {appNumber} = useParams();
    const queryParams = new URLSearchParams(window.location.search)
    const applicationNumberVumi = queryParams.get("applicationNumber");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        sendEmailWithPdfUsingAppNumber(appNumber, applicationNumberVumi).then(() =>{
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
        });
    }, [appNumber]);

    return (
        <div>
            {
                !loading && <div id="success"></div>
            }
        </div>
    );
};
