import { useEffect, useState, useContext } from "react";
import { TextsContext } from "../context/textsContext";
import { useApplication } from "../context/applicationContext";

/**
 *  @typedef ApplicationInputType
 *  @property {string} value
 *  @property {(e: import("react").ChangeEvent<HTMLInputElement>) => void} handleOnChange
 *  @property {boolean} failedRequired
 */

/** 
 *  @function
 * 
 *  @returns {ApplicationInputType}
 */
export const useApplicationInput = ({
  minimum = 0,
  limit = 0,
  numeric = false,
  section = "",
  name = "",
  errorTexts = {},
  required = false,
  type = "text"
}) => {
  const { texts } = useContext(TextsContext);
  const {
    applicationState,
    handleOnChange: handleOnChange_,
    addError: addError_,
    removeError: removeError_,
    failedNextIntent,
  } = useApplication();
  const [isDirty, setIsDirty] = useState(false);
  const addError = (key, text) => addError_(section, name, key, text);
  const removeError = (key) => removeError_(section, name, key);

  const handleOnChange__ = (e, val) => {
    handleOnChange_(
      {
        ...e,
        target: {
          value: val,
          name: e.target.name,
        },
      },
      section
    );
  }

  /** @param {import("react").ChangeEvent<HTMLInputElement>} e */
  const handleOnChange = (e) => {
    setIsDirty(true);
    let val = e.target.value;
    if (numeric) val = val.replace(/[^0-9\-.,]/g, "");
    if (limit) val = val.slice(0, limit);
    if (minimum) {
      if (val.length < minimum)
        addError("minimunLength", errorTexts["minimunLength"] ?? "Too short");
      else removeError("minimunLength");
    }
    if (type?.toLowerCase() === "checkbox") {
      const check = e.target.checked;
      handleOnChange__(e, (check === "true" || check === true) ? true : false);
      return;
    }


    handleOnChange__(e, val);
  };

  

  const handleNoInputError = () => {
    const activated = isDirty || failedNextIntent;
    const value =
      applicationState?.[section]?.[name] !== undefined ? applicationState?.[section]?.[name] : "";
    
    if (required && !activated && !value) {
      addError(
        "noInput",
        errorTexts["noInput"] ?? ('[hidden] This field is required')
        // errorTexts["noInput"] ?? (texts?.general_field_validation_hidden ?? '[hidden] This field is required')
      );
    } else if (required && activated && !value) {
      addError("noInput", errorTexts["noInput"] ?? (texts?.general_field_validation_required ?? 'This field is required'));
    } else if (required && activated && value) {
      removeError("noInput");
    }
  }

  useEffect(() => {
    if (type.toLowerCase() === 'checkbox' && (applicationState?.[section]?.[name] === undefined || applicationState?.[section]?.[name] === null) ) {
      handleOnChange__({target: {name}}, false)
    }
  }, [])
  
  useEffect(() => {
    handleNoInputError();
    return () => {
      removeError("noInput");
    }
  }, [applicationState?.[section]?.[name], failedNextIntent]);

  return {
    handleOnChange,
    value: applicationState[section]?.[name] !== undefined ? applicationState[section]?.[name] : "",
    failedRequired: failedNextIntent
  };
};
