import React, { Fragment } from "react";
import { Collapse } from 'react-bootstrap';
import { TitleH3Component } from "../../../components/TitleH3Component";
import style from '../../../scss/collapse.module.scss';
import { Icon } from "@iconify/react";

const AccordionItemComponent = ({className, title, openItem, itemNumber, onClick, children}) => {


    return (
        <Fragment>
            <div className={className}>
                <div
                    onClick={onClick}
                    aria-controls="example-collapse-text"
                    aria-expanded={openItem[itemNumber]}
                    className={`${style.encabezado} pb-3 py-4 `}
                >
                    <TitleH3Component styleGroup={`raleway_bold fw_700 mb-0 color_primary ${style.titulo}`}>
                        {title}
                    </TitleH3Component>
                    <div className={`${style.icon}`}>
                    {openItem[itemNumber] ? <Icon icon="ep:arrow-up"/> : <Icon icon="ep:arrow-down"/>}

                    </div>
                </div>
                <Collapse in={openItem[itemNumber]}>
                    {children}
                </Collapse>

            </div >
        </Fragment >
    )
}
export default AccordionItemComponent;
