import React, { Fragment, useEffect, useContext } from "react";
import { TextsContext } from "context/textsContext";
import styleForm from '../../../scss/form.module.scss';
import { InputTextComponent } from "../../../components/application/InputTextComponent";
import ValidaComponent, { APPLICATION } from "../../../components/ValidaComponent";
import { SelectAppComponent } from "../../../components/application/SelectAppComponent";
import Stylegeneral from '../../../scss/general.module.scss'
import { CheckboxComponent } from "components/CheckboxComponent";
import { useLocation } from "react-router-dom";

const Registro7Page = () => {
    const { texts, verifyCurrentLanguage } = useContext(TextsContext);
    const {pathname} = useLocation();

    useEffect(() => {
		verifyCurrentLanguage();
	}, []);

    return (
        <Fragment>
            <section>
                <div className="container py-3">
                    <div className={`${styleForm.btn_activar}`}>
                        <label htmlFor="examen" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                            {texts?.page_seven_checkbox_one ?? '¿Alguno de los solicitantes ha sufrido algún accidente? En caso afirmativo, indique:'}
                        </label>
                        <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                            <CheckboxComponent className="form-check-input" section={pathname} id="examen" name={"haveAccident"} />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container pb-5">
                    <div className="row gy-4">
                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <SelectAppComponent
                                name="solicitante"
                                label={texts?.general_form_field_applicant ?? "Solicitante"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                placeholder='Ej:Colombia'
                                type='text'
                                section={pathname}
                                options={[]}
                            />

                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"solicitante"} />
                        </div>

                        <div className={`col-12 ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={pathname}
                                name="taccidente"
                                label={texts?.page_seven_form_field_accident ?? "Tipo de accidente"}
                                placeholder={texts?.page_seven_form_placeholder_accident ?? "Ej: de vehiculo"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                type='text'
                            />
                            <ValidaComponent keyName={"taccidente"} phase={APPLICATION} section={pathname} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.btn_activar}`}>
                                <label htmlFor="tratamiento" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                    {texts?.page_seven_checkbox_two ?? '¿Está actualmente en tratamiento por motivo de este accidente?'}
                                </label>
                                <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                    <CheckboxComponent section={pathname} className="form-check-input" type="checkbox" id="tratamiento" name={"addDependant"} />
                                </div>
                            </div>
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.btn_activar}`}>
                                <label htmlFor="secuelas" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                    {texts?.page_seven_checkbox_three ?? '¿Existen secuelas conocidas en la salud de la persona a causa de este accidente? En caso afirmativo, por favor detallar:'}
                                </label>
                                <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                    <CheckboxComponent section={pathname} className="form-check-input" type="checkbox" id="secuelas" name={"secuelas"} />
                                </div>
                            </div>
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={pathname}
                                name="rsultados"
                                label={texts?.page_seven_form_field_result ?? "Resultado"}
                                placeholder={texts?.page_seven_form_placelholder_result ?? "Ej: Causa de este accidente"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                type='text'
                                required

                            />
                            <ValidaComponent keyName={"rsultados"} phase={APPLICATION} section={pathname} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.btn_activar}`}>
                                <label htmlFor="aparatoortopedico" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                    {texts?.page_seven_checkbox_four ?? '¿Usa algún aparato ortopédico?'}
                                </label>
                                <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                    <CheckboxComponent section={pathname} className="form-check-input" type="checkbox" id="aparatoortopedico" name={"ortopedico"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}
export default Registro7Page;