import React, { Fragment, useState, useEffect, useContext } from "react";
import { TextsContext } from "context/textsContext";
import Picture from "../../../components/PictureComponent";
import { TitleH2Component } from "../../../components/TitleH2Component";
import Stylegeneral from '../../../scss/general.module.scss'
import style from '../../../scss/InfoSolicitudo.module.scss';
import { InputTextComponent } from "../../../components/application/InputTextComponent";
import ValidaComponent from "../../../components/ValidaComponent";
import styleForm from '../../../scss/form.module.scss';

const EditarInfoDependientePage = () => {
    const { texts, verifyCurrentLanguage } = useContext(TextsContext);
    const [getCheck, setGetCheck] = useState([
        { id: 1, text: texts?.edit_dependent_checkbox_option_one ?? 'Cuidados Preventivos', checked: false },
        { id: 2, text: texts?.edit_dependent_checkbox_option_two ?? 'Travel VIP Light (Solicitante)', checked: false }
    ]);

    const handleCheckboxChange = (id) => {
        // Copia el arreglo de elementos y actualiza el estado de la casilla de verificación
        const updatedItems = getCheck.map(item => {
            if (item.id === id) {
                return { ...item, checked: !item.checked };
            }
            return item;
        });
        setGetCheck(updatedItems);
    };

    const handleChecks = (e) => {

    }

    useEffect(() => {
        verifyCurrentLanguage();
    }, []);

    return (
        <Fragment>
            <section>
                <div className="container d-flex justify-content-center ">
                    <div className={`${style.titulos_principales}`}>
                        <div className="d-flex align-items-center py-3">
                            <Picture src="/img/independiente.png" alt="VUMI" styleGroup={`${style.logosimg} w-auto mb-0 me-2`} />
                            <TitleH2Component styleGroup={`raleway_bold color-azul2 lh_30   mb-0 ${Stylegeneral.titulo} `}>
                                {texts?.edit_dependent_title ?? 'Información de Dependientes'}
                            </TitleH2Component>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container px-3">
                    <div className="row gy-4">
                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                name="name"
                                label={texts?.dependents_info_collapsible_name ?? 'Nombre(s)'}
                                placeholder={texts?.edit_dependent_placeholder_name ?? 'Ingrid'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                type='text'
                            />
                            <ValidaComponent keyName={"name"} />
                        </div>

                        <div className={`col-12 ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                name="apelidos"
                                label={texts?.dependents_info_collapsible_surname ?? 'Apellido(s)'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                placeholder={texts?.edit_dependent_placeholder_surname ?? 'Bustamante'}
                                type='text'
                            />

                            <ValidaComponent keyName={"apelidos"} />
                        </div>
                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                name="numpasaporte"
                                label={texts?.edit_dependent_passport ?? 'Número de pasaporte o identificación'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                placeholder={texts?.edit_dependent_placeholder_passport ?? '8987-9988'}
                                type='text'
                            />

                            <ValidaComponent keyName={"numpasaporte"} />
                        </div>
                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                name="sexo"
                                label={texts?.dependents_info_collapsible_sex ?? 'Sexo'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                placeholder='Femenino'
                                type='text'
                            />

                            <ValidaComponent keyName={"sexo"} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                name="calendar"
                                label={texts?.edit_dependent_calendar ?? 'Calendar'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                placeholder='Oct-11-1990'
                                type='date'
                            />

                            <ValidaComponent keyName={"calendar"} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                name="paisR"
                                label={texts?.edit_dependent_country ?? 'País de residencia'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                placeholder='Colombia'
                                type='text'
                            />

                            <ValidaComponent keyName={"paisR"} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                name="relacionS"
                                label={texts?.dependents_info_collapsible_relation ?? 'Relación con el solicitante principal'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                placeholder={texts?.edit_dependent_placeholder_relation ?? 'Cónyuge'}
                                type='text'
                            />

                            <ValidaComponent keyName={"relacionS"} />
                        </div>

                        <div className={`col-12   ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                {getCheck.map(item => (

                                    <label className={`checkbox blue pb-4 pb-md-2 d-flex align-items-center ${styleForm.labels} ${Stylegeneral.contentLabel} raleway color_primary fw_400`} key={item.id}>
                                        <input
                                            type="checkbox"
                                            className='me-2'
                                            checked={item.checked}
                                            onChange={() => handleCheckboxChange(item.id)}
                                        />
                                        <span className='indicator'></span>
                                        <span>{item.text}</span>
                                    </label>

                                ))}

                            </div>

                        </div>
                        <div className={`col-12 ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                name="estatura"
                                label={texts?.dependents_info_collapsible_height ?? 'Estatura'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                placeholder={texts?.edit_dependent_placeholder_height ?? 'Ej:1,50'}
                                required
                                numeric
                                type="text"
                            />
                            <ValidaComponent keyName={"estatura"} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                name="peso"
                                label={texts?.dependents_info_collapsible_weight ?? 'Peso'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                placeholder={texts?.edit_dependent_placeholder_weight ?? 'Ej: 50 kilos'}
                                required
                                numeric
                                type="text"
                            />

                            <ValidaComponent keyName={"peso"} />
                        </div>

                        <div className="col-12">
                            <div className={`${styleForm.btn_activar}`}>
                                <label htmlFor="need" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                    {texts?.edit_dependent_checkbox_option_three ?? '¿Es mayor de 18 años y estudiante a tiempo completo?'}
                                </label>
                                <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                    <input className="form-check-input" type="checkbox" id="need" name={"addDependant"} onChange={handleChecks} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default EditarInfoDependientePage;