/* eslint-disable eqeqeq */
import Axios from "axios";
import { FEMALE_BENEFITS, useCotizador } from "../context/apiContext";
import { useFeeTable } from "./FeeTableHook";
import { useSteps } from "./useSteps";
import { useAuth } from "../context/userContext";
import {saveCotizadorData, sendCotizadorData} from "../api/ApplicationApi";
import { arrayIncludesNumber } from "helpers/toolsHelper";
import { BENEFITS_NEGATIVES } from "helpers/benefitsNegatives";
import {
  getRegularBenefitAdapt,
  getStructuralBenefitAdapt,
} from "helpers/cotizador/adapterHelper";
import {useCalculations} from "./CalculationsHook";

export const useCotizacionSaveAdapter = () => {
  const {
    contextState,
    structuralData,
    regularData,
    selectedBenefitsIds,
    plan,
    isFemale,
    fee,
    thereIsTravel,

    timely,
      rider
  } = useCotizador();
  const { getFullRider: getRider, getFullPremiumAnnual: getPremiumAnnual } =
    useFeeTable();
  const { getBenefitsPerStep } = useSteps();
  const { user } = useAuth();
  const {getDependantFare, getMainFares} = useCalculations();

  const getDependants = () => {
    return contextState.dependants.map((dependant) => ({
      BirthDate: dependant.birthday,
      Country: dependant.country,
      Gender: dependant.gender == 1 ? "m" : "f",
      LastName: dependant.surname,
      Name: dependant.name,
      Province: 0,
      TravelLight: dependant.travel ? "1" : "0",
      Relationship: dependant.componentId == 15 ? 1 : 2,
      Premium: getDependantFare(dependant, false)[timely].toFixed(2),
      TravelLightAmount: getDependantFare(dependant)?.rider.toFixed(2) ?? "0.00"
    }));
  };

  const getOwner = () => {
    return {
      BirthDate: contextState.birthday,
      Country: contextState.country,
      Email: contextState.email,
      Gender: contextState.gender == 1 ? "m" : "f",
      LastName: contextState.surname,
      Name: contextState.name,
      Province: "0",
      TravelLight: contextState.travel ? "1" : "0",
      Relationship: 0,
      Premium: getMainFares(false)[timely].toFixed(2),
      TravelLightAmount: getMainFares()?.rider.toFixed(2) ?? "0.00"
    };
  };

  const getStructuralBenefits = () => {
    return structuralData
      .map((benefit) => {
        if (getBenefitsPerStep().includes(benefit["factorId.nameFactor"])) {
          if (
            arrayIncludesNumber(Object.values(selectedBenefitsIds), benefit.id)
          )
            return getStructuralBenefitAdapt(contextState, benefit);
        } else {
          if (
            arrayIncludesNumber(
              BENEFITS_NEGATIVES.map((i) => i.id),
              benefit.id
            )
          ) {
            return getStructuralBenefitAdapt(contextState, benefit);
          }
        }

        return false;
      })
      .filter((i) => !!i);
  };

  const getRegularBenefits = () => {
    return regularData
      .map((benefit) => {
        if (FEMALE_BENEFITS.includes(benefit["factorId.nameFactor"])){
          return false;
        }
        if (getBenefitsPerStep().includes(benefit["factorId.nameFactor"])) {
          if (
            arrayIncludesNumber(Object.values(selectedBenefitsIds), benefit.id)
          )
            return getRegularBenefitAdapt(contextState, benefit);
        } else {
          if (
            arrayIncludesNumber(
              BENEFITS_NEGATIVES.map((i) => i.id),
              benefit.id
            )
          ) {
            return getRegularBenefitAdapt(contextState, benefit);
          }
        }

        return false;
      })
      .filter((i) => !!i);
  };

  const getMaternityBenefits = () => {
    const mapMaternityBenefits = (benefit) => {
      const isMaternityBenefitAndIsApproved =
        arrayIncludesNumber(Object.values(selectedBenefitsIds), benefit.id) &&
        FEMALE_BENEFITS.includes(benefit["factorId.nameFactor"]) &&
        isFemale()

      if (isMaternityBenefitAndIsApproved)
        return getRegularBenefitAdapt(contextState, benefit);

      return false;
    };

    return regularData
      .filter((i) => getBenefitsPerStep().includes(i["factorId.nameFactor"]))
      .map(mapMaternityBenefits)
      .filter((i) => !!i);
  };

  const getOrganTransplant = () => {
    return [
      {
        BeginDate: contextState.coverage,
        EndDate: "",
        Description: "Organ and tissue transplant",
        Deductible: "Maximum Coverage",
        PortalType: "max_coverage",
        CodeFactor: "ORGRIDE",
        DescriptionOption: contextState.organRiderName,
        IdOption: contextState.organId?.toString?.(), //no existe todavia
        Factor: contextState.organRider,
        Status: "A"
      },
    ];
  };

  const getPaymentInformation = () => {
    const fee_ = fee?.data?.data?.[0]?.fee;
    return {
      BillMode: {
        annual: 1,
        semiannual: 2,
        quarterly: 3,
        monthly: 4,
      }[contextState.timely],
      PremiumAmount: Number(getPremiumAnnual()["annual"]).toString(),
      TravelLightAmount: getRider()?.annual?.toFixed(2) ?? "0.00",
      Fee: Number(fee_ ?? 0)?.toFixed(2)?.toString() ?? "0.00",
    };
  };

  const getData = async (extra = {}) => {
    const {
      data: { ip },
    } = await Axios("https://api.ipify.org?format=json", { method: "GET" });
    const Source = localStorage.getItem("source");


    extra["OptionOrganTrasplant"] = 0;
    extra["OptionTravelLight"] = 0;

    if (thereIsTravel()) {
      extra["OptionTravelLight"] = rider.data['data']?.[0]?.['id'];
    }
    if (contextState.organ) {
      extra["OptionOrganTrasplant"] = contextState.organId;
    }


    const attributes = {
      AgentId: user.agentId.toString(),
      UserId: user.userId.toString(),
      EffectiveDate: contextState.coverage,
      OptionId: !!plan?.data?.data?.length ? plan?.data?.data[0]?.id : -1,
      Owner: getOwner(),
      Dependents: getDependants(),
      StructuralBenefits: getStructuralBenefits(),
      RegularBenefits: getRegularBenefits(),
      MaternityBenefits: getMaternityBenefits(),
      OrganTrasplant: contextState.organ ? getOrganTransplant() : [],
      PaymentInformation: getPaymentInformation(),
      Plan: "my_vip_care",
      TypeApplication: 1,
      IpAddress: ip,
      Source,
      ...extra
    };

    if (user?.agentNumber) {
      attributes['AgentNumber'] = user.agentNumber.toString();
    }
    return attributes;
  };

  const createApplicationSave = async (QuoteIridianId) => {
    const { data } = await saveCotizadorData(await getData({QuoteIridianId}));
    return data;
  };

  return {
    getData,
    createApplicationSave
  };
};
