import { useCotizador } from 'context/apiContext';
import { useEffect, useState } from 'react'


/**
 * 
 * @param {string[]|string} complementBenefitName 
 * @returns 
 */
export const useBenefitComplement = (complementBenefitName) => {
    const [benefit, setBenefit] = useState(complementBenefitName);
    const {
        selectedBenefits,
        calculateSelectedItem,
        getAllBenefits
    } = useCotizador();

    useEffect(() => {
      setBenefit(complementBenefitName);
    }, [complementBenefitName])
    

    const updateComplement = (benefitName = null) => {
        if (!benefitName) benefitName = benefit;
        if (selectedBenefits[benefitName]) return;
        const benefitObject = getAllBenefits().find(i => i["factorId.nameFactor"] === benefitName);
        calculateSelectedItem(benefitObject, getAllBenefits());
    }

    const updateComplements = () => {
        if (typeof benefit == 'string' ) {
            updateComplement();
            return
        }
        for(const _benefit of benefit) {
            updateComplement(_benefit);
        }
    }

  return {
    updateComplement,
    updateComplements
};
}
