import React, { Fragment, useEffect, useContext } from "react";
import { TextsContext } from "../../../context/textsContext";
import Picture from "../../../components/PictureComponent";
import { TitleH2Component } from "../../../components/TitleH2Component";
import Stylegeneral from '../../../scss/general.module.scss'
import style from '../../../scss/InfoSolicitudo.module.scss';
import CollapsibleIndependienteComponent from "./components/CollapsibleIndependienteComponent";
import styleText from '../../../scss/text.module.scss';
const InfoDependientesPage = () => {
    const { texts, verifyCurrentLanguage } = useContext(TextsContext);
    const datosIndependientes = Array.from({ length: 3 });

    useEffect(() => {
        verifyCurrentLanguage();
    }, []);

    return (
        <Fragment>
            <section>
                <div className="container d-flex justify-content-center  ">
                    <div className={`${style.titulos_principales} pt-3 pb-5`}>
                        <div className="d-flex align-items-center ">
                            <Picture src="/img/independiente.png" alt="VUMI" styleGroup={`${style.logosimg} w-auto mb-0 me-2`} />
                            <TitleH2Component styleGroup={`raleway_bold color-azul2 lh_30   mb-0 ${Stylegeneral.titulo} `}>
                                {texts?.dependents_info_title ?? 'Información de Dependientes'}
                            </TitleH2Component>
                        </div>
                    </div>
                </div>
            </section>

           { /*<section>
                <div className={`container`}>
                    <div className={`  mb-3`}>
                        <span className={`raleway_bold color_primary  ${styleText.titulo_informativo}`}>
                            {texts?.dependents_info_subtitle ?? 'Cobertura adicional'}
                        </span>
                    </div>
                    <p className={`raleway color_primary fw_400 ${styleText.titulo_informativo}`}>
                        {texts?.dependents_info_paragraph ?? 'Cónyuge, hijos naturales, hijastros o niños legalmente adoptados por el solicitante principal o los niños de quienes el solicitante principal ha sido legalmente nombrado guardián (los hijos deben ser solteros). Si un dependiente es mayor de 18 años y estudiante a tiempo completo (mínimo de doce (12) créditos por semestre) deberá adjuntar una copia del certificado o comprobante del centro de estudios como evidencia.'}
                    </p>
                </div>
    </section>+*/}
            <section>
                <div className="container pb-5">
                    <div className="row gy-3 ">
                        {datosIndependientes.map((item, index) => {
                            return (
                                <div className="col-12 col-xl-6 offset-xl-3">
                                    <CollapsibleIndependienteComponent key={index} />
                                </div>

                            )
                        })}
                    </div>

                </div>
            </section>
        </Fragment>
    )
}

export default InfoDependientesPage;