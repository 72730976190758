import "./App.css";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/scss/generalClass.scss';
import '../src/scss/fonts.scss';
import "./global.scss";

import { createBrowserRouter, RouterProvider} from "react-router-dom";
import { Fragment } from "react";
import { AppRoutes } from "./routes/AppRoutes";

function App() {
	const router = createBrowserRouter(AppRoutes);

	return (
		<Fragment>
			<RouterProvider router={router} />
		</Fragment>
	);
}

export default App;
