import React, { useContext } from "react";
import Stylegeneral from "scss/general.module.scss";
import styleForm from "scss/form.module.scss";
import { TextsContext } from "context/textsContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { useApplicationInput } from "../../hooks/ApplicationInputHook";

export const SelectAppComponent = (props) => {
  const { texts } = useContext(TextsContext);
  const {name, placeholder, options, label, isMobileDevice, tooltip, required = false, disabled = false} = props;
  const {value, handleOnChange} = useApplicationInput(props);
  return (
    <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
      <div
        className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}
      >
        <label
          htmlFor={name}
          className={`${styleForm.labels} ${Stylegeneral.contentLabel} raleway color_primary fw_700`}
        >{required && <span className={styleForm.punto}>*</span>}
          {label}
        </label>
        <OverlayTrigger
          key={isMobileDevice ? "left" : "top"}
          placement={isMobileDevice ? "left" : "top"}
          overlay={
            <Tooltip id="tooltip-top" className="custom-tooltip">
              {tooltip}
            </Tooltip>
          }
        >
          <Icon
            icon={"mdi:information-outline"}
            className={`color_gray_5 ${styleForm.iconselect}`}
          />
        </OverlayTrigger>
      </div>

      <select
        className={`${styleForm.input_with_line} raleway fw_400`}
        placeholder={placeholder}
        name={name}
        onChange={handleOnChange}
        value={value}
        required={required}
        disabled={disabled}
      >
        <option value={0}>{texts?.general_select ?? 'Select'}</option>
        {React.Children.toArray(
          Object.entries(options ?? {}).map(([key, value]) => (
            <option value={value}>{key}</option>
          ))
        )}
      </select>
    </div>


  );
};
