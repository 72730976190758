import React, { Fragment, useContext, useEffect } from "react";
import { TextsContext } from "../../context/textsContext";
import style from '../../scss/login.module.scss';
import Picture from "../../components/PictureComponent";
import { TitleH2Component } from "../../components/TitleH2Component";
import { TitleH6Component } from "../../components/TitleH6Component";
import styleForm from '../../scss/form.module.scss';
import Stylegeneral from '../../scss/general.module.scss';
import StyleBtn from "../../scss/btn.module.scss";
import SelecctorIdiomaComponent from "./components/SelecctorIdiomaComponent";
import { useNavigate } from "react-router-dom";
const LoginAppPage = () => {
    const { texts, verifyCurrentLanguage } = useContext(TextsContext);
    const navigate = useNavigate();
    useEffect(() => {
        verifyCurrentLanguage();
    }, []);

    return (
        <Fragment>
            <div style={{ backgroundImage: 'url(/img/bgloginapp.jpg)', backgroundSize: 'cover', width: 'auto', height: '100vh' }}>

                <div className={`${style.contenidoLoginApp}`}>
                    <div className="container">
                        <div className={`${style.formlogin} `} >
                            <div className={`${style.loginApp} `}>
                                <div className="d-flex justify-content-end pb-2">
                                    <SelecctorIdiomaComponent />
                                </div>

                                <div className="d-flex justify-content-center mb-0 mb-md-4">
                                    <Picture src="/img/Logoh.png" alt="VUMI" styleGroup={`${style.logosimg} w-auto mb-3`} />
                                </div>

                                <div className="text-center mb-4">
                                    <TitleH2Component styleGroup={`raleway  mb-0 pb-3  mb-0 color_primary_2 `}>
                                        <span className="raleway_bold ">{texts?.login_two_title_one ?? 'Access'}</span> {texts?.login_two_title_two ?? 'your application'}
                                    </TitleH2Component>

                                    <TitleH6Component styleGroup={`mb-0  lh_20 color_primary`}>
                                        <span className="raleway">{texts?.login_two_phrase_one ?? 'To continue with a request, enter your'}</span>  <span className="raleway_bold"> {texts?.login_two_phrase_two ?? 'application number and your email address.'}</span>
                                    </TitleH6Component>
                                </div>
                                <form action="#" className="mb-4">
                                    <div className={`${styleForm.form_group} ${styleForm.form_h} mb-4`}>
                                        <label htmlFor="name" className={` ${`${styleForm.labelLogin}`} raleway_bold  color_primary_2`}>
                                            {texts?.login_two_application_number ?? 'Application number'}
                                        </label>
                                        <input type="text" className={`${styleForm.input_with_line}`} placeholder={'123456'} name={"name"} required />
                                    </div>
                                    <div className={`${styleForm.form_group} ${styleForm.form_h} mb-5`}>
                                        <label htmlFor="correo" className={` ${`${styleForm.labelLogin}`} raleway_bold  color_primary_2  `}>
                                            {texts?.login_two_email ?? 'Email address'}
                                        </label>
                                        <input type="email" className={`${styleForm.input_with_line} `} placeholder={'Ingresar Email'} name={"correo"} required />
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <button type="button" onClick={()=>{navigate('/info-solicitud')}} className={`Poppins fw_500 color_primary d-flex justify-content-center  ${StyleBtn.btn} ${style.wpbtnLogin}`} >{texts?.login_two_continue_button ?? 'Continue'}</button>
                                    </div>
                                </form>
                                <TitleH6Component styleGroup={`mb-0  text-center Poppins color_primary`}>
                                    {texts?.login_two_final_phrase ?? 'VUMI® 2023 - All rights reserved'}
                                </TitleH6Component>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default LoginAppPage;