import React, { Fragment, useState, useEffect, useContext } from 'react'
import { TextsContext } from '../../../context/textsContext';

import Stylegeneral from '../../../scss/general.module.scss';
import styleForm from '../../../scss/form.module.scss';
import StylegeneralMod from '../../../scss/general.module.scss';

import Picture from '../../../components/PictureComponent';
import { TitleH2Component } from '../../../components/TitleH2Component';
import { ParagraphComponent } from '../../../components/ParagraphComponent';
import ValidaComponent from '../../../components/ValidaComponent';
import { TitleH3Component } from '../../../components/TitleH3Component';
import { useApplication } from '../../../context/applicationContext';
import { InputTextComponent } from 'components/application/InputTextComponent';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import style from '../../../scss/informacionDePago.module.scss';
const InformacionDePagoPages = () => {
	const { texts, verifyCurrentLanguage } = useContext(TextsContext);
	const [isMobileDevice, setIsMobileDevice] = useState(false);
	const {
		applicationState,
		handleOnChange
	} = useApplication();

	const handleTextInput = (e) => {
		console.log("->>> ", e.target.value);
		// e.preventDefault();
	}

	// const handleChecks = (e) => {
	// 	handleOnChange({ target: { value: e.target.checked, name: e.target.name } })
	// }

	const grid_pay = [
		{ name: texts?.payment_info_beneficiary ?? 'Beneficiario', text: ' VUMI® Group, I.I.' },
		{ name: texts?.payment_info_bank ?? 'Banco', text: 'Texas Capital Bank N.A' },
		{ name: texts?.payment_info_account ?? 'Número de cuenta', text: 1511025379 },
		{ name: texts?.payment_info_aba ?? 'ABA', text: 111017979 },
		{ name: texts?.payment_info_address ?? 'Dirección', text: 'Richardson, Texas 75082' },
		{ name: texts?.payment_info_code ?? 'Código SWIFT', text: 'TXCBUS4460' }
	]

	function esImpar(numero) {
		return numero % 2 !== 0;
	}

	const [getCheck, setGetCheck] = useState([
		{ id: 1, text: texts?.payment_info_check ?? 'Cheque', checked: true },
		{ id: 2, text: texts?.payment_info_transfer ?? 'Transferencia bancaria', checked: true },
		{ id: 3, text: texts?.payment_info_credit_card ?? 'Tarjeta de crédito', checked: true },
		{ id: 3, text: texts?.payment_info_electronic_check ?? 'Cheque electrónico', checked: true },
		{ id: 3, text: texts?.payment_info_pay_later ?? 'Pagar una vez aprobada la solicitud', checked: true },
	]);

	const handleCheckboxChange = (id) => {
		// Copia el arreglo de elementos y actualiza el estado de la casilla de verificación
		const updatedItems = getCheck.map(item => {
			if (item.id === id) {
				return { ...item, checked: !item.checked };
			}
			return item;
		});
		setGetCheck(updatedItems);
	};

	useEffect(() => {
		verifyCurrentLanguage();
	}, []);

	return (
		<Fragment>
			<section>
				<div className="container">
					<div className=" d-flex justify-content-center  ">
						<div className="d-flex align-items-center py-3">
							<Picture src="/img/pay.png" alt="VUMI" styleGroup={`${Stylegeneral.logosimg} w-auto mb-0 me-2`} />
							<TitleH2Component styleGroup={`raleway_bold color-azul2 lh_30   mb-0 ${Stylegeneral.titulo} `}>
								{texts?.payment_info_title ?? 'Información de Pago'}
							</TitleH2Component>
						</div>
					</div>
				</div>
			</section>
			<section>
				<div className="container pb-4">
					<div className="nota bg_verde_4 mx-1">
						<ParagraphComponent styleGroup="fw_400 lh_24 color_black mb-0 p-3">
							{texts?.payment_info_advertisement ?? 'El monto de la prima puede cambiar después de la revisión y evaluación de VUMI®'}
						</ParagraphComponent>
					</div>
				</div>
			</section>

			<section>
				<div className="container">
					<div className="row gy-4 pb-4">
						<div className={`col-12    ${Stylegeneral.colform}`}>
							<div className={`${styleForm.form_group} ${styleForm.form_h}`}>
								<div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
									<label htmlFor="poliza" className={`${styleForm.labels} ${Stylegeneral.contentLabel} raleway color_primary fw_700`}>
										{texts?.payment_info_form_field_one ?? 'Modalidad de la poliza:'}
									</label>
									<OverlayTrigger
										key={isMobileDevice ? 'left' : 'top'}
										placement={isMobileDevice ? 'left' : 'top'}
										overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{"Geographic area where the policy´s benefits apply"}</Tooltip>}
									>
										<Icon icon={'mdi:information-outline'} className={`color_gray_5 ${styleForm.iconselect}`} />
									</OverlayTrigger>
								</div>
								<input type="text"
									className={` w-100 ${styleForm.input_with_line}`} onKeyDown={handleTextInput}
									placeholder={texts?.payment_info_form_placeholder_one ?? 'Anual'}
									name={"poliza"}
									value={applicationState.poliza}
									onChange={handleOnChange} readonly />
							</div>
							<ValidaComponent keyName={"poliza"} />
						</div>

						<div className={`col-12    ${Stylegeneral.colform}`}>
							<div className={`${styleForm.form_group} ${styleForm.form_h}`}>
								<div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
									<label htmlFor="poliza" className={`${styleForm.labels} ${Stylegeneral.contentLabel} raleway color_primary fw_700`}>
										{texts?.payment_info_form_field_two ?? 'Modalidad de la poliza:'}
									</label>
									<OverlayTrigger
										key={isMobileDevice ? 'left' : 'top'}
										placement={isMobileDevice ? 'left' : 'top'}
										overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{"Geographic area where the policy´s benefits apply"}</Tooltip>}
									>
										<Icon icon={'mdi:information-outline'} className={`color_gray_5 ${styleForm.iconselect}`} />
									</OverlayTrigger>
								</div>
								<input type="number"
									className={`w-100 ${styleForm.input_with_line}`} onKeyDown={handleTextInput}
									placeholder={texts?.payment_info_form_placeholder_two ?? '$23,897.00'} name={"prima"}
									value={applicationState.prima}
									onChange={handleOnChange} disabled />
								<ParagraphComponent styleGroup="fw_400 lh_24 color_primary_2 mb-0">
									{texts?.payment_info_phrase ?? 'Esta cantidad no incluye la tarifa administrativa'}
								</ParagraphComponent>
							</div>
							<ValidaComponent keyName={"prima"} />
						</div>

						<div className={`col-12    ${Stylegeneral.colform}`}>
							<div className={`${styleForm.form_group} ${styleForm.form_h}`}>
								<div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
									<label htmlFor="poliza" className={`${styleForm.labels} ${Stylegeneral.contentLabel} raleway color_primary fw_700`}>
										{texts?.payment_info_form_field_three ?? 'Modalidad de la poliza:'}
									</label>
									<OverlayTrigger
										key={isMobileDevice ? 'left' : 'top'}
										placement={isMobileDevice ? 'left' : 'top'}
										overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{"Geographic area where the policy´s benefits apply"}</Tooltip>}
									>
										<Icon icon={'mdi:information-outline'} className={`color_gray_5 ${styleForm.iconselect}`} />
									</OverlayTrigger>
								</div>
								<input type="number"
									className={` w-100 ${styleForm.input_with_line} color_primary`} onKeyDown={handleTextInput}
									placeholder={texts?.payment_info_form_placeholder_three ?? '$75.00'} name={"tarifa"}
									value={applicationState.tarifa}
									onChange={handleOnChange} disabled />
							</div>
							<ValidaComponent keyName={"tarifa"} />
						</div>

						<div className={`col-12   ${Stylegeneral.colform}`}>
							<div className={`${styleForm.form_group} ${styleForm.form_h}`}>
								<div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
									<label htmlFor="poliza" className={`${styleForm.labels} ${Stylegeneral.contentLabel} raleway color_primary fw_700`}>
										{texts?.payment_info_form_field_four ?? 'Modalidad de la poliza:'}
									</label>
									<OverlayTrigger
										key={isMobileDevice ? 'left' : 'top'}
										placement={isMobileDevice ? 'left' : 'top'}
										overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{"Geographic area where the policy´s benefits apply"}</Tooltip>}
									>
										<Icon icon={'mdi:information-outline'} className={`color_gray_5 ${styleForm.iconselect}`} />
									</OverlayTrigger>
								</div>
								<input type="number"
									className={`w-100 ${styleForm.input_with_line} color_primary`} onKeyDown={handleTextInput}
									placeholder={texts?.payment_info_form_placeholder_four ?? '$23,897.00'} name={"total"}
									value={applicationState.total}
									onChange={handleOnChange} disabled />
							</div>
							<ValidaComponent keyName={"total"} />
						</div>

						<div className={`col-12   ${Stylegeneral.colform}`}>
							<div className={`${styleForm.form_group} ${styleForm.form_h}`}>
								<div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
									<label htmlFor="poliza" className={`${styleForm.labels} ${Stylegeneral.contentLabel} raleway color_primary fw_700`}>
										{texts?.payment_info_form_field_five ?? 'Modalidad de la poliza:'}
									</label>
									<OverlayTrigger
										key={isMobileDevice ? 'left' : 'top'}
										placement={isMobileDevice ? 'left' : 'top'}
										overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{"Geographic area where the policy´s benefits apply"}</Tooltip>}
									>
										<Icon icon={'mdi:information-outline'} className={`color_gray_5 ${styleForm.iconselect}`} />
									</OverlayTrigger>
								</div>
								<input type="number"
									className={` w-100 ${styleForm.input_with_line} color_primary`} onKeyDown={handleTextInput}
									placeholder={texts?.payment_info_form_placeholder_five ?? '$23,897.00'} name={"Desglose"}
									value={applicationState.Desglose}
									onChange={handleOnChange} disabled />
							</div>
							<ValidaComponent keyName={"Desglose"} />
						</div>
					</div>
				</div>
			</section>

			<section>
				<div className="container">
					<div className="d-flex justify-content-start align-items-center my-2 my-md-4 mx-1">
						<div className="me-3">
							<Picture src="/img/pay.png" alt="VUMI" />
						</div>
						<TitleH3Component styleGroup={`raleway fw_700 color-azul2 pb-0 my-3 ${Stylegeneral.titulo}`}>
							{texts?.payment_info_subtitle ?? 'Método de pago'}
						</TitleH3Component>
					</div>
				</div>
			</section>

			<section>
				<div className="container">
					<div className="mx-1 w_fit">
						<ParagraphComponent styleGroup="fw_400 lh_24 color_black my-3">
							{texts?.payment_info_payment_phrase ?? 'Para pagos realizados por transferencia bancaria o cheque, use la siguiente información:'}
						</ParagraphComponent>
					</div>
				</div>
			</section>

			<section>
				<div className="container">
					<div className={`${style.rowMetodoPago}`}>
						<div className={`${style.col1}`}>
							<ul className='list-unstyled mx-1 mt-4'>
								{getCheck.map(item => (
									<li key={item.id} className='type_check'>
										<label className='checkbox blue d-flex align-items-center color_primary me-0 mb-3'>
											<input
												type="checkbox"
												className='me-3'
												checked={item.checked}
												onChange={() => handleCheckboxChange(item.id)}
											/>
											<span class='indicator'></span>
											{item.text}</label>
									</li>
								))}
							</ul>
						</div>

						<div className={`${style.col2}`}>

							{grid_pay.map((item, idx) => (
								<div className={`mx-1 ${StylegeneralMod.grid_container} 
						${esImpar(idx) ? '' : 'bg_azul5'}`} key={idx} >
									<div className={`${StylegeneralMod.grid_item} `}>
										<TitleH3Component styleGroup={`raleway fw_700 color_primary mb-1 ps-2`}>
											{item.name}
										</TitleH3Component>
									</div>
									<div className={` ${StylegeneralMod.align_right} me-`}>
										<ParagraphComponent styleGroup={`fw_400 color_gray_1 my-1 w-100`}>
											{item.text}
										</ParagraphComponent>
									</div>
								</div>
							))}

						</div>
					</div>
				</div>
			</section>
		</Fragment>
	)
}

export default InformacionDePagoPages