import React, { Fragment, useEffect, useContext } from "react";
import { TextsContext } from "context/textsContext";
import styleForm from '../../../scss/form.module.scss';
import { ParagraphComponent } from "../../../components/ParagraphComponent";
import StyleTex from '../../../scss/text.module.scss'
import { SelectAppComponent } from "../../../components/application/SelectAppComponent";
import ValidaComponent, { APPLICATION } from "../../../components/ValidaComponent";
import Stylegeneral from '../../../scss/general.module.scss'
import { InputTextComponent } from "../../../components/application/InputTextComponent";
import GestorArchivosComponent from "../Reconocimientos/components/GestorArchivosComponent";
const MAIN_APPLICANT = "mainApplicant";
const Registro1Page = () => {
    const { texts, verifyCurrentLanguage } = useContext(TextsContext);
    useEffect(() => {
		verifyCurrentLanguage();
	}, []);
    
    return (
        <Fragment>

            <section>
                <div className="container">
                    <div className={`${styleForm.btn_activar}`}>
                        <label className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                            {texts?.page_one_checkbox_one ?? '¿Tiene usted un seguro de salud o plan de cobertura médica con alguna otra compañía?'}
                        </label>
                        <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                            <input className="form-check-input" type="checkbox" id="seguro" name={"addDependant"} />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container">
                    <ParagraphComponent styleGroup={'mb-0 py-3 raleway color_primary fw_700'}>
                        <span className={`${StyleTex.titulosFormulario} `}>
                            {texts?.page_one_form_title ?? 'Datos de la otra póliza del solicitante'}
                        </span>
                    </ParagraphComponent>
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="row gy-4">
                        <div className={`col-12 col-md-6 col-lg-4 ${Stylegeneral.colform}`}>
                            <SelectAppComponent
                                name="mcompania"
                                label={texts?.page_one_form_field_company ?? "Nombre de la compañía"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                placeholder='Ej:Colombia'
                                type='text'
                                options={[]}
                            />

                            <ValidaComponent phase={APPLICATION} section={MAIN_APPLICANT} keyName={"mcompania"} />
                        </div>

                        <div className={`col-12 col-md-6 col-lg-4 ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={MAIN_APPLICANT}
                                name="plan"
                                label={texts?.page_one_form_field_plan ?? "Plan"}
                                placeholder={texts?.page_one_form_placeholder_plan ?? "Ej"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                type='text'
                            />
                            <ValidaComponent keyName={"plan"} phase={APPLICATION} section={MAIN_APPLICANT} />
                        </div>

                        <div className={`col-12 col-md-6 col-lg-4 ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={MAIN_APPLICANT}
                                name="ntelefono"
                                label={texts?.page_one_form_field_phone ?? "Número de teléfono"}
                                placeholder={texts?.page_one_form_placeholder_phone ?? "Ej:3452344"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                type='text'
                            />
                            <ValidaComponent keyName={"ntelefono"} phase={APPLICATION} section={MAIN_APPLICANT} />
                        </div>

                        <div className={`col-12 col-md-6 col-lg-4 ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={MAIN_APPLICANT}
                                name="valordeducible"
                                label={texts?.page_one_form_field_deductible ?? "Valor del deducible"}
                                placeholder={texts?.page_one_form_placeholder_deductible ?? "Ej:120000"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                type='number'
                            />
                            <ValidaComponent keyName={"valordeducible"} phase={APPLICATION} section={MAIN_APPLICANT} />
                        </div>
                        <div className={`col-12 col-md-6 col-lg-4 ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={MAIN_APPLICANT}
                                name="numeropoliza"
                                label={texts?.page_one_form_field_policy ?? "Número de poliza"}
                                placeholder={texts?.page_one_form_placeholder_policy ?? "Ej:132342323"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                type='number'
                            />
                            <ValidaComponent keyName={"numeropoliza"} phase={APPLICATION} section={MAIN_APPLICANT} />
                        </div>

                        <div className={`col-12 col-md-6 col-lg-4 ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={MAIN_APPLICANT}
                                name="numeropoliza"
                                label={texts?.page_one_form_field_policy ?? "Número de poliza"}
                                placeholder={texts?.page_one_form_placeholder_policy ?? "Ej:132342323"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                type='number'
                            />
                            <ValidaComponent keyName={"numeropoliza"} phase={APPLICATION} section={MAIN_APPLICANT} />
                        </div>

                        <div className={`col-12 col-md-6 col-lg-4 ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.btn_activar}`}>
                                <label htmlFor="compania" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                    {texts?.page_one_checkbox_two ?? '¿Tiene la intención de mantener la cobertura médica con la otra compañía?'}
                                </label>
                                <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                    <input className="form-check-input" type="checkbox" id="compania" name={"addDependant"} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-3">
                <div className="container">

                    <ParagraphComponent styleGroup={`${StyleTex.text_informacion} mb-0 pb-2 raleway color_primary fw_400`}>
                        {texts?.page_one_file_phrase ?? 'Si desea que se elimine el período de espera, por favor incluya una copia del certificado de cobertura y recibo de pago de los últimos 12 meses de la cobertura previa.'}
                    </ParagraphComponent>

                    <div className="row">
                        <div className=" col-md-6 col-lg-5 col-12">
                            <GestorArchivosComponent />
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-3">
                <div className="container">
                    <div className="row gy-4">
                        <div className={`col-12 col-md-6 col-lg-4 ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.btn_activar}`}>
                                <label htmlFor="cmedica" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                    {texts?.page_one_checkbox_three ?? '¿Ha tenido cobertura médica con VUMI® o cualquiera de sus afiliadas?'}
                                </label>
                                <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                    <input className="form-check-input" type="checkbox" id="cmedica" name={"addDependant"} />
                                </div>
                            </div>
                        </div>

                        <div className={`col-12 col-md-6 col-lg-4 ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={MAIN_APPLICANT}
                                name="numeropoliza"
                                label={texts?.page_one_form_field_policy ?? "Número de poliza"}
                                placeholder={texts?.page_one_form_placeholder_policy ?? "Ej:132342323"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                type='number'
                            />
                            <ValidaComponent keyName={"numeropoliza"} phase={APPLICATION} section={MAIN_APPLICANT} />
                        </div>

                        <div className={`col-12 col-md-6 col-lg-4 ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.btn_activar}`}>
                                <label htmlFor="solicitud" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                    {texts?.page_one_checkbox_four ?? '¿Ha sido alguna solicitud de seguro de salud o de vida rechazada, aceptada sujeta a restricciones o a una prima mayor que las tarifas estándar de la aseguradora para alguno de los solicitantes?'}
                                </label>
                                <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                    <input className="form-check-input" type="checkbox" id="solicitud" name={"addDependant"} />
                                </div>
                            </div>
                        </div>

                        <div className={`col-12 col-md-6 col-lg-4 ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={MAIN_APPLICANT}
                                name="explicacion"
                                label={texts?.page_one_form_field_explanation ?? "Explicación"}
                                placeholder={texts?.page_one_form_placeholder_explanation ?? "Ej:Explicación"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                type='text'
                            />
                            <ValidaComponent keyName={"explicacio"} phase={APPLICATION} section={MAIN_APPLICANT} />
                        </div>

                    </div>
                </div>
            </section>

        </Fragment>
    )
}
export default Registro1Page;