import React, { Fragment, useEffect, useState, useContext } from "react";
import { TextsContext } from '../../context/textsContext';
import { TitleH1Component } from "../../components/TitleH1Component";
import Stylegeneral from '../../scss/general.module.scss';
import styleForm from '../../scss/form.module.scss';
import { Icon } from "@iconify/react";
import { TitleH2Component } from "../../components/TitleH2Component";
import SelectBenefitsComponent from "../../components/SelectBenefitsComponent";
import { useCotizador } from "../../context/apiContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ValidaComponent from "components/ValidaComponent";
import { boolIsTrue } from "../../helpers/toolsHelper";
import styleTexto from '../../scss/text.module.scss';
const Step4Pages = () => {
    const { texts, verifyCurrentLanguage } = useContext(TextsContext);
    const { benefits, handleOnChange: handleOnChange_, organ, organRider, organRiderQuery, addDependant } = useCotizador();
    const [isOpen, setIsOpen] = useState(false);
    const [isMobileDevice, setIsMobileDevice] = useState(false);
    useEffect(() => {

        verifyCurrentLanguage();
        function handleResize() {
            setIsMobileDevice(window.innerWidth <= 768);
        }

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleChecks = (e) => {
        handleOnChange({ target: { value: e.target.checked, name: e.target.name } })
    }

    const handleOnChange = (e) => {
        handleOnChange_(e);
        let text = "";
        let id = "";
        if (e?.target?.options?.length) {
            text = e.target?.options[e.target.selectedIndex]?.dataset?.description;
            id = e.target?.options[e.target.selectedIndex]?.dataset?.id;
        }
        handleOnChange_({ target: { value: text, name: "organRiderName" } });
        handleOnChange_({ target: { value: id, name: "organId" } });
    }


    useEffect(() => {
        verifyCurrentLanguage();
    }, []);


    const extractNumberFromString = (inputString) => {
        if (!inputString) return NaN;
        const stringWithoutCommas = String(inputString).replace(/,/g, "");
        const match = stringWithoutCommas.match(/[\d.]+/);

        if (match) {
            const numberString = match[0];
            return parseFloat(numberString);
        }

        return NaN;
    };

    const getOrganList = () => {
        if (!(typeof organRiderQuery?.data?.data?.sort === 'function')) return [];
        return organRiderQuery?.data?.data?.sort((a, b) => extractNumberFromString(b?.description) - extractNumberFromString(a?.description));
    }

    const paragraphgeneral_header_title = texts?.general_header_title;
    const general_header_title_marca = paragraphgeneral_header_title?.replace?.("®", `<span>&reg;</span>`) ?? '';

    return (
        <Fragment>
            <section id="sectionscroll">
                <div className="container pb-4">
                    <div className="d-block d-lg-none">
                        <TitleH2Component styleGroup={`raleway fw_400 lh_40 color_secundary mb-0 py-2 ${Stylegeneral.titulo_principal}`}>
                            <p dangerouslySetInnerHTML={{ __html: general_header_title_marca }} className={Stylegeneral.marca_registradaContainer}></p>

                        </TitleH2Component>
                    </div>
                    <TitleH1Component styleGroup={`mb-0 raleway_semibold fw_700  lh_30 color_primary pb-2 ${Stylegeneral.titulo}`}>
                        {texts?.step4_title ?? "Let’s continue customizing based on your exact needs"}
                    </TitleH1Component>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className={`${Stylegeneral.rowForm} row gy-5 pb-5`}>
                        <div className={`col-12 col-md-4 ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                    <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1 `}>
                                        {texts?.step4_form_field_checkup ?? 'Preventive health checkup'}
                                    </label>

                                    <OverlayTrigger
                                        key={isMobileDevice ? 'left' : 'top'}
                                        placement={isMobileDevice ? 'top' : 'top'}
                                        overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{texts?.step4_tooltip_checkup ?? 'Preventive medical examinations conducted by a certified physician and/or an institution providing medical services.'}</Tooltip>}
                                    >
                                        <Icon icon={'mdi:information-outline'} className={` color_gray_5 ${styleForm.iconselect}`} />
                                    </OverlayTrigger>
                                </div>

                                <SelectBenefitsComponent benefits={benefits.regularData} benefit={"Preventive health checkup"} className={`${styleForm.customselectLine}`} />

                            </div>
                        </div>

                        <div className={`col-12 col-md-4 ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                    <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1 `}>
                                        {texts?.step4_form_field_congenital ?? 'Congenital conditions'}
                                    </label>

                                    <OverlayTrigger
                                        key={isMobileDevice ? 'left' : 'top'}
                                        placement={isMobileDevice ? 'top' : 'top'}
                                        overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{texts?.step4_tooltip_congenital ?? 'Any condition, organic disorder, malformation, embryopathy, persistency of embryonic or fetal tissue or structure, which has been acquired during the development of the fetus in utero or during birth, regardless of whether these had been evident before birth, at the time of birth or had manifested later.'}</Tooltip>}
                                    >
                                        <Icon icon={'mdi:information-outline'} className={` color_gray_5 ${styleForm.iconselect}`} />
                                    </OverlayTrigger>

                                </div>

                                <SelectBenefitsComponent benefits={benefits.regularData} benefit={"Congenital conditions"} className={`${styleForm.customselectLine}`} />

                            </div>
                        </div>
                        <div className={`col-12 col-md-4 ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                    <label htmlFor="seclet" className={`${styleForm.labels} ${styleForm.labelstextlarge} raleway  color_primary_2 pe-1 `}>
                                        {texts?.step4_form_field_non_professional_sports ?? 'Injuries during the training or practice of non-professional hazardous hobbies and sports'}
                                    </label>

                                    <OverlayTrigger
                                        key={isMobileDevice ? 'left' : 'left'}
                                        placement={isMobileDevice ? 'left' : 'left'}
                                        overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{texts?.step4_tooltip_non_professional_sports ?? 'This benefit covers medical expenses resulting from injuries caused during the training or practice of non-professional hazardous hobbies and/or sports. These include white-water rafting; mountaineering; diving; rock climbing; skydiving; paragliding; parasailing; mountain biking; motor sports like motorcycles, mopeds, scooters, all-terrain vehicles (ATVs), any two or three wheeled motorized vehicles; flying as a pilot in any aircraft; surfing (waves under 50 ft.)'}</Tooltip>}
                                    >
                                        <Icon icon={'mdi:information-outline'} className={` color_gray_5 ${styleForm.iconselect}`} />
                                    </OverlayTrigger>
                                </div>

                                <SelectBenefitsComponent benefits={benefits.regularData} benefit={"Injuries during the training or practice of non-professional hazardous hobbies and sports"} className={`${styleForm.customselectLine}`} />

                            </div>
                        </div>


                        <div className={`${boolIsTrue(addDependant) ? `col-12 col-md-4 ${Stylegeneral.colform}` : "d-none"}`}>
                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                    <label htmlFor="seclet" className={`${styleForm.labels} ${styleForm.label_tooltip} raleway  color_primary_2 pe-1 `}>
                                        {texts?.step5_form_field_extended_coverage ?? "Free extended coverage for eligible dependents after policyholder’s death"}
                                    </label>
                                    <OverlayTrigger
                                        key={isMobileDevice ? 'left' : 'top'}
                                        placement={isMobileDevice ? 'left' : 'top'}
                                        overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{texts?.step5_tooltip_extended_coverage ?? 'Free coverage for the dependents in the policy, for a periodo f time after the last paid periodo f the policy, in the evento the policyholder should die, as long as the cause of death is the resulto f a condition or accident covered by the policy.'}</Tooltip>}
                                    >
                                        <div className={`${styleForm.iconselect} ms-1`}>
                                            <Icon icon={'mdi:information-outline'} className={` color_gray_5   `} />
                                        </div>
                                    </OverlayTrigger>
                                </div>


                                <SelectBenefitsComponent benefits={benefits.regularData} benefit={"Free extended coverage for eligible dependents after policyholder’s death"} className={`${styleForm.customselectLine} ${styleForm.styleinterno}`} />

                            </div>
                        </div>

                        <div className={`col-12 col-md-4 ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                    <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1 `}>
                                        {texts?.step5_form_field_deductible ?? 'Deductible elimination'}
                                    </label>
                                    <OverlayTrigger
                                        key={isMobileDevice ? 'left' : 'top'}
                                        placement={isMobileDevice ? 'top' : 'top'}
                                        overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{texts?.step5_tooltip_deductible ?? 'Deductible elimination for the first urgent hospital medical treatment that requieres the hospitalization o fan insured who has suffered a serious accident (as defined in this Policy), anywhere in the world, provided that medical care is recieved within the first twenty-four (24) hours after the serious accident. Any subsequent hospitalization or medical service related or not to the serious accident, Will be subject to the selected deductible or coinsurance (if aplicable).'}</Tooltip>}
                                    >
                                        <div className={`${styleForm.iconselect}`}>
                                            <Icon icon={'mdi:information-outline'} className={` color_gray_5   `} />
                                        </div>
                                    </OverlayTrigger>
                                </div>

                                <SelectBenefitsComponent benefits={benefits.regularData} benefit={"Deductible elimination"} className={`${styleForm.customselectLine}`} />

                            </div>
                        </div>
                        <div className={`col-12 col-md-4 ${Stylegeneral.colform}`}>
                            <div className={`w-100 h-100`}>
                                <div className={`${styleForm.form_group} h-100`}>
                                    <div className={`${styleForm.btn_activar} pt-2 `}>
                                        <div className={`d-flex align-items-center mb-2 ${styleForm.con_tootip}`}>
                                            <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1 mb-2`}>
                                                {texts?.step7_form_field_organ ?? 'Organ Transplant'}
                                            </label>
                                            <OverlayTrigger
                                                key="top"
                                                placement="top"
                                                overlay={<Tooltip id="tooltip-top" className="custom-tooltip">
                                                    {texts?.step7_tooltip_medical_procedure ?? 'Medical procedure to transfer an organ, tissue or cells from a living or deceased donor to the recipient, or reimplant it in the same person.'}
                                                </Tooltip>}
                                            >
                                                <Icon icon={'mdi:information-outline'} className={` color_gray_5 ${styleForm.iconselect}`} />
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center h-100 ">
                                        <div className={(organ === "true" || organ === true) ? "w-100 mt-3" : "d-none"}>

                                            <select
                                                name={"organRider"}
                                                className={`${styleForm.customselectLine}`}
                                                onChange={handleOnChange}
                                                value={organRider}
                                                onClick={() => setIsOpen(!isOpen)}
                                                onBlur={() => setIsOpen(false)}
                                                onFocus={() => setIsOpen(false)}
                                            >


                                                <option value={0} disabled={true}>
                                                    {texts?.general_form_field_select ?? 'Select'}
                                                </option>

                                                {getOrganList()
                                                    .map((item) => (
                                                        <option key={item.id} value={item.factor} data-description={item.description} data-id={item.id}>

                                                            <span >
                                                                {item.description}
                                                            </span>

                                                            {item.id === 36 && isOpen &&
                                                                (organRider !== item.factor || isOpen) && (
                                                                    <span></span>
                                                                )}{" "}
                                                        </option>

                                                    ))}


                                            </select>
                                            <div className={`${styleForm.validador}`}>
                                                <ValidaComponent keyName={"organ"} styleGroup={`${styleForm.valida}`} />
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={` ${(organ === "false" || organ === false) ? "w-100 mt-3" : "d-none"}`}>
                            <div className={`${Stylegeneral.cuadro_beneficio} p-2`}>
                                <TitleH2Component styleGroup={`raleway fw_700 color_primary mb-4 text-shadow`}>
                                    {texts?.step11_provider_message_title ?? 'Important provider networks message:'}
                                </TitleH2Component>
                                <div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="acepto" name="acceptNonOrgan"  onChange={handleChecks} />
                                        <label className={`Poppins typecursiva fw_700 color_primary mb-0 ${styleTexto.h3}`} htmlFor="acepto">
                                            {texts?.general_dislcaimer_step4 ?? ' He elegido no optar por el beneficio de trasplante de órganos. Entiendo que mi póliza no ofrecerá ningún beneficio para trasplante de órganos y/o tejidos.'}
                                        </label>
                                    </div>
                                    <div className={`${styleForm.validador}`}>
                                        <ValidaComponent keyName={"acceptNonOrgan"} styleGroup={`${styleForm.valida}`} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}
export default Step4Pages;