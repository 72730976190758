import React from "react";
import { Navigate } from "react-router-dom";
import { useApplicationStep } from "hooks/ApplicationStepManagerHook";

export const ApplicationGuard = ({ element, path }) => {
  const {userShouldntBeHere} = useApplicationStep();

  const userTruePath = userShouldntBeHere();


  return (
    <>
      {userTruePath ? <Navigate to={userTruePath} /> : element}
    </>
  );
};
