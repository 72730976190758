export const useBenefitValidation = (selectedBenefits = []) => {
    const shouldNotAdvance = (currentBenefits = []) => {
        const state = currentBenefits.map(i => !!Object.keys(selectedBenefits).includes(i)).every(i => i === true);

        return !state;
    }

    const getBenefitsThatAreNot = (currentBenefits = []) => {
        const list = currentBenefits.filter(i => !Object.keys(selectedBenefits).includes(i));
        return list;
    }

    return {shouldNotAdvance, getBenefitsThatAreNot};
}