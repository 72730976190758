import Stylegeneral from "../../../scss/general.module.scss";
import {TitleH2Component} from "../../../components/TitleH2Component";
import {TitleH3Component} from "../../../components/TitleH3Component";
import React, {Fragment, useContext} from "react";
import styleTexto from "../../../scss/text.module.scss";
import styleForm from "../../../scss/form.module.scss";
import ValidaComponent from "../../../components/ValidaComponent";
import {TextsContext} from "../../../context/textsContext";
import { equal, notEqual } from "helpers/toolsHelper";

export const ImportantMessage = ({Providers = "", mostarPais = "", handleChecks = () => {}, limitedCover = false, geoId = 0, providerId = 0, antiResponsive = false}) => {
    const { texts } = useContext(TextsContext);
    if (!geoId) return;
    if (notEqual(geoId, "46") && !providerId) return;

    return (
        <div className={antiResponsive ? "antiresponsive" : "responsive"}>
            <div className={`${Stylegeneral.cuadro_beneficio} p-2`}>
                <TitleH2Component styleGroup={`raleway fw_700 color_primary mb-4 text-shadow`}>
                    {texts?.step11_provider_message_title ?? 'Important provider networks message:'}
                </TitleH2Component>

                {equal(geoId, "46") ?
                    <TitleH3Component styleGroup={`Poppins fw_400 color_primary mb-0`}>
                        {texts?.step11_provider_message_one ?? 'Excludes coverage in the following countries: Brazil, China, Hong Kong, Germany, Japan, Singapore, Switzerland, United States.'}
                    </TitleH3Component> : null}

                {equal(geoId, "47") ?
                    <Fragment>
                        {equal(providerId, "76") && notEqual(providerId, "77") && <TitleH3Component styleGroup={`Poppins fw_400 color_primary mb-0`}>
                            {texts?.step11_provider_message_two ?? 'Free choice of hospitals and doctors worldwide, except the following hospitals in the United States: Dana Farber Cancer Institute, Memorial Sloan Kettering Cancer Center, MD Anderson Cancer Center, New York Presbyterian Hospital, The Johns Hopkins Hospital, Baptist Health Systems, Cedars-Sinai Medical Center, Mayo Clinic (FL, MN, AZ), HCA Healthcare, Memorial Health Systems (S FL), Aspen Valley Hospital, Mount Sinai Medical Center, Naples Comprehensive Health, Orlando Health, Hospital for Special Surgery.'}
                        </TitleH3Component>}
                        {equal(providerId, "75") && <TitleH3Component styleGroup={`Poppins fw_400 color_primary mb-0`}>
                            {texts?.step11_provider_message_three ?? 'Free choice of hospitals and doctors worldwide'}
                        </TitleH3Component>}

                        

                        {equal(providerId, "77") && <TitleH3Component styleGroup={`Poppins fw_400 color_primary mb-0`}>
                            {texts?.step11_provider_message_four ?? "Free choice of hospitals and doctors worldwide, except the U.S. In the United States, the plan offers coverage only in the following hospitals: Florida: University of Miami Jackson Health System, Cleveland Clinic Weston, Holtz Children’s Hospital. Texas: Houston Methodist Hospital System, Methodist Hospital in San Antonio, The Hospitals of Providence in El Paso."}
                        </TitleH3Component>}
                    </Fragment>
                    : null
                }

                {equal(geoId, "44") ?
                    <Fragment>
                        {
                            equal(providerId, "78") &&
                            <TitleH3Component styleGroup={`Poppins fw_400 color_primary mb-0`}>
                                {texts?.step11_provider_message_five ?? 'Free choice of hospitals and doctors in Latin America'}
                            </TitleH3Component>
                        }
                        { equal(providerId, "79") && <TitleH3Component styleGroup={`Poppins fw_400 color_primary mb-0`}>
                            {texts?.step11_provider_message_six ?? "Free choice of hospitals and doctors in Latin America, except the following hospitals: Hospital Israelita Albert Einstein, Sao Paulo, Hospital Sírio-Libanês, Sao Paulo, Centro Médico ABC, Mexico, Hospital Angeles, México, CMH-Hospital Galenia, Cancún, México, Saint Luke's Hospitals, Cabo San Lucas, México, Hospital Joya, Puerto Vallarta, México, Hospital CIMA, Costa Rica, Hospital La Católica, Guadalupe, Costa Rica, Clínica Las Condes, Estoril, Chile, Clínica Alemana, Vitacura, Chile, Hospital del Diagnóstico Colonia Escalón, San Salvador, El Salvador, Hospital El Pilar, Guatemala."}
                        </TitleH3Component>}
                    </Fragment>
                    : null
                }

                {( notEqual(providerId, "75") || equal(geoId, "46") ) ? <div class="form-check mt-4">
                    <input className="form-check-input" name={"limitedCover"} type="checkbox" id="acepto" checked={limitedCover} onChange={handleChecks} />
                    <label className={`Poppins typecursiva fw_700 color_primary mb-0 ${styleTexto.h3}`} htmlFor="acepto">
                        {texts?.general_dislcaimer_cuadroText ?? 'Entiendo que he seleccionado una opción de cobertura limitada a una área geográfica y/o red de proveedores y acepto que esto conlleva restricciones en relación con estas  regiones y/o los proveedores en donde mi plan tiene cobertura.'}
                    </label>
                    <div className={`${styleForm.validador}`}>
                        <ValidaComponent keyName={"limitedCover"} styleGroup={`${styleForm.valida}`} />
                    </div>
                </div>:null}

            </div>
        </div>
    )
}
