import React, { Fragment } from "react";
import { ParagraphComponent } from "../../../../components/ParagraphComponent";
import styleForm from '../../../../scss/form.module.scss';
import StyleTex from '../../../../scss/text.module.scss'
import ValidaComponent, { APPLICATION } from "../../../../components/ValidaComponent";
import Stylegeneral from 'scss/general.module.scss'
import GestorArchivosComponent from "../../Reconocimientos/components/GestorArchivosComponent";
import { SelectAppComponent } from "components/application/SelectAppComponent";
import { InputTextComponent } from "components/application/InputTextComponent";
const MAIN_APPLICANT = "mainApplicant";
const Formulario1Component = () => {
    return (
        <Fragment>
            <ParagraphComponent styleGroup={'mb-0 py-3 raleway color_primary fw_700'}>
                <span className={`${StyleTex.titulosFormulario} `}>
                    {'Datos de la otra póliza del solicitante'}
                </span>
            </ParagraphComponent>

            <div className={`pb-3  ${Stylegeneral.colform}`}>
                <SelectAppComponent
                    name="mcompania"
                    label="Nombre de la compañía"
                    tooltip="Geographic area where the policy´s benefits apply"
                    placeholder='Ej:Colombia'
                    type='text'
                    options={[]}
                />

                <ValidaComponent phase={APPLICATION} section={MAIN_APPLICANT} keyName={"mcompania"} />
            </div>

            <div className={`pb-3  ${Stylegeneral.colform}`}>
                <SelectAppComponent
                    section={MAIN_APPLICANT}
                    name="plan"
                    label="Plan"
                    placeholder='Ej'
                    tooltip="Geographic area where the policy´s benefits apply"
                    type='text'
                />
                <ValidaComponent keyName={"plan"} phase={APPLICATION} section={MAIN_APPLICANT} />
            </div>
            <div className={`pb-3  ${Stylegeneral.colform}`}>
                <SelectAppComponent
                    section={MAIN_APPLICANT}
                    name="ntelefono"
                    label="Número de teléfono"
                    placeholder='Ej:3452344'
                    tooltip="Geographic area where the policy´s benefits apply"
                    type='text'
                />
                <ValidaComponent keyName={"ntelefono"} phase={APPLICATION} section={MAIN_APPLICANT} />
            </div>

            <div className={`pb-3  ${Stylegeneral.colform}`}>
                <SelectAppComponent
                    section={MAIN_APPLICANT}
                    name="valordeducible"
                    label="Valor del deducible"
                    placeholder='Ej:120000'
                    tooltip="Geographic area where the policy´s benefits apply"
                    type='number'
                />
                <ValidaComponent keyName={"valordeducible"} phase={APPLICATION} section={MAIN_APPLICANT} />
            </div>
            <div className={`pb-3  ${Stylegeneral.colform}`}>
                <SelectAppComponent
                    section={MAIN_APPLICANT}
                    name="numeropoliza"
                    label="Número de poliza"
                    placeholder='Ej:132342323'
                    tooltip="Geographic area where the policy´s benefits apply"
                    type='number'
                />
                <ValidaComponent keyName={"numeropoliza"} phase={APPLICATION} section={MAIN_APPLICANT} />
            </div>

            <div className={`pb-3  ${Stylegeneral.colform}`}>
                <SelectAppComponent
                    section={MAIN_APPLICANT}
                    name="numeropoliza"
                    label="Número de poliza"
                    placeholder='Ej:132342323'
                    tooltip="Geographic area where the policy´s benefits apply"
                    type='number'
                />
                <ValidaComponent keyName={"numeropoliza"} phase={APPLICATION} section={MAIN_APPLICANT} />
            </div>


            <div className={`pb-3 ${Stylegeneral.colform}`}>
                <div className={`${styleForm.btn_activar}`}>
                    <label htmlFor="compania" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                        {'¿Tiene la intención de mantener la cobertura médica con la otra compañía?'}
                    </label>
                    <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                        <input className="form-check-input" type="checkbox" id="compania" name={"addDependant"} />
                    </div>
                </div>
            </div>
            <div className="pb-3">

                <ParagraphComponent styleGroup={`${StyleTex.text_informacion} mb-0 pb-2 raleway color_primary fw_400`}>
                    Si desea que se elimine el período de espera, por favor incluya una copia del certificado de cobertura y recibo de pago de los últimos 12 meses de la cobertura previa.
                </ParagraphComponent>

                <GestorArchivosComponent />

            </div>

            <div className={`pb-3 ${Stylegeneral.colform}`}>
                <div className={`${styleForm.btn_activar}`}>
                    <label htmlFor="cmedica" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                        {'¿Ha tenido cobertura médica con VUMI® o cualquiera de sus afiliadas?'}
                    </label>
                    <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                        <input className="form-check-input" type="checkbox" id="cmedica" name={"addDependant"} />
                    </div>
                </div>
            </div>

            <div className={`pb-3 ${Stylegeneral.colform}`}>
                <InputTextComponent
                    section={MAIN_APPLICANT}
                    name="numeropoliza"
                    label="Número de poliza"
                    placeholder='Ej:132342323'
                    tooltip="Geographic area where the policy´s benefits apply"
                    type='number'
                />
                <ValidaComponent keyName={"numeropoliza"} phase={APPLICATION} section={MAIN_APPLICANT} />
            </div>

            <div className={`pb-3 ${Stylegeneral.colform}`}>
                <div className={`${styleForm.btn_activar}`}>
                    <label htmlFor="solicitud" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                        {'¿Ha sido alguna solicitud de seguro de salud o de vida rechazada, aceptada sujeta a restricciones o a una prima mayor que las tarifas estándar de la aseguradora para alguno de los solicitantes?'}
                    </label>
                    <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                        <input className="form-check-input" type="checkbox" id="solicitud" name={"addDependant"} />
                    </div>
                </div>
            </div>

            <div className={`pb-3 ${Stylegeneral.colform}`}>
                <InputTextComponent
                    section={MAIN_APPLICANT}
                    name="explicacion"
                    label="Explicación"
                    placeholder='Ej:Explicación'
                    tooltip="Geographic area where the policy´s benefits apply"
                    type='text'
                />
                <ValidaComponent keyName={"explicacio"} phase={APPLICATION} section={MAIN_APPLICANT} />
            </div>
        </Fragment>
    )
}
export default Formulario1Component;