import html2pdf from "html2pdf.js";
import mainApi from "./mainApi";
import { getPdfOptions } from "helpers/pdfOptions";
import { getEnvVariables } from "helpers/getEnvVariables";

export const sendEmailWithData = async (postData) => {
  const { data } = await mainApi.post("/email/pdf", postData);
  return data;
};
const { VUMI_LOGIN_URL, VUMI_LOGIN_URL_TEST } = getEnvVariables()


export const sendEmailWithPdfUsingAppNumber = async (appNumber, aplication = 0) => {
  const { data } = await mainApi.get(`/cotizador/${appNumber}`);

  const postData = data['data']

  const { data:html } = await mainApi.get(`/application_html/${appNumber}`, {
    responseType: "plain/text",
  });


  const pdf = await html2pdf().from(html).set(getPdfOptions()).outputPdf();

  const { data: email } = await sendEmailWithData({
    to: postData["email"],
    attachments: [
      {
        filename: `${postData["name"]} ${postData["surname"]} VIP Care.pdf`,
        base64Content: btoa(pdf),
      },
    ],
    name: `${postData["name"]} ${postData["surname"]}`,
    url: VUMI_LOGIN_URL,
    locale: postData["locale"],
    applicationId: aplication,
  });

  return email;
};


export const sendEmailWithPdf = async (postData, user) => {
  const { data } = await mainApi.post("/htmlpdf", postData, {
    responseType: "plain/text",
  });


  const pdf = await html2pdf().from(data).set(getPdfOptions()).outputPdf();

  const { data: email } = await sendEmailWithData({
    to: postData["to"],
    attachments: [
      {
        filename: `${postData["name"]} ${postData["surname"]} VIP Care.pdf`,
        base64Content: btoa(pdf),
      },
    ],
    name: `${postData["name"]} ${postData["surname"]}`,
    url: postData["url"] ?? ((user.userId.toString() === "1793" || user.agentId.toString() === "265" ) ? VUMI_LOGIN_URL_TEST : VUMI_LOGIN_URL),
    locale: postData["locale"],
    applicationId: postData["applicationId"],
  });

  return email;
};
