import React, { Fragment, useEffect, useContext } from "react";
import { TextsContext } from 'context/textsContext';
import { TitleH1Component } from "components/TitleH1Component";
import { TitleH3Component } from "components/TitleH3Component";
import Stylegeneral from 'scss/general.module.scss';
import Style from 'scss/step8.module.scss'
import { TitleH2Component } from "components/TitleH2Component";
import Styleboton from "scss/btn.module.scss";
import { Icon } from "@iconify/react";
import { useCotizador } from "context/apiContext";
import styleTexto from 'scss/text.module.scss';
import AccordionComponent from "./component/AccordionComponent";
import { downloadPdfWithData } from "api/pdfGeneratorApi";
import { usePdf } from "hooks/PdfHook";
import StyleBtn from "../../scss/btn.module.scss";
import { CircularProgress } from "@mui/material";
import "pdfjs-dist/build/pdf.worker.entry";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { isInFrame } from "../../helpers/toolsHelper";
import styleForm from '../../scss/form.module.scss';
import ValidaComponent from "../../components/ValidaComponent";
const Step8Pages = () => {
    const { texts, verifyCurrentLanguage } = useContext(TextsContext);
    const { calculation, name, surname, coverageShow: coverage, fee, fare, fareDependant, fareRider, acceptNonRegion, acceptNonRegion2, handleChecks } = useCotizador();
    const { getPdfData } = usePdf();
    const [loading, setLoading] = React.useState(false);
    const [currentPdf, setCurrentPdf] = React.useState(null);
    const [numPages, setNumPages] = React.useState(null);

    const handlePdf = () => {
        if (loading) return;
        setLoading(true);
        downloadPdfWithData({ ...getPdfData() }, isInFrame()).then(async (resp) => {
            if (resp) {
                setCurrentPdf(btoa(resp))
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        verifyCurrentLanguage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
    }

    const getCalculation = () => {
        if (fee.isFetching || fare.isFetching || fareDependant.isFetching || fareRider.isFetching) {
            return texts?.footer_value ?? 'Calculating...';
        }
        return "$" + parseFloat(calculation.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2 });
    }

    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const paragraphstep8 = texts?.step8_title;
    const step8_title_marca = paragraphstep8?.replace?.("®", `<span>&reg;</span>`) ?? '';

    const paragraphgeneral_header_title = texts?.general_header_title;
    const general_header_title_marca = paragraphgeneral_header_title?.replace?.("®", `<span>&reg;</span>`) ?? '';
    return (
        <Fragment>
            {currentPdf ?
                <div className="pdf-viewer">
                    <button type="button" onClick={() => setCurrentPdf(null)} style={{ textAlign: "right" }}
                        className={`raleway color_primary ${StyleBtn.btnApp} ${StyleBtn.wpbtn} ${StyleBtn.btnsig}`}
                    >
                        CLOSE
                    </button>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                        <Viewer
                            fileUrl={(`data:application/pdf;base64,${currentPdf}`)}
                            plugins={[defaultLayoutPluginInstance]}
                        />
                    </Worker>
                </div>
                :
                <React.Fragment>
                    <section className="pb-4 pb-mb-2" id="sectionscroll">
                        <div className="container ">
                            <div className="d-block d-lg-none">
                                <TitleH2Component
                                    styleGroup={`raleway fw_400 lh_40 color_secundary mb-0 py-2 ${Stylegeneral.titulo_principal}`}>
                                    <p dangerouslySetInnerHTML={{ __html: general_header_title_marca }} className={Stylegeneral.marca_registradaContainer}></p>

                                </TitleH2Component>
                            </div>
                            <TitleH1Component styleGroup={`raleway_semibold fw_700  lh_30 color_primary pb-3 ${Stylegeneral.titulo}`}>
                                <p dangerouslySetInnerHTML={{ __html: step8_title_marca }} className={Stylegeneral.marca_registradaContainer}></p>
                            </TitleH1Component>

                            <TitleH3Component
                                styleGroup={`Poppins fw_400 color_primary  ${Stylegeneral.subtitulo} ${styleTexto.tamasubparrafo}`}>
                                {texts?.step8_phrase_one ?? 'You’ve designed a plan that will provide you with tailor-made coverage to protect your family. Based on your specific selections, here is the annual estimate cost of your custom My VIP Care plan.'}
                            </TitleH3Component>


                        </div>
                    </section>

                    <section>
                        <div className="container">
                            <div className={`${Style.row}`}>
                                <div className={`${Style.col1} me-0 me-md-5`}>

                                    <div className={`${Stylegeneral.cuadro_title_azul}`}>
                                        <TitleH3Component
                                            styleGroup={`Poppins fw_400 color-white mb-0 text-start ${styleTexto.tamasubparrafo}`}>
                                            {texts?.step8_phrase_two ?? 'Do you want to make changes? You can make the changes from the dropdown arrows and adjust your plan to your needs.'}
                                        </TitleH3Component>
                                    </div>
                                    {/* <CollapseComponent datos={datos} collapse={collapses} />*/}
                                    <AccordionComponent />

                                    <div className={`mt-3`}>
                                        <div className={`${Stylegeneral.cuadro_beneficio} p-2`}>
                                            <TitleH2Component styleGroup={`raleway fw_700 color_primary mb-4 text-shadow`}>
                                                {texts?.step11_provider_message_title ?? 'Important provider networks message:'}
                                            </TitleH2Component>
                                            <div className={styleForm.containercheck}>
                                                <div className="form-check mt-4">
                                                    <input className="form-check-input" name="acceptNonRegion" checked={acceptNonRegion} onChange={handleChecks} type="checkbox" value="" id="acepto" />
                                                    <label className={`Poppins typecursiva fw_700 color_primary mb-0 ${styleTexto.h3}`} htmlFor="acepto">
                                                        {texts?.general_dislcaimer_step8 ?? 'Entiendo que he seleccionado una opción de cobertura geográfica y/o red de proveedores con restricciones en relación con las regiones y/o los hospitales en donde mi plan tendrá cobertura'}
                                                    </label>
                                                </div>
                                                <div className={`${styleForm.validador}`}>
                                                    <ValidaComponent keyName={"acceptNonRegion"} styleGroup={`${styleForm.valida} ${styleForm.validaSpecial}`} />
                                                </div>
                                            </div>

                                            <div className={styleForm.containercheck}>
                                                <div className="form-check mt-4">
                                                    <input className="form-check-input" type="checkbox" value="" id="acepto2" name="acceptNonRegion2" checked={acceptNonRegion2} onChange={handleChecks} />
                                                    <label className={`Poppins typecursiva fw_700 color_primary mb-0 ${styleTexto.h3}`} htmlFor="acepto2">
                                                        {texts?.general_dislcaimer_2_step8 ?? 'Confirmo que he revisado y entendido todos los beneficios que ofrece la póliza “My VIP Care” de VUMI® y he elegido aquellos que requiero y declinado otros. Por lo tanto, entiendo y acepto el alcance de la cobertura del plan diseñado y elegido por mí.'}
                                                    </label>
                                                </div>
                                                <div className={`${styleForm.validador}`}>
                                                    <ValidaComponent keyName={"acceptNonRegion2"} styleGroup={`${styleForm.valida}  `} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div className={`${Style.col2} mb-5 mb-lg-0`}>
                                    <div className={`${Style.tarjeta_info} `}>
                                        <TitleH3Component styleGroup="raleway_bold fw_700  color_primary mb-3 mb-md-2">
                                            {texts?.step8_phrase_summary ?? 'Summary'}
                                        </TitleH3Component>
                                        <TitleH2Component styleGroup="raleway fw_400  color_secundary mb-2 ">
                                            {name} {surname}
                                        </TitleH2Component>
                                        <TitleH3Component styleGroup=" pb-2 pb-md-0 ">
                                            <span
                                                className={`color_primary raleway_bold  ${Style.tama_subtitle}`}>{texts?.step8_phrase_date ?? 'Coverage Start Date'}</span>
                                            <p
                                            
                                                className={`${Style.tama_date}  raleway color_primary`}>{coverage}</p>
                                        </TitleH3Component>

                                        {loading ? <CircularProgress size={50} />
                                            :
                                            <button type="button" onClick={handlePdf}
                                                className={`${Styleboton.btn } d-flex align-items-center my-3 py-3 py-md-2 mb-1 ${Styleboton.btndescarga} w-100`}>
                                                <Icon icon="prime:file-pdf" className={`${Style.icono}`} /> <span
                                                    className={`${Styleboton.text_boton} raleway`}>{texts?.step8_phrase_download ?? 'Download quote'}</span>
                                            </button>
                                        }
                                        <hr className={`${Style.line}`} />
                                        <TitleH2Component styleGroup="raleway fw_400  color_primary_2 ">
                                            {texts?.step8_phrase_cost ?? 'Estimated annual cost:'}
                                        </TitleH2Component>
                                        <TitleH1Component styleGroup={`raleway fw_600 color_secundary`}>
                                            {getCalculation()}
                                        </TitleH1Component>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </React.Fragment>
            }
        </Fragment >
    )
}
export default Step8Pages;
