import LoginPage from "../pages/Login/LoginPage";
import { ApplicationRoutes } from "./ApplicationRoutes";
import { CotizadorRoutes } from "./CotizadorRoutes";
import { MainRoutes } from "./MainRoutes";

export const AppRoutes = [
  {
    path: "/",
    element: <LoginPage />,
  },
  ...MainRoutes,
  ...CotizadorRoutes,
  ...ApplicationRoutes
];
