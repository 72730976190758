import { ApplicationGuard } from "../guards/ApplicationGuard";
import AppLayout from "../layouts/AppLayout";
import CotizacionPage from "../pages/Fase2/Cotizacion/CotizacionPage";
import HistoriaFamiliarPage from "../pages/Fase2/HistoriaFamiliar/HistoriaFamiliarPage";
import EditarInfoDependientePage from "../pages/Fase2/InfoDependientes/EditarInfoDependientePage";
import InfoDependientesPage from "../pages/Fase2/InfoDependientes/InfoDependientesPage";
import InfoSolicitudoPage from "../pages/Fase2/InfoSolicitud/InfoSolicitudoPage";
import InformacionDePagoPages from "../pages/Fase2/InformacionDePago/InformacionDePagoPages";
import ReconocimientosPage from "../pages/Fase2/Reconocimientos/ReconocimientosPage";
import LoginAppPage from "../pages/Login/LoginAppPage";
import CoberturaPreviaPage from "../pages/Fase2/CoberturaPrevia/CoberturaPreviaPage";
import Registro1Page from "../pages/Fase2/CoberturaPrevia/Registro1Page";
import Registro2Page from "../pages/Fase2/CoberturaPrevia/Registro2Page";
import Registro4Page from "../pages/Fase2/CoberturaPrevia/Registro4Page";
import Registro5Page from "../pages/Fase2/CoberturaPrevia/Registro5Page";
import Registro6Page from "../pages/Fase2/CoberturaPrevia/Registro6Page";
import Registro7Page from "../pages/Fase2/CoberturaPrevia/Registro7Page";
import Registro8Page from "../pages/Fase2/CoberturaPrevia/Registro8Page";
import Registro9Page from "../pages/Fase2/CoberturaPrevia/Registro9Page";
import Registro10Page from "../pages/Fase2/CoberturaPrevia/Registro10Page";
import Registro11Page from "../pages/Fase2/CoberturaPrevia/Registro11Page";
import { CancelButton } from "components/footer/CancelButton";
import { AddButton } from "components/footer/AddButton";
import StyleFooter from "scss/footer.module.scss";
import Registro12Page from "../pages/Fase2/CoberturaPrevia/Registro12Page";
import Registro13Page from "../pages/Fase2/CoberturaPrevia/Registro13Page";
import Registro14Page from "../pages/Fase2/CoberturaPrevia/Registro14Page";
import { AddButtonText } from "components/footer/AddButtonText";
import { GUARD_NONE, GUARD_RELATIVE } from "helpers/guards";

const registerDefault = {
    hidden: true,
    baseButtonCss: StyleFooter.editarBtns,
    leftButton: <CancelButton href={"/coberturaprevia"} action={"reset"} />,
    rightButton: <AddButton text={<AddButtonText />} action={"add"} />,
    guard: GUARD_RELATIVE
}

export const ApplicationRoutes = [
    {
        path: "/login-app",
        element: <LoginAppPage />,
        hidden: true,
        guard: GUARD_NONE
    },
    {
        path: '/info-solicitud',
        element:<AppLayout><InfoSolicitudoPage /></AppLayout>,
        name: "Información del solicitante",
        key: "menu_options_title_applicant",
        leftButton: <></>
    },
    {
        path:'/cotizacion',
        element:<AppLayout><CotizacionPage /></AppLayout>,
        name: "Cobertura",
        key: "menu_options_title_coverage_two",
    },
    {
        path:'/infodependientes',
        element:<AppLayout><InfoDependientesPage/></AppLayout>,
        name: "Información de Dependientes",
        key: "menu_options_title_dependents"
    },
    {
        path:'/editar-dependientes',
        element:<AppLayout><EditarInfoDependientePage/></AppLayout>,
        hidden: true,
        baseButtonCss: StyleFooter.editarBtns,
        leftButton: <CancelButton href={"/infodependientes"} />,
        rightButton: <AddButton />,
        guard: GUARD_RELATIVE
    },
    {
        path: "/coberturaprevia",
        element: <AppLayout><CoberturaPreviaPage /></AppLayout>,
        name: "Información de Cobertura Previa",
        key: "menu_options_title_prior_coverage"
    },
    {
        path:'/registro1Page',
        element:<AppLayout><Registro1Page/></AppLayout>,
        ...registerDefault
    },
    {
        path:'/registro2Page',
        element:<AppLayout><Registro2Page/></AppLayout>,
        ...registerDefault
    },
    {
        path:'/registro4Page',
        element:<AppLayout><Registro4Page/></AppLayout>,
        ...registerDefault
    },
    {
        path:'/registro5Page',
        element:<AppLayout><Registro5Page/></AppLayout>,
        ...registerDefault
    },
    {
        path:'/registro6Page',
        element:<AppLayout><Registro6Page/></AppLayout>,
        ...registerDefault
    },
    {
        path:'/registro7Page',
        element:<AppLayout><Registro7Page/></AppLayout>,
        ...registerDefault
    },
    {
        path:'/registro8Page',
        element:<AppLayout><Registro8Page/></AppLayout>,
        ...registerDefault
    },
    {
        path:'/registro9Page',
        element:<AppLayout><Registro9Page/></AppLayout>,
        ...registerDefault
    },
    {
        path:'/registro10Page',
        element:<AppLayout><Registro10Page/></AppLayout>,
        ...registerDefault
    },
    {
        path:'/registro11Page',
        element:<AppLayout><Registro11Page/></AppLayout>,
        ...registerDefault
    },
    {
        path:'/registro12Page',
        element:<AppLayout><Registro12Page/></AppLayout>,
        ...registerDefault
    },
    {
        path:'/registro13Page',
        element:<AppLayout><Registro13Page/></AppLayout>,
        ...registerDefault
    },
    {
        path:'/registro14Page',
        element:<AppLayout><Registro14Page/></AppLayout>,
        ...registerDefault
    },
    {
        path:'/historia-familiar',
        element:<AppLayout><HistoriaFamiliarPage/></AppLayout>,
        name: "Historia Familiar",
        key: "menu_options_title_history"
    },
    {
        path:'/reconocimientos',
        element:<AppLayout><ReconocimientosPage /></AppLayout>
    },
    {
        path: "/InformacionDePago",
        element: <AppLayout><InformacionDePagoPages /></AppLayout>,
    },

    

].map((step) => {
    step.element = <ApplicationGuard {...step} />
    return step;
});