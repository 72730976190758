import React from "react";
import { DateSelectComponent } from "../DateSelectsComponent";
import { useApplicationInput } from "hooks/ApplicationInputHook";

export const DateAppComponent = (props) => {
  const {maxYear, minYear, name, disabled = false} = props;
  const {value, handleOnChange} = useApplicationInput(props);

  /**@param {Date} date */
  const onDataChange = (date) => {
    handleOnChange({target: {name, value: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`}});
  }

  const date_ = value?.split("-");
  const value_ = value ? (new Date(date_[0], date_[1] - 1, date_[2])) : null;

  return (
    <DateSelectComponent disabled={disabled} maxYear={maxYear} minYear={minYear} onChange={onDataChange} value={value_} />
    )
  }