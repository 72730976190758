import { useContext } from "react";
import { TextsContext } from "context/textsContext";
import StyleBtn from "scss/btn.module.scss";
import { Icon } from "@iconify/react";
import { useFooterApplication } from "hooks/FooterApplicationHook";

export function AddButton({ action, text }) {
    const { texts } = useContext(TextsContext);
    const {[action]: fn} = useFooterApplication();
    if (!text) text = texts?.general_save_button ?? 'Save';

  return (
    <button
      type="button"
      onClick={() => fn?.()}
      className={`raleway  color_primary  d-flex align-items-center justify-content-center   ${StyleBtn.btnApp} ${StyleBtn.btnAppSave}   ${StyleBtn.btnant} `}
    >
      <Icon icon="cil:save" className={`${StyleBtn.iconBtnSave}`} />
      <span className="ms-2">
        {text}
      </span>
    </button>
  );
}
