import React, { useContext } from "react";
import { TitleH3Component } from "../../../components/TitleH3Component";
import styleForm from "../../../scss/form.module.scss";
import style from "../../../scss/collapse.module.scss";
import { TextsContext } from "context/textsContext";
import { useCotizador } from "context/apiContext";
import { valusIsTrue } from "helpers/toolsHelper";

export const AccordionItemTravelComponent = ({ onChange }) => {
  const { texts } = useContext(TextsContext);
  const { contextState, dependants, editDependantTravel } = useCotizador();

  const handleDependantChange = (e) => {
    editDependantTravel(Number(e.target.dataset.id), e.target.value === 'true' ? true : false);
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center  pb-2">
        <TitleH3Component
          styleGroup={`raleway color_primary ${style.titulo} ${style.titulocollasep}`}
        >
          {texts?.general_form_field_light_rider ?? "Travel VIP Light Rider"}
        </TitleH3Component>

        <div
          className={`bg_gray_6 py-1 px-3  ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}
        >
          <select
            value={contextState.travel}
            onChange={onChange}
            name={"travel"}
            className={` ${styleForm.selecfondo} raleway `}
          >
            <option value={false}>
              {texts?.general_form_option_no ?? "No"}
            </option>
            <option value={true}>
              {texts?.general_form_option_yes ?? "Yes"}
            </option>
          </select>
        </div>
      </div>

      {dependants.map((dependant) => (
        <div className="d-flex justify-content-between align-items-center  pb-2">
          <TitleH3Component
            styleGroup={`raleway color_primary ${style.titulo} ${style.titulocollasep}`}
          >
            {texts?.general_form_field_light_rider_dependant ?? "Travel VIP Light Rider Dependant"} {dependant.id +1 }
          </TitleH3Component>

          <div
            className={`bg_gray_6 py-1 px-3  ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}
          >
            <select
              value={dependant.travel}
              onChange={handleDependantChange}
              data-id={dependant.id}
              name={"travel"}
              className={` ${styleForm.selecfondo} raleway `}
            >
              <option value={false}>
                {texts?.general_form_option_no ?? "No"}
              </option>
              <option value={true}>
                {texts?.general_form_option_yes ?? "Yes"}
              </option>
            </select>
          </div>
        </div>
      ))}

      {[valusIsTrue(contextState.travel), ...dependants.map(i => valusIsTrue(i.travel))].some(i => !!i) && (
        <>
          <div className="d-flex justify-content-between align-items-center mb-1">
            <TitleH3Component
              styleGroup={`raleway color_primary ${style.titulo} ${style.titulocollasep}`}
            >
              {texts?.quote_pdf_travel_vip_title ??
                "Benefits for Travel VIP Light"}
            </TitleH3Component>
            <div
              className={` py-1 px-3 ${styleForm.customselecfondo} ${styleForm.selectcollasep}`}
            >
              <TitleH3Component
                styleGroup={`raleway  color_gray_1 mb-1 ${style.titulo} `}
              >
                {texts?.quote_pdf_travel_vip_value ??
                  `US$60,000 per lifetime, included in the organ transplant
                                              benefit`}
              </TitleH3Component>
            </div>
          </div>
        </>
      )}
    </>
  );
};
