import { Fragment, useState, useRef, useEffect, useContext } from "react";
import { TextsContext } from '../context/textsContext';
import { useLocation } from "react-router-dom";
import StyleGrid from "../scss/grid.module.scss";
import StyleBtn from "../scss/btn.module.scss";
import { FooterComponent } from "../components/FooterComponent";
import MenuComponent from "../components/MenuComponent";
import HeaderComponent from "../components/HeaderComponent";
import StepComponents from "../components/StepComponents";
import { ParagraphComponent } from "../components/ParagraphComponent";

import { isMobileOnly } from "react-device-detect";
import { Icon } from "@iconify/react";
import { Modal, Button } from "react-bootstrap";

import { useCotizador } from "../context/apiContext";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/userContext";
import { isInFrame } from "../helpers/toolsHelper";

const MainLayout = ({ children }) => {
    const { texts } = useContext(TextsContext);
    const currentPath = window.location.pathname;
    const [hamburger, sethamburger] = useState(true)

    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const hHeader = useRef(null);
    const wpGroup = useRef(null);
    const hContent = useRef(null);
    const hFooter = useRef(null);
    const stepsG = useRef(null);

    const [isFocused, setIsFocused] = useState(false);

    const [disabledHeader, setDisabledHeader] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    const { logout } = useAuth();
    const handleLogout = () => {
        logout();
        navigate('/login')
    }


    useEffect(() => {

        if (isInFrame()) {
            localStorage.setItem('onApp', 1);
            setDisabledHeader(true);
        } else {
            if (localStorage.getItem('onApp') == 1) {
                setDisabledHeader(true);
            } else {
                setDisabledHeader(false);
            }
        }

        const alturaHeader = hHeader.current ? hHeader.current.clientHeight : 0;
        const alturaFooter = hFooter.current ? hFooter.current.clientHeight : 0;
        const alturaContent = hContent.current ? hContent.current.clientHeight : 0;
        const alturaSteps = stepsG.current ? stepsG.current.clientHeight : 0;

        if (isMobileOnly) {
            const updateWindowHeight = () => {
                setWindowHeight(window.innerHeight);
            };

            const realHContent = windowHeight - alturaHeader - alturaFooter;
            const realHContent2 = realHContent - alturaSteps;
            const realHContent3 = realHContent2 + alturaHeader;

            //funciones para cuando este en mobile y se haga focus en un input text el wpGroup aunmente,
            //su altura y tenga un mejor espacio para los elementos ya que aparece el teclado 

            const handleGlobalFocus = (event) => {
                if ((event.target.tagName === 'INPUT' && event.target.type === 'text') || event.target.type === 'email') {
                    // console.log(`Se seleccionó el elemento: ${event.target.tagName}`);
                    setIsFocused(true);
                    // hContent.current.style.height = `${realHContent+alturaFooter}px`;
                }
            };

            const handleGlobalBlur = (event) => {
                if ((event.target.tagName === 'INPUT' && event.target.type === 'text') || event.target.type === 'email') {
                    // console.log(`Se desenfocó el elemento: ${event.target.tagName}`);
                    setIsFocused(false);
                    // hContent.current.style.height = `${realHContent2}px`;
                }
            };

            document.addEventListener('focus', handleGlobalFocus, true);
            document.addEventListener('blur', handleGlobalBlur, true);

            return () => {
                window.removeEventListener('resize', updateWindowHeight);
                document.removeEventListener('focus', handleGlobalFocus, true);
                document.removeEventListener('blur', handleGlobalBlur, true);
            };

        }

    }, [location]);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const { name, surname, resetData } = useCotizador();

    const reset = () => {
        resetData();
        navigate("/step1");
        handleClose(true);
    }

    return (
        <Fragment>
            <div className={StyleGrid.wpApp}>
                {!disabledHeader &&
                    <div className={`${StyleGrid.wpMenu} ${!hamburger ? StyleGrid.active : null} `} ref={hHeader}>
                        <div className={StyleGrid.wpScroll}>
                            <MenuComponent sethamburger={sethamburger} hamburger={hamburger} />
                            <div className={`${StyleGrid.tooltipicon}`}>
                                <div className={`${StyleGrid.text_tooltip} me-2 bg_gray_3 d-flex align-items-center`}>
                                    <ParagraphComponent styleGroup='mb-0 color_gray_5 pb-0'>
                                        {texts?.menu_paragraph ?? 'Place the cursor over the information icon  for further explanation about each one of the fields.'}
                                    </ParagraphComponent>
                                    <div className={`${StyleGrid.iconInfo} color_gray_5 `}>
                                        <Icon icon='mdi:information-symbol' />
                                    </div>
                                </div>

                                {/*<img src="../../img/iconInfo.png" alt="icon " className={`${StyleGrid.iconInfo}`} />*/}
                            </div>

                        </div>



                        {/* <div className="pt-5 pt-xl-2 justify-content-center d-none d-lg-flex">
                        <button onClick={handleLogout} className={`${StyleBtn.logout} ${StyleBtn.btn}`}>
                            <Icon icon='mdi:logout' className="me-2"/>
                            <span>{texts?.menu_logout ?? 'Logout'}</span>
                        </button>
                    </div> */}

                    </div>
                }

                {disabledHeader &&
                    <button className={`${StyleGrid.NewStart} color_secundary`} onClick={handleShow}>
                        <span>New</span>
                        <Icon icon='ic:outline-plus' />
                    </button>
                }

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title className="d-flex align-items-center">
                            <h3 className="pe-3">Aviso</h3>
                            <Icon icon='fluent:alert-12-regular' />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="d-flex justify-content-center">
                        <Button onClick={reset} className={`raleway fw-bold color_primary bg_gray_3 px-2`}>
                            <Icon icon="ei:plus" className={`pe-1`} />
                            <span>Start over with quote</span>
                        </Button>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose} className={StyleBtn.btn}>
                            Close
                        </Button>
                        {/* <Button variant="primary" onClick={handleClose} className={StyleBtn.btn}>
                            Save Changes
                        </Button> */}
                    </Modal.Footer>
                </Modal>

                <div className={`${StyleGrid.wpGroup} ${disabledHeader ? StyleGrid.noTop : ''}`} ref={wpGroup}>
                    <div className={`${StyleGrid.wpHeader} ${disabledHeader ? StyleGrid.noTopHeader : ''}`}>
                        <div className="d-none d-lg-block">
                            <HeaderComponent />
                        </div>

                        <div ref={stepsG}>
                            <div className="d-lg-none">
                                <StepComponents />
                            </div>
                        </div>

                    </div>
                    <div className={`${StyleGrid.wpContent}`} ref={hContent}>
                        <div className={`py-3 py-md-0  ${StyleGrid.wpScroll} ${StyleGrid.wpScrollContent}`} >
                            {children}
                        </div>
                    </div>
                    {
                        currentPath !== '/step9' && (
                            <div className={`${StyleGrid.wpFooter}  
                        ${currentPath === '/step8' || currentPath === '/step9' ? `${StyleGrid.fondoFoooterStep8}` : `${StyleGrid.fondoFoooter}`} 
                        ${isFocused ? `${StyleGrid.getOut}` : ''} `} ref={hFooter}>
                                <FooterComponent hContent={hContent}/>
                            </div>
                        )
                    }


                </div>
            </div>
        </Fragment >
    );
}

export default MainLayout;