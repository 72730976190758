import React, { Fragment, useEffect, useContext, useState } from "react";
import { TextsContext } from '../../context/textsContext';
import Stylegeneral from '../../scss/general.module.scss';
import styleForm from '../../scss/form.module.scss';
import { TitleH1Component } from "../../components/TitleH1Component";
import { Icon } from "@iconify/react";
import { TitleH2Component } from "../../components/TitleH2Component";
import { useCotizador } from "../../context/apiContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { TitleH3Component } from "../../components/TitleH3Component";
import ValidaComponent from "../../components/ValidaComponent";
import { filterByMaximun } from "../../helpers/toolsHelper";

const Step7Pages = () => {
    const { texts, verifyCurrentLanguage } = useContext(TextsContext);
    const { handleOnChange: handleOnChange_, organ, organRider, organRiderQuery, maximun } = useCotizador();
    const [isOpen, setIsOpen] = useState(false);

    const handleOnChange = (e) => {
        handleOnChange_(e);
        let text = "";
        let id = "";
        if (e?.target?.options?.length) {
            text = e.target?.options[e.target.selectedIndex]?.dataset?.description;
            id = e.target?.options[e.target.selectedIndex]?.dataset?.id;
        }
        handleOnChange_({ target: { value: text, name: "organRiderName" } });
        handleOnChange_({ target: { value: id, name: "organId" } });
    }

    const handleBoolSelect = (e) => {
        handleOnChange({ target: { name: e.target.name, value: e.target.value === 'true' ? true : false } });
    }

    useEffect(() => {
        verifyCurrentLanguage();
    }, []);

    const handleChecks = (e) => {
        handleOnChange({ target: { value: e.target.checked, name: e.target.name } })
    }

    const extractNumberFromString = (inputString) => {
        if (!inputString) return NaN;
        const stringWithoutCommas = String(inputString).replace(/,/g, "");
        const match = stringWithoutCommas.match(/[\d.]+/);

        if (match) {
            const numberString = match[0];
            return parseFloat(numberString);
        }

        return NaN;
    };

    const getOrganList = () => {
        if (!(typeof organRiderQuery?.data?.data?.sort === 'function')) return [];
        return organRiderQuery?.data?.data?.sort((a, b) => extractNumberFromString(b?.description) - extractNumberFromString(a?.description));
    }

    return (
        <Fragment>
            <section id="sectionscroll">
                <div className="container ">
                    <div className="d-block d-lg-none">
                        <TitleH2Component styleGroup={`raleway fw_400 lh_40 color_secundary mb-0 py-2 ${Stylegeneral.titulo_principal}`}>
                            {texts?.general_header_title ?? 'New VUMI VIP Care'}
                        </TitleH2Component>
                    </div>
                    <TitleH1Component styleGroup={`raleway_semibold fw_700  lh_30 color_primary pb-3 ${Stylegeneral.titulo}`}>
                        {texts?.step4_title ?? "Let’s continue customizing based on your exact needs"}
                    </TitleH1Component>


                </div>
            </section>

            <section>
                <div className="container">
                    <div className="row gy-5">
                        <div className="col-12 col-md-6 ">

                            <div className={`${Stylegeneral.col_img}`}>
                                <div className="d-flex justify-content-center mt-2">
                                    <TitleH2Component styleGroup={`Poppins fw_400 color_primary mb-0 ${Stylegeneral.titulo}`}>
                                        {texts?.step7_subtitle ?? 'Organ  transplant'}
                                    </TitleH2Component>
                                </div>
                                <img src="../../img/Mask2.jpg" alt="step6" className="w-100" />
                                <img src="../../img/marcaagua.png" alt="tep6" className={`${Stylegeneral.img}`} />
                            </div>

                        </div>
                        <div className="col-12  col-md-6  ">
                            <div className={`${Stylegeneral.col_form} h-100`}>
                                <div className={`${styleForm.form_group} h-100`}>
                                    {/*<div className="d-flex align-items-center">
                                        <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1`}>{texts?.step7_form_field_select ?? 'Select Yes/No'}</label>

    </div>*/}

                                    {/* <select onChange={handleBoolSelect} name={"organ"} value={organ} className={`${styleForm.customselectLine}`}>
                                        <option value={false}>{texts?.general_form_option_no ?? 'No'}</option>
                                        <option value={true}>{texts?.general_form_option_yes ?? 'Yes'}</option>
</select>*/}

                                    <div className={`${styleForm.btn_activar} pt-2 `}>
                                        <label htmlFor="need" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                            {texts?.step7_form_field_select ?? 'Select Yes/No'}
                                        </label>
                                        <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                            <input className="form-check-input" type="checkbox" id="need" name={"organ"} onChange={handleChecks} checked={organ} />
                                        </div>
                                    </div>


                                    <div className="d-flex align-items-center h-100 ">
                                        <div className={(organ === "true" || organ === true) ? "w-100" : "d-none"}>
                                            <div className="d-flex align-items-center mt-5">
                                                <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1 `}>{texts?.step7_form_field_organ ?? 'Organ Transplant'}</label>

                                                <OverlayTrigger
                                                    key="top"
                                                    placement="top"
                                                    overlay={<Tooltip id="tooltip-top" className="custom-tooltip">
                                                        {texts?.step7_tooltip_medical_procedure ?? 'Medical procedure to transfer an organ, tissue or cells from a living or deceased donor to the recipient, or reimplant it in the same person.'}
                                                    </Tooltip>}
                                                >
                                                    <Icon icon={'mdi:information-outline'} className={` color_gray_5 ${styleForm.iconselect}`} />
                                                </OverlayTrigger>



                                            </div>


                                            <select
                                                name={"organRider"}
                                                className={`${styleForm.customselectLine}`}
                                                onChange={handleOnChange}
                                                value={organRider}
                                                onClick={() => setIsOpen(!isOpen)}
                                                onBlur={() => setIsOpen(false)}
                                                onFocus={() => setIsOpen(false)}
                                            >
                                               

                                                <option value={0} disabled={true}>
                                                    {texts?.general_form_field_select ?? 'Select'}
                                                </option>

                                                {getOrganList()
                                                .filter(filterByMaximun(maximun))
                                                .map((item) => (
                                                    <option key={item.id} value={item.factor} data-description={item.description} data-id={item.id}>

                                                        <span >
                                                            {item.description}
                                                        </span>

                                                        {item.id === 36 && isOpen &&
                                                            (organRider !== item.factor || isOpen) && (
                                                                <span></span>
                                                            )}{" "}
                                                    </option>

                                                ))}


                                            </select>
                                            <div className={`${styleForm.validador}`}>
                                                <ValidaComponent keyName={"organ"} styleGroup={`${styleForm.valida}`}/>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}
export default Step7Pages;
