import React, { Fragment, useEffect, useContext } from "react";
import { TextsContext } from "context/textsContext";
import styleForm from '../../../scss/form.module.scss';
import ValidaComponent, { APPLICATION } from "../../../components/ValidaComponent";
import { SelectAppComponent } from "../../../components/application/SelectAppComponent";
import Stylegeneral from '../../../scss/general.module.scss'
import { useLocation } from "react-router-dom";
import { CheckboxComponent } from "components/CheckboxComponent";

const Registro10Page = () => {
    const { texts, verifyCurrentLanguage } = useContext(TextsContext);
    const {pathname} = useLocation();

    useEffect(() => {
		verifyCurrentLanguage();
	}, []);

    return (
        <Fragment>
            <section>
                <div className="container py-3">
                    <div className={`${styleForm.btn_activar}`}>
                        <label htmlFor="Embarazo" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                            {texts?.page_ten_checkbox_one ?? 'Femenino: Embarazo actual / Embarazos anteriores'}
                        </label>
                        <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                            <CheckboxComponent className="form-check-input" type="checkbox" id="Embarazo" name={"pregnancy"} section={"/coberturaprevia"} />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container pb-5">
                    <div className="row gy-4">
                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <SelectAppComponent
                                name="solicitante"
                                label={texts?.general_form_field_applicant ?? "Solicitante"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                placeholder='Ej:Colombia'
                                type='text'
                                options={[]}
                                section={pathname}
                            />

                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"solicitante"} />
                        </div>
                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <SelectAppComponent
                                section={pathname}
                                name="tipoembarazo"
                                label={texts?.page_ten_form_field_type ?? "Tipo"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                placeholder='Ej:Colombia'
                                type='text'
                                options={[]}
                            />

                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"tipoembarazo"} />
                        </div>
                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <SelectAppComponent
                                name="partosNormales"
                                label={texts?.page_ten_form_field_birth ?? "Número de Partos Normales:"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                placeholder='Ej:Colombia'
                                type='text'
                                options={[]}
                                section={pathname}
                            />

                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"partosNormales"} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <SelectAppComponent
                                name="numerocesarias"
                                label={texts?.page_ten_form_field_cesarean ?? "Número de Cesáreas:"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                placeholder='Ej:Colombia'
                                type='text'
                                options={[]}
                                section={pathname}
                            />

                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"numerocesarias"} />
                        </div>

                        <div className={`col-12 ${Stylegeneral.colform}`}>
                            <SelectAppComponent
                                name="numeroabortos"
                                label={texts?.page_ten_form_field_abortion ?? "Número de Abortos:"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                placeholder='Ej:Colombia'
                                type='text'
                                options={[]}
                                section={pathname}
                            />

                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"numeroabortos"} />
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}
export default Registro10Page;