import React from "react";
import { useApplicationInput } from "../../hooks/ApplicationInputHook";

export const InputComponent = (props) => {
  const {type = "text", name, placeholder, required, className, id, disabled} = props;
  const {handleOnChange, value} = useApplicationInput(props);
  
  return (
      <input
        id={id}
        type={type}
        className={className}
        placeholder={placeholder}
        name={name}
        onChange={handleOnChange}
        value={value}
        required={required}
        disabled={disabled}
      />
  );
};
