import React, { Fragment, useState, useContext } from "react";
import { TextsContext } from '../context/textsContext';
import StyleBtn from "../scss/btn.module.scss";
import Stylemodal from '../scss/modal.module.scss';
import { Icon } from "@iconify/react";
import { Modal } from 'react-bootstrap';
import { useCotizador } from "../context/apiContext";
import ValidaComponent from "./ValidaComponent";
import styleForm from '../scss/form.module.scss';
import Stylegeneral from "../scss/general.module.scss";
import { sendEmailWithPdf } from "../api/emailApi";
import { usePdf } from "../hooks/PdfHook";
import { downloadPdfWithData } from "../api/pdfGeneratorApi";
import { TitleH3Component } from "./TitleH3Component";
import { CircularProgress } from "@mui/material";
import { saveCotizador } from "api/cotizadorApi";
import {saveCotizadorData} from "../api/ApplicationApi";
import {useCotizacionAdapter} from "../hooks/CotizadorAdapterHook";
import {useCotizacionSaveAdapter} from "../hooks/CotizadorSaveAdapterHook";
import {useAuth} from "../context/userContext";

const ModalEnvioEmailComponent = () => {
    const { texts } = useContext(TextsContext);
    const { show, setShow } = useCotizador();
    const [showConfirmar, setShowConfirmar] = useState(false);
    const [to, setTo] = useState("");
    const [sending, setSending] = useState(false);
    const [showemail, setShowemail] = useState(false);
    const [loadingPdf, setLoadingPdf] = useState(false);
    const {user} = useAuth();
    const handleClose = () => setShow(false);
    const handleCloseConfirmar = () => setShowConfirmar(false);
    const handleCloseemail = () => setShowemail(false);

    const { getPdfData } = usePdf();
    const {createApplicationSave} = useCotizacionSaveAdapter();

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handlePdf = () => {
        setLoadingPdf(true);
        saveCotizador(getPdfData())
        .then(({data}) => {
            return createApplicationSave(data.id)
        }).then(() => {
            return downloadPdfWithData(getPdfData());
        }).then(() => {
            setLoadingPdf(false);
            setShowConfirmar(true);
            setShow(false);
        }).catch(() => {
            setLoadingPdf(false);
        });
    }

    const handleSend = () => {
        if (!validateEmail(to)) {
            return;
        }
        setSending(true);
        sendEmailWithPdf({ ...getPdfData(), to }, user).then(() => {
            setShowConfirmar(true);
            setSending(false);
            setShowemail(false);
            handleClose();
        }).catch((e) => {
            console.error(e);
            setSending(false);
        })
    }

    return (
        <Fragment>

            <Modal show={show} onHide={handleClose} className={`modal_save d-flex justify-content-center align-items-center `}>

                <Modal.Body className={`bg_gray_3  ${Stylemodal.modalconfirmar} `}>
                    <div className={`d-flex align-items-center w-100 pb-4 ${Stylemodal.modal_content}`}>
                        <div className="d-flex justify-content-center w-100">
                            <TitleH3Component styleGroup={`raleway mb-0 py-0 color_primary mb-0 fw_700  text-center  ${Stylemodal.textShere}`}>
                                { texts?.modal_pdf_save ?? 'Save'}
                            </TitleH3Component>
                        </div>

                        {/*<Icon icon="solar:share-bold" className={`${Stylemodal.icono}`} />*/}
                        <div className={`  ${Stylemodal.iconClose} ${Stylemodal.iconCloseflex}`}>
                            <Icon icon='mdi:close' onClick={() => { handleClose() }} />
                        </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                        {
                            loadingPdf ?
                                <div className=" px-4 me-2 fw_500 Poppins d-flex justify-content-center align-items-center"><CircularProgress size={50} /></div> :
                                <button type="button" className={`Poppins fw_500 color_primary px-4  ${StyleBtn.btn}  d-flex justify-content-center align-items-center`} onClick={handlePdf} ><span><Icon icon="prime:file-pdf" className={`${StyleBtn.icono}`} /></span>  <span>{texts?.modal_button_quote ?? 'Download '}</span></button>
                        }
                        {/* <button type="button" className={`Poppins fw_500 color_primary px-4  ${StyleBtn.btn} ${StyleBtn.btnPopup} d-flex justify-content-center align-items-center`} onClick={() => { setShowemail(true); handleClose() }}> <span><Icon icon="icon-park-outline:send-email" className={`${StyleBtn.icono} me-1`} /></span>   <span>{texts?.modal_button_send_quote ?? 'Send '}</span></button>*/}
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showConfirmar} onHide={handleCloseConfirmar} className="modal-email d-flex justify-content-center align-items-center ">
                <div className={`bg_gray_3 ${Stylemodal.modalconfirmar} `}>
                    {/*<Modal.Header  >
                        <div className={`d-flex justify-content-end w-100 ${Stylemodal.iconClose}`}>
                            <Icon icon='mdi:close' onClick={() => { handleCloseConfirmar() }} />
                        </div>
                    </Modal.Header>
                    */}
                    <Modal.Body >
                        <div className="d-flex flex-column aling-items-center">
                           <span className="text-center color_primary fw700 `raleway">{ texts?.modal_successful_message ?? 'The document has been downloaded correctly'}</span> 
                            <div className="d-flex justify-content-center mt-3">
                                <button type="button" onClick={()=>{setShowConfirmar(false)}} className={`Poppins fw_500 color_primary px-4  ${StyleBtn.btn} w-50  d-flex justify-content-center align-items-center`} > <span>{texts?.modal_boton_closed ?? 'Closed'}</span></button>
                            </div>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>

            <Modal show={showemail} onHide={handleCloseemail} className="modal-email d-flex justify-content-center align-items-center ">

                <Modal.Body className={`bg_gray_3  ${Stylemodal.modalconfirmar} `}>
                    <div className={`d-flex justify-content-end w-100 mb-2 ${Stylemodal.iconClose}`}>
                        <Icon icon='mdi:close' onClick={() => { handleCloseemail() }} />
                    </div>
                    <form action="">
                        <div className={` ${Stylegeneral.colform} mb-3`}>
                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                <div className="d-flex align-items-center justify-content-center w-100 pb-2">
                                    <TitleH3Component styleGroup={`raleway mb-0 py-0 color_primary_2 mb-0 fw_700   me-2  ${Stylemodal.textShere}`}>
                                        {texts?.modal_form_field_quote ?? 'Send quote'}
                                    </TitleH3Component>
                                    <Icon icon="icon-park-outline:send-email" className={`${Stylemodal.icono}`} />
                                </div>
                                {/* <label htmlFor="email" className={`${styleForm.labels} ${Stylegeneral.contentLabel} raleway color_primary_2 `}><span className={styleForm.punto}>*</span>
                                    {texts?.modal_form_field_quote ?? 'Send quote to:'}
    </label>*/}
                                <input type="email" value={to} onChange={(e) => { setTo(e.target.value) }} className={`${styleForm.input_with_linemodal}`} placeholder={texts?.step1_form_placeholder_email ?? 'Email'} name={"email"} />

                            </div>
                            <ValidaComponent />
                        </div>
                        <div className="d-flex justify-content-between  justify-content-md-center">
                            {
                                sending ? <div className=" px-4 me-2 fw_500 Poppins d-flex justify-content-center align-items-center"><CircularProgress size={50} /></div> :
                                    <button disabled={sending} type="button" onClick={() => { handleSend() }} className={`Poppins me-3  fw_500 color_primary px-4 ${StyleBtn.btn} ${StyleBtn.wpbtn} `}>
                                        {texts?.modal_button_send ?? 'Send'}
                                    </button>
                            }

                            <button disabled={sending} type="button" onClick={() => { setShowemail(false); setShow(true) }} className={`Poppins fw_500 color_secundary  px-4 ${StyleBtn.btn}  ${StyleBtn.wpbtn}  `}>
                                {texts?.modal_button_cancel ?? 'Cancel'}
                            </button>
                        </div>

                    </form>
                </Modal.Body>
            </Modal>




        </Fragment>
    )
}
export default ModalEnvioEmailComponent;