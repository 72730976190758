/* eslint-disable eqeqeq */
import React, { Fragment, useEffect, useContext } from "react";
import { TextsContext } from '../../context/textsContext';
import { TitleH1Component } from "../../components/TitleH1Component";
import { TitleH3Component } from "../../components/TitleH3Component";
import Stylegeneral from '../../scss/general.module.scss';
import styleForm from '../../scss/form.module.scss';
import { Icon } from "@iconify/react";
import { TitleH2Component } from "../../components/TitleH2Component";
import { useCountries } from "../../api/quoterApi";
import { useCotizador } from "../../context/apiContext";
import Tooltip from 'react-bootstrap/Tooltip';
import { OverlayTrigger } from "react-bootstrap";
import ValidaComponent from "../../components/ValidaComponent";
import { DateSelectComponent } from "../../components/DateSelectsComponent";
import { translateANT } from "../../helpers/toolsHelper";
const Step3Pages = () => {
    const { texts, verifyCurrentLanguage, getCurrentLanguage } = useContext(TextsContext);
    const { data: countries, getCountryName, countrySort } = useCountries();
    const {
        dependantData: contextState,
        handleOnChangeDependant: handleOnChange,
        changeDate,
        dates
    } = useCotizador();

    const handleChecks = (e) => {
        handleOnChange({ target: { value: e.target.checked, name: e.target.name } })
    }

    useEffect(() => {
        verifyCurrentLanguage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCountries = () => {
        if (!countries) return [];
        if (!(typeof countries.sort === 'function')) return [];
        return countries
            ?.sort(countrySort(getCurrentLanguage()));
    }


    return (
        <Fragment>
            <section id="sectionscroll">
                <div className="container ">
                    <div className="d-block d-lg-none">
                        <TitleH2Component styleGroup={`raleway fw_400 lh_40 color_secundary mb-0 py-2 ${Stylegeneral.titulo_principal}`}>
                            {texts?.general_header_title ?? 'My VUMI VIP Care'}
                        </TitleH2Component>
                    </div>
                    <TitleH1Component styleGroup={`raleway_semibold fw_700  lh_30 color_primary pb-3 ${Stylegeneral.titulo}`}>
                        {texts?.step3_title ?? 'Tell us about your dependents'}
                    </TitleH1Component>

                    <TitleH3Component styleGroup={`Poppins fw_400 color_primary pb-4 ${Stylegeneral.titulo}`}>
                        {texts?.step3_phrase_one ?? 'You may add your spouse/domestic partner or children. The age of the dependent cannot be over 23 years old.'}
                    </TitleH3Component>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className={`${Stylegeneral.rowForm} row gy-5 pb-5`}>
                        <div className="col-12 col-md-4">
                            <div className={`${styleForm.form_group}`}>
                                <label htmlFor="name" className={`${styleForm.labels} raleway color_primary_2`}><span className={styleForm.punto}>*</span>
                                    {texts?.general_form_field_names ?? 'Name'}
                                </label>
                                <input type="text" className={`${styleForm.input_with_line}`} placeholder={texts?.general_form_field_names ?? 'Name'} name={"name"} value={contextState.name} onChange={handleOnChange} />
                                <ValidaComponent dependant={true} keyName={"name"} />
                            </div>

                        </div>
                        <div className="col-12 col-md-4">
                            <div className={`${styleForm.form_group}`}>
                                <label htmlFor="Lastname" className={`${styleForm.labels} raleway color_primary_2`}><span className={styleForm.punto}>*</span>
                                    {texts?.general_form_field_last_names ?? 'Last name'}
                                </label>
                                <input type="text" className={`${styleForm.input_with_line}`} placeholder={texts?.general_form_field_last_names ?? 'Last name'} name={"surname"} value={contextState.surname} onChange={handleOnChange} />
                                <ValidaComponent dependant={true} keyName={"surname"} />
                            </div>

                        </div>

                        <div className="col-12 col-md-4">
                            <div className={`${styleForm.form_group}`}>
                                <div className="  d-flex align-items-center">
                                    <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1  `}>
                                        <span className={styleForm.punto}>*</span>
                                        {texts?.general_form_field_relationship ?? 'Relationship with main applicant'}
                                    </label>
                                </div>

                                <select className={`${styleForm.customselectLine}`} name={"componentId"} value={contextState.componentId} onChange={handleOnChange}>
                                    <option value="">{texts?.general_form_field_select ?? 'Select'}</option>
                                    <option value="15">{texts?.general_form_option_spouse ?? 'Spouse'}</option>
                                    <option value="16">{texts?.general_form_option_children ?? 'Son/Daugther'}</option>
                                    {/* <option value="-16">{texts?.general_form_option_other ?? 'Other'}</option> */}
                                </select>
                                <ValidaComponent dependant={true} keyName={"componentId"} />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className={`${styleForm.form_group}`}>
                                <label htmlFor="gender" className={`${styleForm.labels} raleway mb-3 color_primary_2`}>
                                    <span className={styleForm.punto}>*</span>
                                    {texts?.general_form_field_gender ?? 'Gender'}</label>
                                <div className={`${styleForm.form_radio}`}>
                                    <div className={`${styleForm.custom_radio} pe-4`}>
                                        <label className={`me-2  ${styleForm.Control} ${contextState.gender == 1 ? styleForm.active : ""}`}
                                        >
                                            <span className={`${styleForm.title} } `}></span>
                                            <input type="radio" name={"gender"} value={1} className={styleForm.input} checked={contextState.gender == 1} onClick={handleOnChange} />
                                            <span className={styleForm.checkmark}></span>
                                        </label>
                                        <label htmlFor="femenina" className={`${styleForm.labels} raleway color_secundary raleway`}>{texts?.general_form_option_male ?? 'Male'}</label>
                                    </div>
                                    <div className={`${styleForm.custom_radio} `}>
                                        <label className={`me-2  ${styleForm.Control} ${contextState.gender == 2 ? styleForm.active : ""}`}
                                        >
                                            <span className={`${styleForm.title} } `}></span>
                                            <input type="radio" name={"gender"} className={styleForm.input} value={2} checked={contextState.gender == 2} onClick={handleOnChange} />
                                            <span className={styleForm.checkmark}></span>
                                        </label>
                                        <label htmlFor="masculino" className={`${styleForm.labels} raleway color_secundary raleway`}>{texts?.general_form_option_female ?? 'Female'}</label>
                                    </div>
                                </div>
                                <ValidaComponent dependant={true} keyName={"gender"} />

                            </div>
                        </div>
                        <div className={`col-12 col-md-4 ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.form_group}  ${styleForm.form_h}`}>
                                <label htmlFor="name" className={`${styleForm.labels} mb-0 raleway color_primary_2`}>
                                    <span className={styleForm.punto}>*</span>
                                    {texts?.general_form_field_birth_date ?? 'Date of birth'}</label>
                                {/* <DatePickerComponent style={`${styleForm.customselectLine}`} onChange={handleOnChange} name={"birthday"} value={contextState.birthday} max={new Date()}/> */}
                                {/* <DatePicker 
                                    views={['month', 'day', 'year']} 
                                    value={dates.dependantB} 
                                    onChange={(newValue) => changeDate("dependantB", newValue)}
                                    slotProps={{
                                        textField: {
                                            variant: 'standard',
                                            focused: true,
                                            color: 'primary',
                                          }
                                    }}
                                    /> */}
                                <DateSelectComponent
                                    minYear={(new Date().getFullYear() - 100)}
                                    maxYear={(new Date().getFullYear())}
                                    value={dates.dependantB}
                                    onChange={(newValue) => changeDate("dependantB", newValue)}
                                />
                                <ValidaComponent dependant={true} keyName={"birthday"} />

                            </div>
                        </div>
                        <div className={`col-12 col-md-4 ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                <div className="  d-flex align-items-center">
                                    <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1  `}>
                                        <span className={styleForm.punto}>*</span>
                                        {texts?.general_form_field_country ?? 'Country of residence'}</label>

                                </div>

                                <select className={`${styleForm.customselectLine}`} name={"country"} value={contextState.country} onChange={handleOnChange}>
                                    <option value={0} selected={true} disabled={true}>{texts?.general_form_field_select ?? 'Select'}</option>
                                    {
                                        getCountries()
                                            ?.map(country => (
                                                <option key={country.id} value={country.isoNum}>{country.isoNum === "530" ? translateANT(getCurrentLanguage()) : (getCountryName(country.isoNum, getCurrentLanguage()) ?? country.description)}</option>
                                            ))
                                    }
                                </select>
                                <ValidaComponent dependant={true} keyName={"country"} />

                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className={`${styleForm.btn_activar}`}>
                                <div className={`d-flex align-items-center mb-2 ${styleForm.con_tootip}`}>
                                    <label htmlFor="seclet" className={`${styleForm.labels} raleway  color_primary_2 pe-1 `}>
                                        {texts?.general_form_field_light_rider ?? 'Travel VIP Light Rider '}
                                    </label>
                                    <OverlayTrigger
                                        key="top"
                                        placement="top"
                                        overlay={<Tooltip id="tooltip-top" className="custom-tooltip">
                                            {texts?.general_form_coverage_paragraph ?? 'Coverage of up to US $5,000 without deductible for emergency treatmen in an emergency room, hospital, convenience or outpatient clinic, or emergency center, when the insured is traveling outside his/her country of residence. This coverage also includes immediate follow-up treatments related to this emergency. For any expenses that exceeds the US $5,000 maximun Benefit, the remaining deductible of the policy Will apply. This Benefit Will provide coverage for 30 consecutive days, or twice during a policy year.'}
                                        </Tooltip>}>
                                        <Icon icon={'mdi:information-outline'} className={` color_gray_5 ${styleForm.iconselect}`} />
                                    </OverlayTrigger>
                                </div>
                                <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                    <input onChange={handleChecks} name={'travel'} checked={contextState.travel} className="form-check-input" type="checkbox" id="need" />
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-12 col-md-4 ">

                        <div className="col-12 col-md-4 d-none">
                            <div className={`${styleForm.form_group}`}>
                                <label htmlFor="name" className={`${styleForm.labels} mb-0 raleway color_primary_2`}>Coverage start date</label>
                                <div className="d-flex align-items-center">
                                    <input type="date" className={`${styleForm.customselectLine}`} />
                                </div>
                            </div>
                                </div>*/}
                    </div>
                </div>
            </section>
        </Fragment>
    )
}
export default Step3Pages;
