/* eslint-disable eqeqeq */
import React, { useContext } from "react";
import { TextsContext } from '../context/textsContext';
import style from "../scss/step10.module.scss";
import { Icon } from "@iconify/react";
import { useCotizador } from "../context/apiContext";
import { useCountries } from "../api/quoterApi";
import { useNavigate } from "react-router-dom";
import { useSteps } from "../hooks/useSteps";

export const DependantsComponent = () => {
  const { texts, getCurrentLanguage } = useContext(TextsContext);
  const {
    dependants,
    deleteDependant,
    startEdit,
  } = useCotizador();
  const { getAllSteps } = useSteps();
  const { data: countries } = useCountries();

  const { getCountryName } = useCountries();

  const handleDelete = (dependant) => {
    deleteDependant(dependant)
  }

  const navigate = useNavigate();
  const handleEdit = (dependant) => {
    startEdit(dependant);
    const currentPath = window.location.pathname;
    const currentPathIndex = getAllSteps().find(i => i.path === currentPath)?.index;
    navigate(getAllSteps()[currentPathIndex + 1].path);
  }

  const getRelation = (dependant) => {
    return texts?.general_form_option_spouse ?? '...';
  }

  const getDependant = (dependant) => {
    return dependant.componentId == "-16" ? (texts?.general_form_option_other ?? 'Other') : (texts?.general_form_option_children ?? 'Son/Daugther');
  }

  return (
    <section className="mb-4">
      <div className="container responsive">
        <div className={style.table_container}>
          <div className={style.tabla}>
            <div className={`${style.tabla_row} mb-3`}>
              <div className={`${style.tabla_cellHeader} raleway_bold`}>{texts?.general_form_field_name ?? 'Name'}</div>
              <div className={`${style.tabla_cellHeader} raleway_bold`}>{texts?.general_form_field_last_name ?? 'Last name'}</div>
              <div className={`${style.tabla_cellHeader} raleway_bold`}>{texts?.general_form_field_relationship ?? 'Relationship with main applicant'}</div>
              <div className={`${style.tabla_cellHeader} raleway_bold`}>{texts?.general_form_field_gender ?? 'Gender'}</div>
              <div className={`${style.tabla_cellHeader} raleway_bold`}>{texts?.general_form_field_birth_date ?? 'Date of birth'}</div>
              <div className={`${style.tabla_cellHeader} raleway_bold`}>{texts?.general_form_field_country ?? 'Country of residence'}</div>
              <div className={`${style.tabla_cellHeader} raleway_bold`}>{texts?.general_form_field_light_rider ?? 'Travel VIP Light Rider'}</div>
              <div className={`${style.tabla_cellHeader} raleway_bold`}></div>
            </div>

            {
              dependants.map((dependant, index) => (
                <div className={`${style.tabla_row} mb-2 raleway`} key={dependant.id}>
                  <div className={style.tabla_cell}>{dependant.name}</div>
                  <div className={style.tabla_cell}>{dependant.surname}</div>
                  <div className={style.tabla_cell}>{dependant.componentId == 15 ? getRelation(dependant) : getDependant(dependant)}</div>
                  <div className={style.tabla_cell}>{dependant.gender == 1 ? texts?.general_form_option_male ?? 'Male' : texts?.general_form_option_female ?? 'Female'}</div>
                  <div className={style.tabla_cell}>{dependant.birthdayShow}</div>
                  <div className={style.tabla_cell}>{getCountryName(dependant.country, getCurrentLanguage())}</div>
                  <div className={style.tabla_cell}>{dependant.travel ? texts?.general_form_option_yes ?? 'Yes' : texts?.general_form_option_no ?? 'No'}</div>
                  <div className={`${style.tabla_cell} ${style.container_btn}`}>
                    <button onClick={() => { handleEdit(dependant.id) }} type="button" className={`${style.button}`}>
                      <Icon icon='mdi:pencil' />
                      <span>{texts?.footer_button_edit ?? 'Edit'}</span>
                    </button>
                    <button onClick={() => { handleDelete(dependant.id) }} type="button" className={`${style.button} ms-xl-4`}>
                      <Icon icon='mdi:close' />
                      <span>{texts?.button_eliminar ?? 'Eliminar'}</span>
                    </button>
                  </div>

                </div>
              ))
            }
          </div>
        </div>
      </div>
      <div className="container antiresponsive">
        <div className={style.table_mobile}>
          {
            dependants.map((dependant, index) => (
              <div className={`${style.tabla_row_mobile} raleway`} key={dependant.id}>
                <div className={style.tabla_cell_mobile}> <span>{texts?.general_form_field_name ?? 'Name'}</span>:  {dependant.name}</div>
                <div className={style.tabla_cell_mobile}> <span>{texts?.general_form_field_last_name ?? 'Last name'}</span>: {dependant.surname}</div>
                <div className={style.tabla_cell_mobile}> <span>{texts?.general_form_field_relationship ?? 'Relationship with main applicant'}</span>: {dependant.componentId == 15 ? getRelation(dependant) : getDependant(dependant)}</div>
                <div className={style.tabla_cell_mobile}> <span>{texts?.general_form_field_gender ?? 'Gender'}</span>: {dependant.gender == 1 ? texts?.general_form_option_male ?? 'Male' : texts?.general_form_option_female ?? 'Female'}</div>
                <div className={style.tabla_cell_mobile}> <span>{texts?.general_form_field_birth_date ?? 'Date of birth'}</span>: {dependant.birthdayShow}</div>
                <div className={style.tabla_cell_mobile}> <span>{texts?.general_form_field_country ?? 'Country of residence'}</span>: {getCountryName(dependant.country, getCurrentLanguage())}</div>
                <div className={style.tabla_cell_mobile}> <span>{texts?.general_form_field_light_rider ?? 'Travel VIP Light Rider'}</span>: {dependant.travel ? texts?.general_form_option_yes ?? 'Yes' : texts?.general_form_option_no ?? 'No'}</div>
                <div className={`${style.tabla_cell_mobile_buttons} ${style.container_btnMobile}`}>
                  <button onClick={() => { handleEdit(dependant.id) }} type="button" className={`${style.button}`}>
                    <Icon icon='mdi:pencil' />
                    <span>{texts?.footer_button_edit ?? 'Edit'}</span>
                  </button>
                  <button onClick={() => { handleDelete(dependant.id) }} type="button" className={`${style.button}`}>
                    <Icon icon='mdi:close' />
                    <span>{texts?.button_eliminar ?? 'Eliminar'}</span>
                  </button>
                </div>

              </div>
            ))
          }
        </div>
      </div>

    </section>
  );
}
