import { TextsContext } from "context/textsContext";
import { useContext } from "react";

export const useLoginLoader = () => {
    const { texts, loaded } = useContext(TextsContext);

    const isDisabled = () => {
        return loaded && JSON.parse(texts?.des_key) === true;
    }

    return {
        isDisabled
    }
}
