import React, { Fragment, useState, useEffect, useContext } from "react";
import { TextsContext } from "context/textsContext";
import styleForm from '../../../scss/form.module.scss';
import { SelectAppComponent } from "../../../components/application/SelectAppComponent";
import ValidaComponent, { APPLICATION } from "../../../components/ValidaComponent";
import Stylegeneral from '../../../scss/general.module.scss'
import { InputTextComponent } from "../../../components/application/InputTextComponent";
import { useLocation } from "react-router-dom";
import { CheckboxComponent } from "../../../components/CheckboxComponent";

const Registro4Page = () => {
    const { texts, verifyCurrentLanguage } = useContext(TextsContext);
    const {pathname} = useLocation();
    const [getCheck, setGetCheck] = useState([
        { id: 1, text: texts?.page_four_option_normal ?? 'Normal', checked: false },
        { id: 2, text: texts?.page_four_option_abnormal ?? 'Anormal', checked: false }
    ]);

    const handleCheckboxChange = (id) => {
        // Copia el arreglo de elementos y actualiza el estado de la casilla de verificación
        const updatedItems = getCheck.map(item => {
            if (item.id === id) {
                return { ...item, checked: !item.checked };
            }
            return item;
        });
        setGetCheck(updatedItems);
    };

    useEffect(() => {
		verifyCurrentLanguage();
	}, []);

    return (
        <Fragment>
            <section>
                <div className="container py-3">
                    <div className={`${styleForm.btn_activar}`}>
                        <label htmlFor="solicitantes" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                            {texts?.page_four_checkbox_one ?? '¿Alguno de los solicitantes se ha realizado algún examen que no sea rutinario en los últimos 5 años? En caso afirmativo, indique:'}
                        </label>
                        <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                            <CheckboxComponent className="form-check-input" id="solicitantes" name={"have5yearPriorExam"} section={"/coberturaprevia"} />
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-5">
                <div className="container">
                    <div className="row gy-4">
                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <SelectAppComponent
                                name="solicitante"
                                label={texts?.general_form_field_applicant ?? "Solicitante"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                placeholder='Ej:Colombia'
                                type='text'
                                options={[]}
                                section={pathname}
                            />

                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"solicitante"} />
                        </div>

                        <div className={`col-12 ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={pathname}
                                name="Texamen"
                                label={texts?.page_four_form_field_exam ?? "Tipo de examen"}
                                placeholder={texts?.page_four_form_placeholder_exam ?? "Ej: Examen general"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                type='text'
                                required
                            />
                            <ValidaComponent keyName={"Texamen"} phase={APPLICATION} section={pathname} />
                        </div>

                        <div className={`col-12 ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={pathname}
                                name="Mexamen"
                                label={texts?.page_four_form_field_reason ?? "Motivo del examen"}
                                placeholder={texts?.page_four_form_placeholder_reason ?? "Ej: Control"}
                                tooltip="Geographic area where the policy´s benefits apply"
                                type='text'
                                required
                            />
                            <ValidaComponent keyName={"Mexamen"} phase={APPLICATION} section={pathname} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <div className={`d-flex align-items-center ${styleForm.form_h} `}>
                                <div className={`d-flex justify-content-between align-items-center pe-3 ${Stylegeneral.contentLabel} pb-4 pb-md-2`}>
                                    <label htmlFor="resultado" className={`${styleForm.labels} ${Stylegeneral.contentLabel} raleway color_primary fw_700`}>
                                        {texts?.page_four_result ?? 'Resultado'}
                                    </label>
                                </div>

                                {getCheck.map(item => (
                                    <label className={`pe-3 checkbox blue pb-4 pb-md-2 d-flex align-items-center ${styleForm.labels} ${Stylegeneral.contentLabel} raleway color_primary fw_400`} key={item.id}>
                                        <input
                                            type="checkbox"
                                            className='me-2'
                                            checked={item.checked}
                                            onChange={() => handleCheckboxChange(item.id)}
                                        />
                                        <span className='indicator'></span>
                                        <span>{item.text}</span>
                                    </label>
                                ))}
                            </div>
                        </div>
                        <div className={`col-12    ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.btn_activar}`}>
                                <label htmlFor="examenes" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                    {texts?.page_four_checkbox_two ?? '¿Actualmente se está realizando otros exámenes para comprobar el resultado de un diagnóstico?'}
                                </label>
                                <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                    <CheckboxComponent className="form-check-input" type="checkbox" id="examenes" name={"addDependant"} section={pathname} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}
export default Registro4Page;