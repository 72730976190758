import React, { Fragment, useEffect, useContext } from "react";
import { TextsContext } from "context/textsContext";
import styleForm from '../../../scss/form.module.scss';
import { InputTextComponent } from "../../../components/application/InputTextComponent";
import ValidaComponent, { APPLICATION } from "../../../components/ValidaComponent";
import Stylegeneral from '../../../scss/general.module.scss';

const MAIN_APPLICANT = "mainApplicant";
const Registro2Page = () => {
    const { texts, verifyCurrentLanguage } = useContext(TextsContext);

    useEffect(() => {
		verifyCurrentLanguage();
	}, []);

    return (
        <Fragment>
            <div className="container my-4">
                <div className={`${styleForm.btn_activar} pb-3`}>
                    <label htmlFor="cobertura" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                        {texts?.page_two_checkbox_one ?? '¿Ha tenido cobertura médica con VUMI® o cualquiera de sus afiliadas?'}
                    </label>
                    <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                        <input className="form-check-input" type="checkbox" id="cobertura" name={"addDependant"} />
                    </div>
                </div>

                <div className="row gy-4">
                    <div className={`col-12 col-md-6 col-lg-4  ${Stylegeneral.colform}`}>
                        <InputTextComponent
                            section={MAIN_APPLICANT}
                            name="numeropoliza"
                            label={texts?.page_one_form_field_policy ?? "Número de poliza"}
                            placeholder={texts?.page_one_form_placeholder_policy ?? "Ej:132342323"}
                            tooltip="Geographic area where the policy´s benefits apply"
                            type='number'
                        />
                        <ValidaComponent keyName={"numeropoliza"} phase={APPLICATION} section={MAIN_APPLICANT} />
                    </div>
                </div>

            </div>

        </Fragment>
    )
}

export default Registro2Page;