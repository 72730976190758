import React, { Fragment, useState, useContext, useEffect } from "react";
import { TextsContext } from "../../../context/textsContext";
import style from '../../../scss/InfoSolicitudo.module.scss';
import { TitleH6Component } from "../../../components/TitleH6Component";
import { Icon } from "@iconify/react";
import { TitleH2Component } from "../../../components/TitleH2Component";
import Stylegeneral from '../../../scss/general.module.scss'
import { TitleH3Component } from "../../../components/TitleH3Component";
import Picture from "../../../components/PictureComponent";
import styleForm from '../../../scss/form.module.scss';
import ValidaComponent, { APPLICATION } from "../../../components/ValidaComponent";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ParagraphComponent } from "../../../components/ParagraphComponent";
import { SelectAppComponent } from "../../../components/application/SelectAppComponent";
import { useCountries } from "../../../api/quoterApi";
import { InputTextComponent } from "../../../components/application/InputTextComponent";
import { useApplication } from "../../../context/applicationContext";
import { useLocation } from "react-router-dom";
import { DateAppComponent } from "../../../components/application/DateAppComponent";
import { InputComponent as InputSingleComponent } from "../../../components/application/InputComponent";
import { CheckboxComponent } from "../../../components/CheckboxComponent";

const InfoSolicitudoPage = () => {
    const { texts, verifyCurrentLanguage, getCurrentLanguage } = useContext(TextsContext);
    const [isMobileDevice, setIsMobileDevice] = useState(false);
    const { data: countries, countrySort, getCountryName } = useCountries();
    const { pathname } = useLocation();
    const genero = { Masculino: 1, Femenino: 2 }

    useEffect(() => {
        verifyCurrentLanguage();
    }, []);

    const getOptions = () => {
        if (!countries) return [];
        if (!(typeof countries.sort === 'function')) return [];
        return countries
            ?.sort(countrySort(getCurrentLanguage()))
            ?.reduce((before, next) => {
                before[getCountryName(next.isoNum, getCurrentLanguage()) ?? next.description] = next.isoNum;
                return before;
            }, {});
    }

    return (
        <Fragment>
            <section>
                <div className="container">
                    <div className="d-flex justify-content-center py-3  ">
                        <div className={`${style.titulos_principales}`}>
                            <div className="d-flex align-items-center justify-content-center ">
                                <Picture src="/img/document-protection-hand.png" alt="VUMI" styleGroup={`${style.logosimg} w-auto mb-0 me-2`} />
                                <TitleH2Component styleGroup={`raleway_bold color-azul2 lh_30   mb-0  `}>
                                    {texts?.application_info_title ?? 'Información del Solicitante'}
                                </TitleH2Component>
                            </div>
                        </div>
                    </div>
                    <TitleH3Component styleGroup={`Poppins fw_400 color_primary pb-2 mb-0 `}>
                        {texts?.application_info_subtitle ?? 'Campos obligatorios (*) check son obligatorios'}
                    </TitleH3Component>
                </div>

            </section>
            <section className="pb-5">
                <div className="container px-3">
                    <div className="row gy-3">
                        <div className={`col-12 ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={pathname}
                                name="name"
                                label={texts?.application_info_form_field_name ?? 'Nombre(s)'}
                                placeholder={texts?.application_info_form_placelholder_name ?? 'Ingresar nombre'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                type='text'
                                required={false}
                                disabled={true}
                            />
                            <ValidaComponent keyName={"name"} phase={APPLICATION} section={pathname} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={pathname}
                                name="surname"
                                label={texts?.application_info_form_field_surname ?? 'Apellido(s)'}
                                placeholder={texts?.application_info_form_placeholder_surname ?? 'Ingresar apellidos'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                required={false}
                                disabled={true}
                                type='text'
                            />

                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"surname"} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={pathname}
                                name="numpasaporte"
                                label={texts?.application_info_form_field_passport ?? 'Número de pasaporte o identificación'}
                                placeholder={texts?.application_info_form_placeholder_passport ?? 'Ej:8987-9988'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                required
                                type='text'
                            />

                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"numpasaporte"} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={pathname}
                                name="direccion"
                                label={texts?.application_info_form_field_address ?? 'Dirección'}
                                placeholder={texts?.application_info_form_placeholder_address ?? 'Ej:calle 147 no 94c 75 t 2 apt 307'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                required
                                type='text'
                            />

                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"direccion"} />
                        </div>

                        <div className={`col-12 ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={pathname}
                                name="ciudad"
                                label={texts?.application_info_form_field_city ?? 'Ciudad'}
                                placeholder={texts?.application_info_form_placeholder_city ?? 'Ej:Bogotá'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                required
                                type='text'
                            />

                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"ciudad"} />
                        </div>
                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={pathname}
                                name="provincia"
                                label={texts?.application_info_form_field_state ?? 'Provincia o estado'}
                                placeholder={texts?.application_info_form_placeholder_state ?? 'Ej:Bogotá'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                required
                                type='text'
                            />

                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"provincia"} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={pathname}
                                name="zonapostal"
                                label={texts?.application_info_form_field_postal ?? 'Zona postal'}
                                placeholder={texts?.application_info_form_placeholder_postal ?? 'Ej:110897'}
                                numeric={true}
                                tooltip="Geographic area where the policy´s benefits apply"
                                required
                                type='text'
                            />
                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"zonapostal"} />
                        </div>

                        <div className={`col-12 ${Stylegeneral.colform}`}>
                            <SelectAppComponent
                                section={pathname}
                                name="pais"
                                label={texts?.application_info_form_field_country ?? 'País'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                required={false}
                                disabled={true}
                                type='text'
                                options={
                                    getOptions()
                                }
                            />

                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"pais"} />
                        </div>

                        <div className={`col-12 ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={pathname}
                                name="paisn"
                                label={texts?.application_info_form_field_country_two ?? 'País de nacionalidad'}
                                placeholder='Seleccione'
                                tooltip="Geographic area where the policy´s benefits apply"
                                required
                                type='text'
                            />

                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"paisn"} />
                        </div>

                        <div className={`col-12 ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={pathname}
                                name="Toficina"
                                label={texts?.application_info_form_field_phone ?? 'Teléfono de oficina'}
                                placeholder={texts?.application_info_form_placeholder_phone ?? 'Ej:5450 45654'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                required
                                type='text'
                                numeric={true}
                                limit={20}
                                minimum={6}
                            />
                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"Toficina"} />
                        </div>

                        <div className={`col-12 ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={pathname}
                                name="Tmovil"
                                label={texts?.application_info_form_field_phone_two ?? 'Teléfono móvil'}
                                placeholder={texts?.application_info_form_placeholder_phone_two ?? 'Ej:5450 45654'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                required
                                type='text'
                                numeric={true}
                                limit={20}
                                minimum={6}
                            />

                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"Tmovil"} />

                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={pathname}
                                name="Tcasa"
                                label={texts?.application_info_form_field_phone_three ?? 'Teléfono de casa'}
                                placeholder={texts?.application_info_form_placeholder_phone_three ?? 'Ej:5450 45654'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                required
                                type='text'
                                numeric={true}
                                limit={20}
                                minimum={6}

                            />
                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"Tcasa"} />
                        </div>

                        <div className={`col-12 ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={pathname}
                                name="fax"
                                label={texts?.application_info_form_field_fax ?? 'Fax'}
                                placeholder={texts?.application_info_form_placeholder_fax ?? 'Ej:5450 45654'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                required
                                type='text'
                                numeric={true}
                                limit={20}
                                minimum={6}
                            />

                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"fax"} />
                        </div>
                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={pathname}
                                name="email"
                                label={texts?.application_info_form_field_email ?? 'Correo electrónico'}
                                placeholder={texts?.application_info_form_placeholder_email ?? 'Ingresar email'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                required
                                type='email'
                            />
                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"email"} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={pathname}
                                name="ocupacion"
                                label={texts?.application_info_form_field_occupation ?? 'Ocupación'}
                                placeholder={texts?.application_info_form_placeholder_occupation ?? 'Ej:Publicidad'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                type='text'
                            />
                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"ocupacion"} />
                        </div>

                        <div className={`col-12   ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                    <label htmlFor="fnacimiento" className={`${styleForm.labels} ${Stylegeneral.contentLabel} raleway color_primary fw_700`}>
                                        {texts?.application_info_form_field_birth_date ?? 'Fecha de nacimiento'}
                                    </label>
                                    <OverlayTrigger
                                        key={isMobileDevice ? 'left' : 'top'}
                                        placement={isMobileDevice ? 'left' : 'top'}
                                        overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{"Geographic area where the policy´s benefits apply"}</Tooltip>}
                                    >
                                        <Icon icon={'mdi:information-outline'} className={`color_gray_5 ${styleForm.iconselect}`} />
                                    </OverlayTrigger>
                                </div>
                                <DateAppComponent
                                    minYear={(new Date().getFullYear() - 100)}
                                    maxYear={(new Date().getFullYear() - 18)}
                                    section={pathname}
                                    name={"fnacimiento"}
                                    label="Ocupación"
                                    tooltip="Geographic area where the policy´s benefits apply"
                                    placeholder='Ej:Publicidad'
                                    type='date'
                                    required={false}
                                    disabled={true}
                                    className={`${styleForm.input_with_line} raleway fw_400`}
                                />
                                {/* <input value={applicationState.birthday} type="date" className={`${styleForm.input_with_line} raleway fw_400`} placeholder={'Ej:Publicidad'} name={"fnacimiento"} onChange={handleChecks} required /> */}

                            </div>
                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"fnacimiento"} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <SelectAppComponent
                                section={pathname}
                                name="gender"
                                label={texts?.application_info_form_field_gender ?? 'Género'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                options={genero}
                                required={false}
                                disabled={true}
                            />

                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"gender"} />
                        </div>

                        <div className={`col-12 ${Stylegeneral.colform}`}>
                            <SelectAppComponent
                                section={pathname}
                                name="estadoCivil"
                                label={texts?.application_info_form_field_civil_status ?? 'Estado civíl'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                options={genero}
                                required
                            />

                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"estadoCivil"} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={pathname}
                                name="estatura"
                                label={texts?.application_info_form_field_height ?? 'Estatura'}
                                placeholder={texts?.application_info_form_placeholder_height ?? 'Ej:1,50'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                required
                                type="text"
                                numeric={true}

                            />
                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"estatura"} />
                        </div>

                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <InputTextComponent
                                section={pathname}
                                name="peso"
                                label={texts?.application_info_form_field_weight ?? 'Peso'}
                                placeholder={texts?.application_info_form_placeholder_weight ?? 'Ej: 50 kilos'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                required
                                type="text"
                                numeric={true}
                            />

                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"peso"} />
                        </div>


                        <div className="col-12  ">
                            <div className={`${styleForm.btn_activar}`}>
                                <label htmlFor="need" className={`${styleForm.labels} ${styleForm.sin_tootip} raleway  mb-2 color_primary_2 fw_700`}>
                                    {texts?.application_info_form_field_checkbox ?? '¿Alguno de los solicitantes dependientes está actualmente viviendo fuera del país de residencia del solicitante principal?'}
                                </label>
                                <div className={`form-check form-switch input-personalizado ${styleForm.col_input}`}>
                                    <CheckboxComponent
                                        type="checkbox"
                                        className={`form-check-input`}
                                        placeholder={'Ingresar nombre'}
                                        name={"addDependant"}
                                        section={pathname}
                                        id={"need"}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <div className={`${styleForm.form_group} ${styleForm.form_h}`}>
                                <div className={`d-flex align-items-center ${Stylegeneral.contentLabel}`}>
                                    <label htmlFor="namebeneficiario" className={`${styleForm.labels} ${Stylegeneral.contentLabel} raleway color_primary fw_700`}><span className={styleForm.punto}>*</span>
                                        {texts?.application_info_form_field_beneficiary ?? 'Nombre completo del beneficiario'}
                                    </label>
                                    <OverlayTrigger
                                        key={isMobileDevice ? 'left' : 'top'}
                                        placement={isMobileDevice ? 'left' : 'top'}
                                        overlay={<Tooltip id="tooltip-top" className="custom-tooltip">{"Geographic area where the policy´s benefits apply"}</Tooltip>}
                                    >
                                        <Icon icon={'mdi:information-outline'} className={`color_gray_5 ${styleForm.iconselect}`} />
                                    </OverlayTrigger>
                                </div>

                                <InputSingleComponent
                                    type="text"
                                    className={`${styleForm.input_with_line} raleway fw_400 mb-1 mb-md-0`}
                                    placeholder={texts?.application_info_form_placeholder_beneficiary ?? 'Ingresar nombre'}
                                    name={"namebeneficiario"}
                                    required={true}
                                    section={pathname}
                                />
                                <ParagraphComponent styleGroup={'raleway color_primary_2 mb-0'}>{texts?.application_info_beneficiary_phrase ?? '(para recibir pagos en nombre del Asegurado Titular)'}</ParagraphComponent>

                            </div>
                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"namebeneficiario"} />

                        </div>
                        <div className={`col-12  ${Stylegeneral.colform}`}>
                            <SelectAppComponent
                                section={pathname}
                                name="idioma"
                                label={texts?.application_info_form_field_language ?? 'Idioma'}
                                tooltip="Geographic area where the policy´s benefits apply"
                                options={genero}
                                required
                            />
                            <ValidaComponent phase={APPLICATION} section={pathname} keyName={"idioma"} />
                        </div>
                    </div>

                </div>

            </section>
        </Fragment >
    )
}
export default InfoSolicitudoPage;