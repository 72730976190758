import React, { useState, useEffect, useContext } from "react";
import { TextsContext } from '../../context/textsContext';
import { Fragment } from "react";
import Stylegeneral from '../../scss/general.module.scss';
import styleStepByStep from '../../scss/stepByStep.module.scss';
import styleTexto from '../../scss/text.module.scss';
import styleForm from '../../scss/form.module.scss';
import { TitleH2Component } from "../../components/TitleH2Component";
import { TitleH1Component } from "../../components/TitleH1Component";
import { TitleH3Component } from "../../components/TitleH3Component";
import SelectBenefitsComponent from "../../components/SelectBenefitsComponent";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { useCotizador } from "../../context/apiContext";
import { useSteps } from "../../hooks/useSteps";
import SelectBenefitsDummyComponent from "../../components/SelectBenefitsDummyComponent";
import OptionBenefitsDummyComponent from "../../components/OptionBenefitsDummyComponent";
import { arrayIncludesNumber } from "../../helpers/toolsHelper";
import { NavLink } from "react-router-dom";

const Step12Page = () => {
    const { texts, verifyCurrentLanguage } = useContext(TextsContext);
    /*const { benefits, selectedBenefitsIds } = useCotizador();
    const [isMobileDevice, setIsMobileDevice] = useState(false);
    const { getBenefitsPerStep } = useSteps();
    const [mostarPais, setMostrarPais] = useState(false);
    const [Providers, setProviders] = useState(false);

    useEffect(() => {
        verifyCurrentLanguage();
    }, []); */

    
    return (
        <Fragment>
            <div className={styleStepByStep.wp}>
                <ul className={styleStepByStep.list}>
                    <li className={styleStepByStep.listItem}>
                        <NavLink to="/" className={styleStepByStep.listLink}>
                            <span className={styleStepByStep.paginator}></span>
                            <span className={styleStepByStep.txt}>Información del Solicitante</span>
                        </NavLink>
                    </li>
                    <li className={styleStepByStep.listItem}>
                        <NavLink to="/" className={styleStepByStep.listLink}>
                            <span className={styleStepByStep.paginator}></span>
                            <span className={styleStepByStep.txt}>Cobertura</span>
                        </NavLink>
                    </li>
                    <li className={styleStepByStep.listItem}>
                        <NavLink to="/" className={styleStepByStep.listLink}>
                            <span className={styleStepByStep.paginator}></span>
                            <span className={styleStepByStep.txt}>Información de Dependientes</span>
                        </NavLink>
                    </li>
                    <li className={styleStepByStep.listItem}>
                        <NavLink to="/" className={styleStepByStep.listLink}>
                            <span className={styleStepByStep.paginator}></span>
                            <span className={styleStepByStep.txt}>Información de Cobertura Previa</span>
                        </NavLink>
                    </li>
                    <li className={styleStepByStep.listItem}>
                        <NavLink to="/" className={styleStepByStep.listLink}>
                            <span className={styleStepByStep.paginator}></span>
                            <span className={styleStepByStep.txt}>Historia Familiar</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
            
            <section >
                <div className="container">
                    <div className="d-block d-lg-none">
                        <TitleH2Component styleGroup={`raleway fw_400 lh_40 color_secundary mb-0 py-2 ${Stylegeneral.titulo_principal}`}>
                            {texts?.general_header_title ?? 'My VUMI VIP Care'}
                        </TitleH2Component>
                    </div>
                    <div className={ `${styleTexto.cuadro_texto} p-2 mb-4 mb-md-3`}>
                        <TitleH3Component styleGroup={`Poppins fw_400 color_primary mb-0 ${styleTexto.tamaSpecial}`}>
                            {texts?.step1_paragraph ?? 'MY VIP Care plan comes with a comprehensive base plan which includes extensive benefits, and lets you customize others based on your specific needs. Having selected your base plan options, you can choose additional benefits as needed, including outpatient services, maternity, and organ transplant.'}
                        </TitleH3Component>
                    </div>
                </div>
            </section>

        </Fragment>
    )
}

export default Step12Page;
