import React, { Fragment, useEffect, useContext } from "react";
import { TextsContext } from "../../../context/textsContext";
import { TitleH2Component } from "../../../components/TitleH2Component";
import style from '../../../scss/InfoSolicitudo.module.scss';
import Stylegeneral from '../../../scss/general.module.scss'
import styleTex from '../../../scss/text.module.scss';
import { ParagraphComponent } from "../../../components/ParagraphComponent";
import { TitleH6Component } from "../../../components/TitleH6Component";
import styleForm from '../../../scss/form.module.scss';
import GestorArchivosComponent from "./components/GestorArchivosComponent";
import { InputComponent } from "../../../components/application/InputComponent";
import { CheckboxComponent } from "../../../components/CheckboxComponent";
import { useLocation } from "react-router-dom";
const ReconocimientosPage = () => {
    const { texts, verifyCurrentLanguage } = useContext(TextsContext);
    const {pathname} = useLocation();
    const text =
        [
            {
                id: 1,
                texto: texts?.recognitions_initial_paragraph_one ?? ' He leído, entiendo completamente la presente solicitud y firmo libre y voluntariamente como mi aceptación de todo el contenido de esta solicitud. Asimismo, declaro que la información personal y médica que he incluido en la presente solicitud es verdadera, completa y precisa, por lo que certifico que no he omitido, ocultado, modificado o alterado esta información. Estoy plenamente consciente y acepto que en caso de que se haya realizado cualquier omisión, ocultación, modificación o alteración de la información declarada en la presente solicitud, o información que no haya sido declarada posteriormente hasta antes de la fecha de efectividad de la póliza, será considerada como un acto de mala fe al aceptar mis obligaciones contractuales y puede ocasionar que las reclamaciones sean negadas y que la póliza sea modificada, rescindida o cancelada, para lo cual bastará una simple comunicación de VUMI® Group, I.I., VUMI® Group, I.I. se reserva el derecho de tomar medidas legales y administrativas en caso de cualquier indemnización.'
            },
            {
                id: 2,
                texto: texts?.recognitions_initial_paragraph_two ?? ' Entiendo que la Compañía se reserva el derecho de rechazar esta solicitud basado en la información que presenté sobre la residencia o el estado de salud de cualquiera de los solicitantes, o por cualquier otra razón que la Compañía considere relevante. Esta solicitud es válida por sesenta (60) días a partir del día en que se firmó. Si deseo retirar mi solicitud del proceso de suscripción, tendré que enviar una notificación por escrito a VUMI® Group, I.I. o sus representantes autorizados dentro de los quince (15) días posteriores a la suscripción de la solicitud.'
            },
            {
                id: 3,
                texto: texts?.recognitions_initial_paragraph_three ?? ' Entiendo que estoy solicitando cobertura de seguro médico internacional que puede no proveer los beneficios obligatorios requeridos por las regulaciones de mi país de residencia o cualquier otra jurisdicción. Tendré quince (15) días para revisar la cobertura ofrecida por la Compañía y si no estoy satisfecho, podré devolver la póliza a la Compañía y recibir un reembolso de la prima neta pagada.'
            }
        ]

    const informacion =
        [
            {
                id: 1,
                titulo: texts?.recognitions_authorization_subtitle ?? 'Autorización para recaudar y revelar información sobre mi salud',
                texto: [
                    {
                        id: 1,
                        text: texts?.recognitions_authorization_paragraph_one ?? 'Por este medio autorizo a VUMI® o VUMI® Group, I.I. y a sus subsidiarias y filiales a solicitar mis registros médicos y/o los de mis dependientes, así como también cualquier historial de medicamentos recetados y cualquier otra información médica o farmacéutica para ser considerada en el proceso de evaluación de riesgos con respecto a la solicitud de seguro de salud para mí y mis dependientes.'
                    },
                    {
                        id: 2,
                        text: texts?.recognitions_authorization_paragraph_two ?? 'Autorizo e instruyo a cualquier médico, hospital, laboratorio, farmacia o cualquier otro proveedor médico, compañía de seguros, si he tenido algún seguro previo o cuento con algún otro plan de seguro médico, agencia gubernamental, empleado, administrador de beneficios, organización a la cual represento y tengo autorización legal, o persona, incluyendo cualquier familiar que tenga registros médicos o conocimiento sobre mi persona o mi salud, a revelar dicha información a VUMI® o VUMI® Group, I.I. o sus representantes designados. Así mismo, autorizo a VUMI® o VUMI® Group, I.I., sus subsidiarias, filiales y representantes designados a revelar a mi agente/agencia de seguros, afiliados y sucesores las condiciones de mi póliza, mi certificado de cobertura y otros documentos de seguro, información de pagos, reclamaciones, solicitudes de reembolso y registros médicos que puedan contener información de salud protegida que les permitirá atender a mis preguntas y facilitar la interacción relacionada con mi cobertura de seguro, pagos y reclamaciones. Entiendo que existe la posibilidad de que cualquier información pueda volver a ser divulgada de conformidad con esta autorización y que la información, una vez revelada, puede que ya esté protegida por las regulaciones federales de los Estados Unidos que rigen la privacidad y confidencialidad.'
                    },
                    {
                        id: 3,
                        text: texts?.recognitions_authorization_paragraph_three ?? 'La existencia de cualquier información y documentación descrita anteriormente deberá ser revelada junto con esta solicitud. Entiendo que VUMI® se basará en dicha información para: 1) Evaluar el riesgo de solicitud de cobertura y tomar decisiones sobre la elegibilidad, clasificación de riesgo, emisión de la póliza e inscripción de todos los solicitantes; 2) administrar reclamaciones y determinar o cumplir con la responsabilidad de cobertura y suministro de beneficios; 3) administrar la cobertura; y 4) llevar a cabo otras operaciones de cobertura conforme a la ley pertinente.'
                    },
                    {
                        id: 4,
                        text: texts?.recognitions_authorization_paragraph_four ?? 'Una copia de esta autorización se considerará tan válida como la original. Entiendo que la capacidad de VUMI® para evaluar la cobertura depende de recibir toda la información de salud necesaria.'
                    }
                ]
            },
            {
                id: 2,
                titulo: texts?.recognitions_privacy_subtitle ?? 'Privacidad',
                texto: [
                    {
                        id: 1,
                        text: texts?.recognitions_privacy_paragraph_one ?? 'Toda la información personal médica será tratada como información confidencial por VUMI® Group, I.I., sus compañías afiliadas o representantes designados.'
                    },
                    {
                        id: 2,
                        text: texts?.recognitions_privacy_paragraph_two ?? 'La Compañía cumple con la legislación de Protección de Datos y los reglamentos y regulaciones de confidencialidad de la información médica. La Compañía no compartirá ninguna información médica, a menos que exista una autorización para hacerlo, ya sea de parte del paciente, su(s) representante(s) legal(es) o la ley.'
                    }
                ]
            },
            {
                id: 3,
                titulo: texts?.recognitions_signature_subtitle ?? 'Acuerdo de Firma Electrónica',
                texto: [
                    {
                        id: 1,
                        text: texts?.recognitions_signature_paragraph_one ?? 'Al seleccionar la casilla "Acepto" más abajo, usted está firmando esta Solicitud electrónicamente. Usted acepta estar legalmente obligado por los términos y condiciones de esta Solicitud. Usted acepta que su firma electrónica ("E-Signature") es el equivalente legal de su firma manual en esta Solicitud.'
                    },
                    {
                        id: 2,
                        text: texts?.recognitions_signature_paragraph_two ?? 'También acepta que no es necesaria ninguna autoridad de certificación u otra verificación de terceros para validar su firma electrónica y que la falta de dicha certificación o verificación de terceros no afectará de ninguna manera la aplicabilidad de su firma electrónica en cualquier contrato resultante entre usted y VUMI® Group, I.I. También declara que tiene la autoridad para celebrar este Acuerdo para todas las personas que están incluidas en su solicitud, como Dependientes y que dichas personas también estarán obligadas por los términos de esta Solicitud y de la póliza para la cual se completa esta Solicitud. Además, acepta que cada uso de su firma electrónica para obtener un servicio de VUMI® Group, I.I. en línea constituyen su aceptación de estar sujeto a los términos y condiciones de VUMI® Group, I.I.'
                    }
                ]
            },
            {
                id: 4,
                titulo: texts?.recognitions_documentation_subtitle ?? 'Consentimiento para la entrega documentación electrónica',
                texto: [
                    {
                        id: 1,
                        text: texts?.recognitions_documentation_paragraph_one ?? 'Usted acepta específicamente recibir y/u obtener de VUMI® Group, I.I. toda y cada una de la documentación relacionada con esta transacción en línea relacionada con "Comunicaciones electrónicas" a través del Servicio del portal en línea de VUMI® Group, I.I. Usted reconoce que, para sus registros, puede utilizar los Servicios del portal en línea de VUMI® Group, I.I. para retener comunicaciones electrónicas imprimiendo y/o descargando y guardando esta Solicitud, la póliza de seguro, y/o cualquier otra comunicación electrónica, documentos o registros que usted acepte usar con su Firma electrónica. Acepta las comunicaciones electrónicas proporcionadas a través de VUMI® Group, I.I. y sus servicios en línea, como notificación razonable y adecuada, para el propósito de todas y cada una de las leyes, reglas y regulaciones, y acuerda que dicho formulario electrónico satisface completamente cualquier requisito de que tales comunicaciones se le proporcionen por escrito o en un formulario que pueda conservar.'
                    }
                ]
            },
            {
                id: 4,
                titulo: texts?.recognitions_contact_info_subtitle ?? 'Actualización de su información de contacto',
                texto: [
                    {
                        id: 1,
                        text: texts?.recognitions_contact_info_paragraph_one ?? 'Usted es responsable de asegurarse de que nosotros tenemos su dirección de correo electrónico actualizada con el propósito de recibir Comunicaciones Electrónicas. Reconocimiento del agente como Agente, acepto completa responsabilidad por entregar a VUMI® Group, I.I. esta solicitud y todas las primas cobradas, así como por entregar la póliza al solicitante cuando esta sea emitida. Desconozco de alguna condición que no haya sido declarada en esta solicitud, la cual afecte la asegurabilidad de los solicitantes.'
                    }
                ]
            }
        ]

    useEffect(() => {
        verifyCurrentLanguage();
    }, []);

    return (
        <Fragment>
            <section>
                <div className="container d-flex justify-content-center  ">
                    <div className={`${style.titulos_principales}  pt-4 pb-3`}>
                        <TitleH2Component styleGroup={`raleway_bold color-azul2 lh_30  mb-0  ${Stylegeneral.titulo} `}>
                            {texts?.recognitions_title ?? 'Reconocimiento y Autorizaciones'}
                        </TitleH2Component>
                    </div>
                </div>
            </section>

            <section>
                <div className="container mb-5">

                    {text.map((iten, index) => {
                        return (
                            <ParagraphComponent key={index} styleGroup={`${styleTex.text_informacion} mb-0 pb-3 raleway color_primary fw_400`}>
                                {iten.texto}
                            </ParagraphComponent>
                        )
                    })}

                    {informacion.map((item, index) => (
                        <div key={index}>
                            <TitleH6Component styleGroup={`${styleTex.text_informacion} mb-0 pb-3 raleway_bold color_primary`}>
                                {item.titulo}
                            </TitleH6Component>
                            {item.texto.map((item, index) => (
                                <ParagraphComponent key={index} styleGroup={`${styleTex.text_informacion} mb-0 pb-3 raleway color_primary fw_400`}>
                                    {item.text}
                                </ParagraphComponent>
                            ))}
                        </div>

                    ))}
                </div>
            </section>

            <section>
                <div className="container">
                    <div className="form-check pb-3">
                        <CheckboxComponent required className="form-check-input" id="reconocimiento1" name={"reconocimiento1"} section={pathname} />
                        <label className={`form-check-label ${styleForm.labels} raleway color_primary`} for="reconocimiento1">
                            {texts?.recognitions_option_checkbox_one ?? 'El solicitante principal está de acuerdo con los puntos mencionados anteriormente.'}
                        </label>
                    </div>

                    <ParagraphComponent styleGroup={`${styleTex.text_informacion} mb-0 pb-3 raleway color_primary fw_400`}>
                        {texts?.recognitions_agent_phrase ?? 'Como Agente, acepto completa responsabilidad por el envío de esta solicitud, todas las primas cobradas y por la entrega de la póliza cuando sea emitida. Desconozco la existencia de cualquier condición que no haya sido revelada en esta solicitud que pudiera afectar la asegurabilidad de los propuestos asegurados.'}
                    </ParagraphComponent>

                    <div className="form-check pb-3">
                        <CheckboxComponent className="form-check-input" id="reconocimiento2" name={"reconocimiento2"} section={pathname} />
                        <label className={`form-check-label ${styleForm.labels} raleway color_primary`} for="reconocimiento2">
                            {texts?.recognitions_option_checkbox_two ?? '¿Alguno de los solicitantes tiene historia familiar de diabetes, hipertensión, desórdenes del corazón, cáncer, o enfermedad congénita o hereditaria?'}
                        </label>
                    </div>

                </div>
            </section>

            <section>
                <div className="container pb-5">
                    <ParagraphComponent styleGroup={`${styleTex.text_informacion} mb-0 pb-2 raleway color_primary fw_400`}>
                        {texts?.recognitions_document_field_phrase ?? 'En caso de ser necesario, utilice este espacio para añadir documentos adicionales.'}
                    </ParagraphComponent>

                    <div className="row">
                        <div className=" col-md-6 col-lg-5 col-12">
                            <GestorArchivosComponent /> 
                        </div>
                    </div>
                    
                </div>
            </section>
        </Fragment>
    )
}

export default ReconocimientosPage;