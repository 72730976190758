import { useApplication } from "context/applicationContext";

export const useApplicationList = () => {
  const { changeDataValue, applicationState } = useApplication();

  const add = (key) => {
    const value = applicationState[key];
    value.id = (applicationState[`${key}List`] ?? []).length;
    changeDataValue(`${key}List`, [
      ...(applicationState[`${key}List`] ?? []),
      value,
    ]);
  };

  const remove = (key, index) => {
    changeDataValue(`${key}List`, [
      ...applicationState[`${key}List`]
        .filter((i) => i.id !== index)
        .map((i, index) => ({ ...i, id: index })),
    ]);
  };

  const edit = (key) => {
    changeDataValue(`${key}List`, [
      ...applicationState[`${key}List`].map((i) => {
        if (i.id === applicationState[key].id) return applicationState[key];
        return i;
      }),
    ]);
  };

  const startEdit = (key, index) => {
    changeDataValue(`${key}`, applicationState[`${key}List`].find(i => i.id === index));
  };

  const resetData = (key) => {
    changeDataValue(`${key}`, {});
  }

  return {
    add,
    remove,
    edit,
    startEdit,
    resetData
  };
};
