import React, { Fragment, useState, useContext } from "react";
import { TextsContext } from "../../../../context/textsContext";
import styleForm from '../../../../scss/form.module.scss';
import StyleBtn from "../../../../scss/btn.module.scss";
import { ParagraphComponent } from "../../../../components/ParagraphComponent";
const GestorArchivosComponent = () => {
    const { texts } = useContext(TextsContext);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };
    return (
        <Fragment>
            <form >

                <div className={`${styleForm.espacio_file}`}>
                    <label for="archivo" className={`${styleForm.custom_file_upload} me-2`}>
                        <span className="raleway fw_500">{texts?.general_select ?? 'Selecione'}</span>
                        <input type="file" id="archivo" name="archivo" className={`${styleForm.input_file}`} onChange={handleFileChange} />
                    </label>
                    {selectedFile ? (
                        <div>{selectedFile.name}</div>
                    ) : <span className="raleway color_primary">{texts?.general_not_file ?? 'Ningún archivo seleccionado'}</span>}
                </div>

                <ParagraphComponent styleGroup={` mb-0 py-4 py-md-3 raleway color_primary fw_400`}>
                    {texts?.general_file_type ?? 'Tipo de archivo Jpg, png y pdf. Peso máximo: 2MB'}
                </ParagraphComponent>
                <button type="button" className={`raleway_bold  color_primary  ${StyleBtn.btnApp} ${StyleBtn.wpbtn} ${StyleBtn.btnsig}`}>{texts?.general_upload_button ?? 'Subir'}</button>
            </form>
        </Fragment>
    )
}
export default GestorArchivosComponent;