export const getRegularBenefitAdapt = (contextState, benefit) => {
  return {
    BeginDate: contextState.coverage,
    EndDate: "",
    Description: benefit["factorId.nameFactor"],
    PortalType: benefit["factorId.portalType"], // ???
    CodeFactor: benefit["factorId.benefitId.code"],
    CodeOption: "",
    DescriptionOption: benefit["description"],
    IdBenefit: benefit["factorId.id"].toString(), // ???
    IdOption: benefit["id"].toString(),
    Factor: benefit["factor"],
    NoCoverage: benefit["isNoCoverageOption"] ? "1" : "0",
    status: "A", // ???
  };
};

export const getStructuralBenefitAdapt = (contextState, benefit) => {
  return {
    BeginDate: contextState.coverage,
    EndDate: "",
    Description: benefit["factorId.nameFactor"],
    PortalType: benefit["factorId.portalType"], // ???
    CodeFactor: benefit["factorId.factorTypeId.codeFactor"],
    CodeOption: benefit["codeFactorOption"],
    DescriptionOption: benefit["description"],
    IdBenefit: benefit["factorId.id"].toString(), // ???
    IdOption: benefit["id"].toString(),
    Factor: benefit["factor"],
    NoCoverage: benefit["isNoCoverageOption"] ? "1" : "0",
    status: "A", // ???
  };
};
