import { useLocation, useNavigate } from "react-router-dom";
import { ApplicationRoutes } from "../routes/ApplicationRoutes";
import { useApplication } from "../context/applicationContext";
import { GUARD_NONE, GUARD_RELATIVE } from "helpers/guards";


export const useApplicationStep = () => {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const {sectionHasNoErrors, setFailedNextIntent, addFinishedStep, removeFinishedStep, finishedSteps} = useApplication();

  const getCurrentPath = () => {
    return ApplicationRoutes.find((i) => i.path === pathname);
  }

  const getCurrentPathIndex = () => {
    return ApplicationRoutes.findIndex((i) => i.path === pathname);
  }

  const getPathIndex = (path) => {
    return ApplicationRoutes.findIndex((i) => i.path === path);
  }

  const getNotHiddenPaths = () => {
    return ApplicationRoutes.filter(i => !i?.hidden);
  }

  const goNext = () => {
    const paths = getNotHiddenPaths();
    const currentIndex = paths.indexOf(getCurrentPath());
    const next = paths?.[currentIndex + 1];
    if (next && !next?.hidden)
      navigate(next.path);
  }

  const goNextValidation = () => {
    if (sectionHasNoErrors(getCurrentPath().path)) {
      setFailedNextIntent(false);
      addFinishedStep(getCurrentPath().path)
      goNext();
      return;
    }
    removeFinishedStep(getCurrentPath().path)
    setFailedNextIntent(true);
  }

  const goPrevious = () => {
    const paths = getNotHiddenPaths();
    const currentIndex = paths.indexOf(getCurrentPath());
    const previous = paths?.[(currentIndex - 1) > -1 ? currentIndex - 1 : null];
    if (previous && !previous?.hidden)
      navigate(previous.path);
  }

  const getUserCurrentPath = () => {
    let current = "";
    const index = getCurrentPathIndex() + 1;
    const arr = ApplicationRoutes.slice(0, index).reverse().map((path) => {
      const arr_ =  {
        ...path,
        visited: current ? true : false,
        current: (current && path.name) ? false : true
      }
        if (arr_.current) current = arr_;
        return arr_;
    });
    return [...arr.reverse(), ...ApplicationRoutes.slice(index)].filter(i => i.name)
  }

  const userShouldntBeHere = () => {
    const list = getListOfCrossedSteps();
    if (!list) return false;
    let path = false;
    for (const element of list.reverse()) {
      if (!isPreviousValid(element.path)) {
        path = getPrevious(element.path)?.path;
        break;
      }
    }
    return path;
  };

  const getListOfCrossedSteps = () => {
    const guard = getCurrentPath()?.guard;
    const index = (guard === GUARD_RELATIVE || guard === GUARD_NONE) ? getPathIndex(getNonRelativeGuardIndex()) : getCurrentPathIndex();
    if (!index) return false; 
    return ApplicationRoutes.slice(0, index + 1);
  };

  const getNonRelativeGuardIndex = () => {
    return ApplicationRoutes.slice(0, getCurrentPathIndex() + 1).reverse().find(i => (i?.guard !== GUARD_RELATIVE && i?.guard !== GUARD_NONE))?.path;
  }

  const getPrevious = (path) => {
    const index = getPathIndex(path) - 1;
    if (index < 0) return false;
    return ApplicationRoutes[index];
  }

  const isPreviousValid = (path) => {
    const index = getPathIndex(path) - 1;
    return isStepValid(index);
  };

  const isStepValid = (index) => {
    if (index < 0) return true;
    const route = ApplicationRoutes[index];
    if (route?.guard === GUARD_NONE) return true;
    if (route?.guard === GUARD_RELATIVE) return true;
    if (route.path === pathname) return true;
    if (finishedSteps.includes(route.path)) return true;
    return false;
  };

  return {
    goNext,
    goPrevious,
    goNextValidation,
    getUserCurrentPath,
    getCurrentPath,
    userShouldntBeHere
  };
};
